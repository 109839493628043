import React, { useContext, useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

import { LinguaContext } from '../../Contextos/Linguas';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Bloco from '../../Componentes/Bloco';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Cores from './Cores.js';
import FormSolid from '../../Componentes/FormSolid';
import PaletaAdesivos from '../../Componentes/PaletaAdesivos';
import PaletaCores from '../../Componentes/PaletaCores';
import Rodape from '../../Componentes/Rodape';
import T from '../../Traducoes';

function Fun2() {
    const { idioma } = useContext(LinguaContext);
    const svg_top = useRef(null);
    const svg_bottom = useRef(null);
    const parapente = 'Fun 2';
    const [ imgtop, setImgtop ] = useState('');
    const [ imgbottom, setImgbottom ] = useState('');
    const [ coresT, setCoresT ] = useState('');
    const [ coresB, setCoresB ] = useState('');
    const [ cort, setCort ] = useState('#FFFFFF');
    const [ corb, setCorb ] = useState('#FFFFFF');
    const [ cor1, setCor1 ] = useState('#FFFFFF');
    const [ cor2, setCor2 ] = useState('#FFFFFF');
    const [ logot, setLogot ] = useState('transparent');
    const [ logob, setLogob ] = useState('transparent');
    const [ showModal, setShowModal ] = useState(false);
    const tamanhos = [
        {value: "12", label: "12" },
        {value: "14", label: "14" },
    ];
    
    function padrao1() {
        setCort('#F00008');
        setCorb('#F00008');
        setCor1('#1F4194');
        setCor2('#FBE111');
    }
        
    function padrao2() {
        setCort('#FBE111');
        setCorb('#FBE111');
        setCor1('#094A39');
        setCor2('#1F4194');
    }

    function padrao3() {
        setCort('#1F4194');
        setCorb('#1F4194');
        setCor1('#F00008');
        setCor2('#FBE111');
    }

    function padrao4() {
        setCort('#F75439');
        setCorb('#F75439');
        setCor1('#1F4194');
        setCor2('#FBE111');
    }

    useEffect(() => {
        padrao1();
    },[]);

    function gerarImagem() {
        htmlToImage.toPng(svg_top.current)
        .then(function (dataURL) {
            setImgtop(dataURL);
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
        
        htmlToImage.toPng(svg_bottom.current)
        .then(function (dataURL) {
            setImgbottom(dataURL)
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
    }

    function abrirModalEnviar() {
        gerarImagem();
        let lt="";
        if (logot==="transparent") lt="NENHUM / NO LOGO";
        if (logot==="#000000") lt="Preto / Black";
        if (logot==="#FF0000") lt="Vermelho / Red";

        let lb="";
        if (logob==="transparent") lb="NENHUM / NO LOGO";
        if (logob==="#000000") lb="Preto / Black";
        if (logob==="#FF0000") lb="Vermelho / Red";

        const novasCoresTop = 
        'Top: '+Cores[cort].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Logo: '+lt+'\n\r';

        const novasCoresBottom = 
        'Bottom: '+Cores[corb].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Logo: '+lb+'\n';

        setCoresT(novasCoresTop);
        setCoresB(novasCoresBottom);

        setShowModal(true);
    }

    return <>
        <BarraSuperior/>
        <Bloco className="container" id="273"/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <div className="row mb-4">

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="w-100 d-flex justify-content-right mb-3 overflow-auto">
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/fun_two_padrao1.jpg" onClick={() => padrao1()} alt="1" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/fun_two_padrao2.jpg" onClick={() => padrao2()} alt="2" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/fun_two_padrao3.jpg" onClick={() => padrao3()} alt="3" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/fun_two_padrao4.jpg" onClick={() => padrao4()} alt="3" style={{maxWidth: '70px'}}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 805.96 449.7" ref={svg_top}>
                        <g stroke="#000000" strokeWidth="0.5" fill={cort}>                                
                            <path d="M803.49 145.19c-1.08,2.37 -3.04,4.23 -5.42,5.32 -40.4,18.45 -450.64,205.22 -668.67,284.77 0,0 -73.3,30.73 -105.23,1.02 0,0 -9.85,-10.15 -22.08,-44.05 -2.59,-7.12 -2.35,-14.96 0.71,-21.88 18.91,-42.9 102.9,-203.7 371.85,-322.6 8.59,-3.8 98.55,-38.55 175.06,-45.12 97.39,-8.34 137.37,6.39 167.97,32.77 27.62,23.83 77.01,64.09 86.52,92.54 1.86,5.58 1.8,11.68 -0.62,17.05l-0.09 0.18z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                            <path d="M206.81 387.16c2.04,2.86 8.57,11.98 10.4,14.54 -3.5,1.38 -7.01,2.77 -10.51,4.15l-11.38 4.46c-3.57,1.55 -7.57,2.95 -11.24,4.36 -0.76,-0.92 -1.94,-2.33 -3.27,-3.9 -1.87,-2.22 -6.42,-8.05 -8.12,-10.23 11.1,-4.3 22.49,-8.76 34.12,-13.38z"/>
                            <path d="M79.92 432.81c1.88,2.37 5.88,6.69 7.55,8.32 1.22,1.19 3.84,3.52 5.51,5 -3.43,0.69 -6.97,1.31 -10.59,1.81 -2.17,0.29 -4.33,0.55 -6.51,0.76 -0.69,0.09 -1.46,0.13 -2.16,0.18l-4.28 0.26c-0.75,0.03 -1.53,0.06 -2.29,0.07 -0.74,0.02 -1.48,0.03 -2.23,0.03 -1.88,0 -3.75,-0.04 -5.63,-0.14 -2.05,-2.26 -5.43,-5.97 -6.32,-7 -0.93,-1.08 -3.11,-4.16 -4.27,-5.83 10.6,0.08 21.44,-1.41 31.22,-3.46z"/>
                            <path d="M139.6 413.17c1.74,2.21 5.9,6.43 7.45,8.01 1.29,1.31 4.3,4.14 5.7,5.45 -7.99,3 -15.8,5.89 -23.35,8.65l-0.01 0 0 0 -0.01 0.01 -0.01 0 -0.01 0.01 -0.01 0 -0.01 0 -0.02 0.01 -0.02 0.01 -0.03 0.01 -0.03 0.02 -0.03 0.01 -0.02 0.01 -0.06 0.02 -0.04 0.02 -0.04 0.01 -0.05 0.02 -0.05 0.02 -0.02 0.01 -0.09 0.04 -0.06 0.02 -0.13 0.06 -0.07 0.02 -0.01 0.01 -0.06 0.02 -0.07 0.03 -0.08 0.03 -0.08 0.03 -0.08 0.04 -0.09 0.03 0 0 -0.09 0.03 -0.09 0.04 -0.09 0.04 -0.1 0.04 -0.1 0.03 -0.08 0.03 -0.02 0.01 -0.11 0.04 -0.21 0.09 -0.12 0.04 -0.91 0.35 -0.09 0.03c-0.76,0.29 -1.65,0.62 -2.66,0.99 -1.85,-1.75 -6.57,-6.18 -7.71,-7.35 -1.16,-1.17 -4.28,-4.72 -5.49,-6.09 3.47,-1.26 5.46,-2.1 5.46,-2.1 7.74,-2.82 15.72,-5.77 23.9,-8.85z"/>
                            <path d="M274.43 359.87l8.73 15.22 -1.82 0.75 0 0 -10.36 4.23 -0.49 0.2 -0.49 0.2 -0.5 0.21 -0.49 0.2 -0.99 0.4 -0.49 0.2 -0.49 0.2 -0.5 0.2 -0.49 0.2 -0.49 0.2 -0.5 0.2 -0.99 0.4 -0.49 0.2 -0.99 0.4 -0.49 0.2 -0.49 0.2 -0.5 0.21 -0.49 0.2 -0.5 0.2 -0.49 0.2 -0.49 0.2 -0.5 0.2 -0.49 0.2 -4.45 1.8 -0.49 0.2 -0.5 0.2 -0.49 0.2 -0.49 0.19 -0.5 0.2 -0.49 0.2 -1.88 0.76c-2.87,-4.18 -8.13,-12.05 -9.92,-14.74 11.66,-4.7 23.5,-9.52 35.49,-14.43z"/>
                            <path d="M346.46 339.01l3.08 8.55c-5.25,2.2 -10.48,4.39 -15.7,6.57l-0.52 0.22 -0.03 0.01 -12.46 5.19 -0.52 0.22 -0.13 0.05 -0.39 0.16 -0.52 0.22 -3.11 1.29 -7.57 -15.73 1.75 -0.72c10.07,-4.19 20.14,-8.4 30.2,-12.61l2.8 -1.18 3.12 7.76z"/>
                            <path d="M413.12 301.67c1.74,4.44 3.91,14.01 4.57,17.02l-0.54 0.23 -0.55 0.24 -0.54 0.23 -0.55 0.23 -0.54 0.24 -0.55 0.23 -0.54 0.23 -0.55 0.23 -0.48 0.21 -0.06 0.03 -0.55 0.23 -0.54 0.23 -0.55 0.24 -0.54 0.23 -0.55 0.23 -0.55 0.23 -0.54 0.24 -0.55 0.23 -0.54 0.23 -0.55 0.23 -0.54 0.24 -0.55 0.23 -0.55 0.23 -0.54 0.23 -0.55 0.24 -0.54 0.23 -0.55 0.23 -0.54 0.23 -0.55 0.23 -0.54 0.24 -0.55 0.23 -4.37 1.86 -0.54 0.23 -2.19 0.93 -0.54 0.23 -0.55 0.23 -0.54 0.23 -0.55 0.23 -0.54 0.23 -0.55 0.24c-2.73,1.16 -5.46,2.32 -8.18,3.47 -0.67,-2.05 -2.04,-6.27 -2.74,-8.48 -0.71,-2.23 -2.14,-6.27 -2.8,-8.12 11.96,-5.07 23.94,-10.18 35.88,-15.28z"/>
                            <path d="M480.86 272.49l1.52 6.07c0.36,1.45 1.94,8.83 2.45,11.23l-0.74 0.32 -3.81 1.66 -0.46 0.19 -0.46 0.2 -0.46 0.2 -0.03 0.02 -0.43 0.18 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.47 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.47 0.2 -0.46 0.2 -0.46 0.19 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.47 0.2 -6.92 2.99 -0.46 0.2 -0.46 0.2 -0.46 0.19 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.47 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.2 -0.46 0.19 -0.46 0.2 -0.47 0.2 -2.3 1 -0.02 0.01 -0.01 0c-0.57,-3.03 -1.49,-7.91 -1.81,-9.44 -0.37,-1.78 -1.73,-6.04 -2.3,-7.79l0.35 -0.15c10.03,-4.33 20.06,-8.66 30.08,-13l1.96 -0.86z"/>
                            <path d="M548.5 242.98c1.01,4.75 2.18,14.26 2.61,17.91l-11.04 4.84 -0.51 0.22 -0.5 0.22 -0.5 0.22 -0.51 0.22 -0.5 0.22 -0.51 0.23 -0.51 0.22 -0.5 0.22 -0.51 0.22 -0.51 0.22 -0.5 0.22 -0.51 0.23 -0.51 0.22 -0.5 0.22 -0.51 0.22 -0.51 0.22 -0.51 0.23 -3.56 1.55 -0.51 0.23 -2.05 0.89 -0.51 0.22c-2.04,0.89 -4.09,1.79 -6.15,2.68 -0.22,-2.58 -0.55,-6.09 -0.89,-9.13 -0.41,-3.48 -1.12,-6.79 -1.61,-8.81 10.91,-4.77 21.7,-9.48 32.32,-14.15z"/>
                            <path d="M611.15 215.36c1.03,5.36 1.79,14.22 2.1,18.14l-0.46 0.21 -0.46 0.2 -0.47 0.21 -0.46 0.2 -0.47 0.21 -0.46 0.2 -0.46 0.21 -0.47 0.21 -0.47 0.2 -0.46 0.21 -0.47 0.2 -0.47 0.21 -0.46 0.21 -0.47 0.21 -0.47 0.2 -0.47 0.21 -0.47 0.21 -0.47 0.2 -0.46 0.21 -0.47 0.21 -0.47 0.21 -0.06 0.02 -0.41 0.18 -0.48 0.21 -0.47 0.21 -0.47 0.21 -0.47 0.21 -0.47 0.21 -0.47 0.21 -0.48 0.2 -0.47 0.21 -0.47 0.21 -0.48 0.21 -0.47 0.21 -0.47 0.21 -0.48 0.21 -0.47 0.21 -0.48 0.21 -0.47 0.21 -0.48 0.21 -0.48 0.21 -0.47 0.21 -0.48 0.21 -0.48 0.21 -0.47 0.21 -0.48 0.22 -0.41 0.18 -0.07 0.03 -0.48 0.21 -0.48 0.21 -0.48 0.21 -0.48 0.21 -0.48 0.21 -0.47 0.22 -0.49 0.21 -1.92 0.85 -0.48 0.21 -2.9 1.27c-0.03,-2.23 -0.13,-5.41 -0.46,-8.4 -0.4,-3.64 -1.37,-7.49 -1.96,-9.59l0.75 -0.33c9.94,-4.38 19.87,-8.77 29.8,-13.17l0.08 -0.04z"/>
                            <path d="M671.13 188.67c1.19,3.53 2.12,10.17 2.12,10.17 0.38,3.36 0.26,6.3 0.13,7.91l-0.43 0.2 -0.44 0.19 -0.43 0.2 -0.44 0.19 -0.43 0.2 -0.44 0.19 -0.44 0.2 -0.43 0.19 -0.44 0.2 -0.44 0.19 -0.02 0.01 -0.42 0.19 -0.44 0.19 -0.44 0.2 -0.44 0.2 -0.45 0.2 -0.44 0.19 -0.44 0.2 -0.44 0.2 -0.45 0.2 -0.44 0.19 -0.45 0.2 -0.44 0.2 -0.45 0.2 -0.44 0.2 -0.45 0.2 -0.45 0.2 -0.45 0.2 -0.89 0.4 -0.45 0.2 -2.26 1 -0.45 0.21 -1.36 0.6 -0.45 0.2 -0.46 0.21 -0.45 0.2 -0.46 0.2 -0.46 0.21 -0.45 0.2 -0.46 0.2 -0.46 0.21 -0.46 0.2 -0.46 0.21 -0.46 0.2 -0.46 0.21 -0.46 0.2 -0.46 0.21 -0.46 0.2 -0.46 0.21 -0.46 0.2 -0.14 0.06 -0.33 0.15 -0.46 0.21 -0.46 0.2 -0.47 0.21 -0.46 0.21 -0.47 0.2 -0.46 0.21 -0.47 0.21c-0.02,-1.48 -0.22,-3.59 -0.54,-6.63 -0.33,-3.11 -2.15,-8.49 -3,-10.88l3.93 -1.74c7.1,-3.16 14.19,-6.33 21.29,-9.5l4.9 -2.19z"/>
                            <path d="M724.63 164.68c0.34,1.5 0.97,4.3 1.64,7.95 0.76,4.08 0.62,8.23 0.5,10.18l-0.77 0.35 -0.39 0.17 -0.38 0.17 -0.39 0.18 -0.38 0.17 -0.11 0.05 -0.28 0.12 -0.39 0.18 -0.39 0.17 -0.39 0.18 -0.39 0.18 -0.39 0.17 -0.39 0.18 -0.4 0.17 -0.39 0.18 -0.4 0.18 -0.39 0.18 -0.4 0.18 -0.4 0.17 -0.39 0.18 -0.4 0.18 -0.4 0.18 -0.4 0.18 -0.4 0.18 -0.41 0.18 -2.43 1.1 -0.4 0.18 -13.46 6.04c0.02,-2.86 -0.3,-6.38 -0.3,-6.38 -0.51,-4.78 -1.47,-9.11 -2.1,-11.63 9.3,-4.17 18.1,-8.13 26.37,-11.85z"/>
                            <path d="M773.21 142.72c1.83,6.43 1.8,14.8 1.68,18.34l-1.55 0.7 -0.31 0.15 -0.32 0.14 -0.32 0.14 -0.11 0.05 -0.21 0.1 -0.64 0.29 -0.33 0.15 -0.32 0.15 -0.33 0.14 -0.33 0.15 -0.33 0.15 -0.33 0.15 -0.34 0.16 -0.33 0.15 -0.34 0.15 -0.34 0.15 -0.34 0.16 -0.34 0.15 -0.34 0.16 -0.41 0.18 -0.28 0.13 -0.35 0.16 -0.35 0.16 -0.35 0.16 -0.36 0.16 -0.35 0.16 -0.36 0.16 -0.35 0.16 -0.36 0.16 -0.73 0.33 -0.36 0.16 -0.36 0.17 -0.37 0.17 -0.37 0.16 -0.73 0.33 -0.01 0.01 -0.37 0.17 -0.38 0.17 -0.37 0.17 -0.38 0.17 -0.38 0.17 -0.38 0.17 -0.38 0.17 -0.38 0.18 -0.39 0.17 -0.38 0.17 -0.39 0.18 -0.39 0.18 -0.39 0.17 -0.39 0.18 -0.4 0.18 -0.39 0.18 -0.31 0.14 -0.09 0.04 -0.4 0.18 -0.4 0.18 -0.4 0.18 -0.4 0.18 -0.4 0.18c0.11,-6.77 -2.13,-15.24 -2.86,-17.8 9.06,-4.1 17.09,-7.73 23.97,-10.86z"/>
                            <path d="M793.7 133.41c1.68,-0.77 3.09,-1.41 4.22,-1.93 2.42,-1.11 4.42,-3 5.53,-5.42l0.03 -0.06 0.16 0.4 0.01 0.03 0.01 0.03 0.01 0.03 0.01 0.03 0.02 0.03 0.04 0.12 0.01 0.03 0.02 0.03 0.01 0.03 0.01 0.04 0.01 0.03c0.13,0.36 0.26,0.71 0.38,1.07l0.01 0.03 0.01 0.03c1.86,5.58 1.8,11.68 -0.62,17.05l-0.09 0.18c-1.08,2.37 -3.04,4.23 -5.42,5.32l-0.03 0.01 -0.03 0.02 -0.03 0.01 -0.04 0.02 -0.03 0.01 -0.03 0.02 -0.04 0.01 -0.03 0.02 -0.03 0.01 -0.04 0.02 -0.03 0.02 -0.04 0.01 -1.99 0.91c0.29,-3.59 -0.18,-8.06 -0.18,-8.06 -0.48,-5.2 -1.31,-8.52 -1.8,-10.13z"/>
                            <path d="M147.79 362.76c-8.18,4.94 -17.72,10.43 -28.27,16.05 1.46,2.96 7.7,15.62 9.95,19.18l0.01 0c0,0 11.57,-10.32 24.72,-24.6l-6.41 -10.63z"/>
                            <path d="M90.77 393.08c-9.15,4.2 -18.72,8.26 -28.59,12.02 0.91,1.71 4.94,9.28 7.59,13.23 9.43,-2.17 19.67,-4.98 30.4,-8.63 -1.19,-2.03 -6.59,-11.24 -9.4,-16.62z"/>
                            <path d="M13.78 419.79c7.43,-1.63 14.82,-3.57 22.14,-5.76 0.9,1.82 3.57,7.16 4.88,9.54 -11.42,1.49 -19.82,1.91 -23.89,2.02l0 0 -0.05 -0.08 -0.04 -0.08 -0.05 -0.08 -0.05 -0.08 -0.04 -0.08 -0.05 -0.08 -0.04 -0.08 -0.04 -0.06 -0.01 -0.02 -0.04 -0.09 -0.05 -0.08 -0.05 -0.08 -0.04 -0.08 -0.05 -0.09 -0.05 -0.08 -0.04 -0.09 -0.05 -0.08 -0.02 -0.04 -0.03 -0.05 -0.05 -0.08 -0.04 -0.09 -0.05 -0.08 -0.05 -0.09 -0.05 -0.09 -0.04 -0.09 -0.05 -0.08 -0.05 -0.09 0 -0.01 -0.05 -0.08 -0.05 -0.09 -0.04 -0.09 -0.05 -0.09 -0.05 -0.09 -0.05 -0.08 -0.05 -0.1 -0.05 -0.09 -0.04 -0.06 -0.01 -0.03 -0.05 -0.09 -0.05 -0.09 -0.05 -0.09 -0.04 -0.1 -0.05 -0.09 -0.05 -0.09 -0.05 -0.09 -0.05 -0.1 -0.02 -0.04 -0.03 -0.05 -0.05 -0.1 -0.05 -0.09 -0.05 -0.1 -0.05 -0.09 -0.05 -0.1 -0.06 -0.1 -0.05 -0.09 -0.05 -0.1 0 -0.01 -0.05 -0.09 -0.05 -0.1 -0.05 -0.1 -0.05 -0.09 -0.25 -0.47 -0.01 -0.03 -0.05 -0.1 -0.05 -0.1 -0.11 -0.21z"/>
                            <path d="M340.44 250.55c1.81,2.7 6.24,9.29 7.37,10.96 -6.43,10.23 -14.16,21.73 -23.02,33.46 -2.81,-4.71 -11.69,-18.52 -13.59,-21.47 9.32,-6.75 19.18,-14.39 29.24,-22.95z"/>
                            <path d="M221.41 346.22c1.87,3.22 6.81,11.18 8.14,13.31 -13.94,6.42 -26.22,11.76 -32.38,13.64 0,0 11.9,-12.54 24.24,-26.95z"/>
                            <path d="M279.76 294.4c4.86,7.67 16.19,27.58 18.62,31.86 -0.97,0.49 -15.67,7.84 -33.79,16.63 -5.26,-8.93 -15.75,-26.53 -18.04,-30.38l0.01 -0.03c0,0 13.2,-5.91 33.2,-18.08z"/>
                            <path d="M726.12 107.09l-0.01 0c0,0 -6.23,3.88 -26.06,8.92 2.19,3.28 11.4,17.25 14.99,24.76l25.83 -8.39c-1.57,-3 -8.74,-16.59 -14.75,-25.29z"/>
                            <path d="M671.03 122.12c-9.62,1.69 -20.8,3.41 -33.75,5.05l7.06 10.09c11.88,1.36 25.14,2.6 38.28,3.29 -1.54,-2.57 -8.12,-13.46 -11.59,-18.43z"/>
                            <path d="M758.18 112.4c0.91,1.33 5.22,7.65 7.69,11.86l21.53 -6.99c0,0 -13.42,-2.25 -29.22,-4.87z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M377.27 316.93c1.29,3.51 4.7,13.96 5.56,16.59l-8.86 3.76 -0.53 0.22 -0.48 0.2 -0.04 0.02 -0.52 0.22 -9.89 4.17 -0.52 0.22 -1.04 0.44 -0.52 0.21 -0.52 0.22 -0.21 0.09 -0.31 0.13 -0.52 0.22 -0.52 0.22 -0.52 0.22 -0.52 0.22 -0.52 0.21 -3.12 1.31 -0.52 0.22 -0.52 0.22 -0.51 0.22 -0.52 0.21 -0.52 0.22 -0.52 0.22 -0.52 0.22 -0.52 0.21c-0.63,-1.76 -1.64,-4.54 -2.87,-7.91 -1.57,-4.31 -2.77,-7.17 -3.31,-8.4 11.27,-4.73 22.58,-9.5 33.93,-14.32z"/>
                            <path d="M238.98 374.29l9.84 14.77 -3.01 1.21 -0.5 0.2 -0.5 0.2 -0.5 0.21 -0.5 0.2 -0.5 0.2 -0.5 0.2 -0.5 0.2 -0.5 0.2 -9.43 3.78 -0.5 0.2 -0.49 0.2 -0.17 0.07 -0.33 0.12 -0.49 0.2c-4.44,1.78 -8.84,3.52 -13.21,5.26l-10.4 -14.54c10.54,-4.18 21.29,-8.48 32.19,-12.88z"/>
                            <path d="M49.9 448.08l-0.09 -0.02c-0.66,-0.11 -1.31,-0.23 -1.96,-0.36l-0.11 -0.02c-0.66,-0.14 -1.31,-0.28 -1.96,-0.43l-0.1 -0.03c-0.64,-0.15 -1.27,-0.31 -1.9,-0.48l-0.11 -0.03c-0.65,-0.18 -1.29,-0.36 -1.93,-0.56l-0.03 -0.01c-0.64,-0.2 -1.27,-0.41 -1.89,-0.63l-0.08 -0.02c-1.27,-0.45 -2.52,-0.94 -3.74,-1.48l-0.04 -0.02c-0.61,-0.27 -1.22,-0.56 -1.81,-0.85l-0.01 -0.01c-1.81,-0.89 -3.55,-1.9 -5.21,-3.04l-0.02 -0.01c-1.1,-0.75 -2.15,-1.55 -3.18,-2.4l-0.07 -0.06c-0.51,-0.43 -1.01,-0.87 -1.49,-1.32l0 0 -0.01 -0.01 0 0 0 0 -0.01 -0.01 -0.01 -0.01 0 0 -0.01 -0.01 -0.02 -0.02 -0.01 -0.01 0 -0.01 -0.02 -0.02 -0.02 -0.02 -0.01 0 -0.01 -0.02 -0.03 -0.03 -0.02 -0.02 -0.01 -0.01 -0.03 -0.03 -0.03 -0.04 -0.01 -0.01 -0.02 -0.03 -0.04 -0.04 -0.03 -0.03 0 -0.01 -0.04 -0.05 -0.04 -0.05 -0.02 -0.01 -0.03 -0.04 -0.04 -0.05 -0.05 -0.05 0 -0.01 -0.05 -0.06 -0.05 -0.06 -0.03 -0.03 -0.02 -0.03 -0.06 -0.07 -0.06 -0.07 -0.06 -0.07 -0.06 -0.08 -0.03 -0.04 -0.03 -0.04 -0.07 -0.08 -0.06 -0.09 -0.01 0 -0.06 -0.08 -0.07 -0.09 -0.05 -0.06 -0.02 -0.04 -0.08 -0.09 -0.07 -0.1 -0.02 -0.02 -0.06 -0.08 -0.08 -0.11 -0.06 -0.08 -0.02 -0.03 -0.09 -0.11 -0.08 -0.11 -0.03 -0.04 -0.06 -0.08 -0.09 -0.12 -0.07 -0.1 -0.02 -0.03 -0.09 -0.12 -0.1 -0.13 -0.03 -0.05 -0.06 -0.09 -0.1 -0.13 -0.09 -0.13 -0.01 -0.02 -0.1 -0.14 -0.1 -0.14 -0.05 -0.07 -0.06 -0.08 -0.1 -0.16 -0.11 -0.15 0 0 -0.11 -0.16 -0.11 -0.17 -0.23 -0.33 -0.11 -0.18 -0.01 -0.01 -0.11 -0.16 -0.12 -0.18 -0.08 -0.12 -0.04 -0.07c8.56,3.88 18.48,5.41 28.61,5.5 1.57,2.21 3.41,4.77 4.3,5.83 1.22,1.45 4.45,5.03 6.22,6.99 -3.15,-0.17 -6.26,-0.49 -9.29,-1.01z"/>
                            <path d="M110.25 424.12c1.37,1.71 4.55,5.15 6.08,6.65 1.43,1.4 5.54,5.28 7.13,6.78l-0.62 0.22 -0.32 0.12 -0.1 0.03 -0.23 0.08 -0.67 0.24 -0.23 0.08 -0.12 0.04 -1.08 0.37 -0.01 0.01c-0.74,0.25 -1.51,0.51 -2.31,0.77l-0.32 0.11 -0.09 0.03c-1.78,0.58 -3.72,1.19 -5.8,1.81l-0.14 0.04c-3.78,1.13 -8.02,2.28 -12.55,3.34l-0.07 0.02c-1.87,0.44 -3.8,0.86 -5.76,1.26l-0.15 0.03 0 0c-1.64,-1.5 -4.27,-3.92 -6.31,-5.93 -2.39,-2.33 -5.27,-5.8 -6.59,-7.43 12.92,-2.71 23.99,-6.39 30.26,-8.67z"/>
                            <path d="M172.63 400.56c2.08,2.97 9.17,11.33 11.52,14.09 -10.76,4.16 -21.21,8.15 -31.35,11.96 -2.23,-2.11 -6.51,-6.17 -7.64,-7.28 -1.19,-1.16 -4.22,-4.64 -5.53,-6.17 10.65,-4 21.67,-8.21 33,-12.6z"/>
                            <path d="M308.6 345.76l7.56 15.73 -0.53 0.21 -0.52 0.22 -0.52 0.22 -0.52 0.21 -0.52 0.22 -0.52 0.22 -0.52 0.21 -0.53 0.22 -0.52 0.21 -1.04 0.44 -0.52 0.21 -0.52 0.22 -0.52 0.21 -0.52 0.22 -0.52 0.21 -0.52 0.22 -0.16 0.07 -0.88 0.36 -0.52 0.21 -0.52 0.22 -0.52 0.21 -0.51 0.22 -8.29 3.41 -0.52 0.22 -0.51 0.21 -0.52 0.21 -0.16 0.07 -0.35 0.14 -0.52 0.22 -0.51 0.21 -8.75 3.59 -0.51 0.21 -0.52 0.21 -8.65 -15.26 2.88 -1.19c9.93,-4.08 19.84,-8.17 29.75,-12.28l1.59 -0.66z"/>
                            <path d="M448.6 286.44c2.1,6.25 3.6,14.55 4.05,17.25l-4.9 2.11 -0.54 0.24 -0.55 0.23 -0.54 0.24 -0.55 0.23 -0.54 0.24 -0.55 0.23 -1.09 0.47 -0.55 0.23 -5.46 2.35 -0.54 0.23 -0.55 0.24 -0.54 0.23 -0.55 0.24 -0.55 0.23 -0.54 0.24 -0.55 0.23 -0.55 0.24 -0.55 0.23 -0.54 0.24 -0.55 0.23 -1.09 0.47 -0.55 0.23 -0.55 0.24 -0.55 0.23 -0.54 0.24 -0.55 0.23 -0.55 0.24 -0.55 0.23 -0.31 0.14 -0.23 0.1 -0.55 0.23 -0.55 0.23 -7.13 3.05c-0.47,-1.95 -1.31,-5.46 -1.91,-8.15 -0.61,-2.82 -1.99,-6.81 -2.71,-8.85 11.88,-5.08 23.73,-10.17 35.55,-15.26z"/>
                            <path d="M516.22 257.11c1.19,4.86 2.16,14.3 2.51,17.94l-13.16 5.74 -0.53 0.23 -0.22 0.1 -0.31 0.13 -0.53 0.23 -0.53 0.23 -9.03 3.93 -0.54 0.23 -0.53 0.23 -0.53 0.23 -0.53 0.23 -0.4 0.18 -0.14 0.05 -0.53 0.24 -0.53 0.23 -5.88 2.54c-0.55,-2.84 -1.47,-7.54 -1.77,-8.84 -0.32,-1.45 -1.54,-6.25 -2.11,-8.5 11.89,-5.15 23.66,-10.27 35.29,-15.35z"/>
                            <path d="M580.55 228.89c1.97,6.68 2.33,14.43 2.38,18.01l-0.19 0.08 -5.24 2.31 -0.47 0.21 -0.48 0.21 -0.48 0.21 -0.95 0.42 -0.47 0.21 -0.96 0.42 -0.47 0.21 -0.95 0.42 -0.48 0.21 -9.53 4.19 -0.47 0.2 -0.48 0.21 -0.48 0.21 -0.47 0.21 -0.48 0.21 -0.47 0.21 -0.48 0.21 -0.48 0.21 -6.19 2.72 -0.4 0.17 -0.78 0.35c-0.37,-3.04 -1.04,-8.4 -1.34,-10.57 -0.3,-2.14 -0.95,-5.46 -1.32,-7.32 10.9,-4.78 21.63,-9.5 32.13,-14.13z"/>
                            <path d="M641.14 202.04c1.06,2.8 2.61,9.53 2.61,9.53 0.71,3.64 0.81,6.53 0.8,8.04l-0.48 0.21 -0.47 0.21 -0.48 0.21 -0.48 0.21 -0.47 0.22 -0.48 0.21 -0.48 0.21 -0.48 0.21 -0.47 0.22 -0.48 0.21 -0.48 0.21 -0.48 0.22 -0.49 0.21 -0.48 0.22 -0.48 0.21 -0.06 0.03 -0.42 0.19 -0.48 0.21 -0.49 0.21 -0.48 0.22 -0.48 0.21 -0.49 0.22 -0.48 0.22 -0.49 0.21 -0.49 0.22 -0.48 0.21 -0.49 0.22 -0.49 0.22 -3.42 1.52 -0.49 0.21 -0.5 0.22 -0.49 0.22 -0.42 0.19 -0.07 0.03 -0.49 0.22 -0.5 0.22 -0.49 0.22 -0.5 0.22 -0.49 0.22 -2.98 1.32 -0.5 0.22 -3 1.33 -0.5 0.22 -0.5 0.22 -1 0.45 -0.28 0.12 -0.23 0.1 -0.5 0.22 -0.5 0.23 -0.51 0.22c-0.51,-9.75 -1.5,-15.58 -2.04,-18.17 10.27,-4.55 20.29,-9 29.99,-13.32z"/>
                            <path d="M698.29 176.52c2.22,7.93 2.44,14.93 2.4,18.01l-0.41 0.18 -0.41 0.18 -0.4 0.19 -0.41 0.18 -0.41 0.18 -0.41 0.19 -0.11 0.05 -0.3 0.13 -0.41 0.19 -0.42 0.18 -0.41 0.19 -2.07 0.92 -0.42 0.19 -1.67 0.75 -0.42 0.19 -1.26 0.56 -0.42 0.19 -4.69 2.1 -0.43 0.19 -0.43 0.19 -0.43 0.2 -0.43 0.19 -0.43 0.19 -0.43 0.2 -0.44 0.19 -0.43 0.19 -0.43 0.2 -0.44 0.19 -0.43 0.2 -0.44 0.19 -0.44 0.2 -0.44 0.2 -0.43 0.19 -0.13 0.06 -0.31 0.14 -0.44 0.19 -0.44 0.2 -0.44 0.2 -0.44 0.19 -0.45 0.2 -0.44 0.2 -2.67 1.19c0.13,-1.93 0.13,-4.15 0.13,-4.15 -0.24,-6.34 -1.64,-11.56 -2.38,-13.92 9.44,-4.22 18.51,-8.28 27.16,-12.16z"/>
                            <path d="M749.26 153.57c2.52,8.02 2.79,15.11 2.78,17.84l-0.72 0.32 -0.36 0.17 -0.36 0.16 -0.37 0.17 -0.37 0.16 -0.36 0.17 -0.37 0.16 -1.81 0.82 -0.05 0.02 -0.38 0.17 -0.37 0.17 -0.38 0.17 -0.38 0.17 -0.38 0.18 -0.38 0.17 -0.38 0.17 -0.38 0.17 -0.39 0.18 -0.38 0.17 -0.39 0.18 -0.39 0.17 -0.39 0.17 -0.39 0.18 -0.38 0.18 -0.4 0.17 -0.39 0.18 -0.39 0.18 -0.3 0.14 -0.1 0.04 -0.39 0.18 -0.4 0.18 -0.4 0.17 -0.4 0.19 -0.8 0.36 -0.4 0.18 -0.41 0.18 -0.4 0.18 -0.41 0.19 -0.41 0.18 -0.4 0.18 -0.41 0.19 -0.41 0.18 -0.41 0.19 -0.42 0.18 -0.41 0.19 -0.41 0.19 -0.28 0.12 -0.14 0.06 -0.42 0.19 -0.41 0.19 -0.42 0.19 -0.42 0.19 -0.42 0.19 -0.42 0.19 -0.43 0.19 -0.42 0.19 -0.43 0.19 -0.42 0.19 -0.43 0.19 -0.43 0.2 -0.43 0.19c0.09,-1.9 0.11,-3.98 0.11,-3.98 0.08,-5.05 -1.58,-11.59 -2.28,-14.13 8.89,-4.01 17.15,-7.73 24.69,-11.14z"/>
                            <path d="M793.73 133.4c2.16,7.64 2.11,14.78 1.91,18.22l-0.21 0.1 -0.22 0.09 -0.22 0.1 -0.22 0.11 -0.22 0.1 -0.24 0.1 -0.23 0.11 -0.24 0.11 -0.24 0.11 -0.25 0.11 -0.13 0.06 -0.37 0.17 -0.25 0.12 -0.53 0.24 -0.27 0.12 -12.8 5.82 -0.4 0.19 -0.41 0.18 -1.24 0.57 -0.43 0.19 -0.42 0.19 -0.43 0.19 -0.43 0.2 -0.43 0.2c0.06,-1.39 0.12,-3.38 0.13,-6.14 0.01,-4.99 -1.16,-9.97 -1.76,-12.22 8.85,-4.02 15.79,-7.17 20.55,-9.34z"/>
                            <path d="M147.81 362.74l6.41 10.62c8.48,-9.23 17.63,-20.11 24.75,-30.94 0,0 -11.81,8.63 -31.16,20.32z"/>
                            <path d="M119.41 378.87c-8.87,4.73 -18.47,9.54 -28.59,14.18 1.37,2.58 6.92,12.99 9.32,16.66 9.48,-3.21 19.32,-7.08 29.34,-11.72l0.04 -0.03c-1.29,-2.34 -8.34,-15.15 -10.11,-19.09z"/>
                            <path d="M62.17 405.1c-8.56,3.25 -17.34,6.27 -26.25,8.93 0.88,1.73 3.63,7.14 4.88,9.54 8.25,-1.09 18.07,-2.74 28.98,-5.24 -1.16,-1.86 -5.35,-8.67 -7.61,-13.23z"/>
                            <path d="M311.17 273.52c4.42,6.56 10.58,16.55 13.59,21.49 -7.92,10.47 -16.73,21.13 -26.3,31.2 -5.37,-10.04 -16.02,-27.5 -18.67,-31.82 9.23,-5.62 19.89,-12.56 31.38,-20.87z"/>
                            <path d="M340.41 250.57c9.49,-8.07 19.16,-16.96 28.73,-26.68 0,0 -7.72,15.93 -21.34,37.62 -1.58,-2.37 -5.5,-8.15 -7.39,-10.94z"/>
                            <path d="M246.55 312.49c3.47,5.51 15.82,26.63 18.02,30.41 -11.19,5.43 -23.68,11.4 -35.07,16.65l-8.04 -13.39c10.24,-11.97 20.78,-25.22 25.09,-33.67z"/>
                            <path d="M782.72 95.54c0.76,0.85 1.52,1.7 2.25,2.56l-9.98 -0.84c2.42,-0.53 4.84,-1.06 7.25,-1.61l0.48 -0.11z"/>
                            <path d="M726.11 107.09c2.1,3.38 11.07,17.84 14.79,25.28l24.86 -8.07c-0.9,-1.65 -3.19,-5.6 -7.55,-11.9 -10.77,-1.79 -22.63,-3.76 -32.1,-5.31z"/>
                            <path d="M700.03 116.01c-7.56,1.92 -17.09,4 -29,6.11l11.59 18.43c11.14,0.57 22.21,0.75 32.31,0.25l0.02 0c-1.12,-2.12 -8.07,-15.12 -14.92,-24.79z"/>
                            <path d="M637.32 127.16c-10.81,1.37 -22.85,2.7 -36.27,3.93 0,0 18.73,3.34 43.26,6.16l-6.99 -10.09z"/>
                        </g>
                        <g fill={logot}>
                            <path d="M532.16 187.79c8.15,-9.51 18.16,-16.62 23.71,-8.06 5.93,9.2 -2.14,30.64 -28.39,42.66 -5.43,2.48 -12.48,4.86 -17.52,5.79 -0.21,0.03 -0.27,0.02 -0.27,0 -0.01,-0.03 0.08,-0.07 0.23,-0.13 5.43,-2.14 10.48,-4.35 15.39,-7.29 15.88,-9.51 24.49,-22.4 21.64,-26.47 -2.16,-3.09 -9.69,0.01 -17.49,6.96 -4.68,4.18 -8.94,9.38 -13.71,15.08 -11.67,13.89 -30.29,24.6 -48.73,16.56 -8.99,-3.91 -8.81,-9.52 0,-10.57 6.73,-0.81 13.7,2.16 19.94,4.27 3.7,1.24 6.92,2.06 11.08,2.11 0.14,0 0.2,0.02 0.21,0.04 0,0.04 -0.13,0.06 -0.34,0.11 -5.12,0.74 -10.04,-0.22 -13.9,-0.99 -1.92,-0.4 -3.81,-0.44 -5.79,-0.17 -5.63,0.76 -6.58,3.3 -1.57,4.8 3.38,1 8.65,1.45 15.06,-0.29 0.56,-0.16 1.13,-0.33 1.72,-0.51l-0.06 0c0,0 0.09,-0.03 0.29,-0.08 19.93,-6.63 25.86,-29.06 38.5,-43.82z"/>
                        </g>
                        <g stroke="#000000" strokeWidth="0.3" fill="transparent">
                            <path d="M59.23 449.1c-3.12,-3.3 -5.97,-6.54 -8.28,-9.59 -8.69,-11.44 -14.91,-24.77 -20.89,-38.12 -7.11,-15.9 -13.62,-31.22 -19.9,-46.51"/>
                            <path d="M123.47 437.55c-4.56,-4.13 -8.9,-8.35 -12,-11.95 -6,-6.98 -10.92,-14.93 -15.5,-23.04 -10.45,-18.53 -19.09,-37.82 -27.96,-57.01 -7.47,-16.13 -15.02,-32.07 -22.67,-47.98"/>
                            <path d="M92.89 446.15c-3.18,-2.86 -6.12,-5.65 -8.41,-8.11 -11.06,-11.91 -19.17,-26.48 -26.55,-41.22 -10.01,-19.97 -18.66,-40.23 -27.24,-60.57 -1.45,-3.46 -2.88,-6.86 -4.3,-10.23"/>
                            <path d="M184.12 414.66c-3.03,-3.58 -5.92,-7.05 -8.01,-9.67 -11.69,-14.63 -21.65,-30.63 -30.98,-46.94 -17.17,-30.03 -32.2,-61.09 -47.15,-92.2l-9.97 -20.77"/>
                            <path d="M152.77 426.62c-5.29,-4.9 -10.39,-10 -13.75,-14.23 -8.02,-10.1 -13.66,-22.06 -19.62,-33.75 -10.57,-20.83 -22.07,-40.84 -32.81,-61.28 -7.62,-14.5 -15.02,-29.59 -22.2,-44.8"/>
                            <path d="M217.22 401.69c-5.91,-8.09 -12.12,-16.72 -16.62,-23.54 -16.14,-24.41 -29.88,-50.36 -43.96,-76.05 -14.94,-27.2 -30.26,-54.13 -45.4,-81.18"/>
                            <path d="M248.81 389.06c-5.67,-8.4 -11.68,-17.33 -16.13,-24.43 -16.07,-25.6 -30.64,-52.12 -45.42,-78.51 -16.66,-29.81 -33.56,-59.47 -50.61,-89.04"/>
                            <path d="M283.09 375.12c-3.3,-5.8 -6.67,-11.69 -9.26,-16.21 -19.55,-34.2 -40.25,-67.74 -60.73,-101.42 -15.9,-26.18 -33.27,-55.09 -49.94,-82.96"/>
                            <path d="M349.55 347.55c-2.2,-6.15 -4.51,-12.36 -6.49,-17.06 -11.73,-27.84 -29.18,-53.03 -46.08,-78.4 -25.95,-38.92 -51.57,-79.87 -76.32,-120.06"/>
                            <path d="M316.15 361.49c-3.35,-7.11 -6.87,-14.44 -9.74,-20.09 -14.81,-29.13 -32.55,-56.69 -50.04,-84.36 -21.16,-33.51 -43.43,-69.56 -64.84,-104.46"/>
                            <path d="M382.82 333.53c-3.34,-10.42 -7.09,-21.45 -10.89,-29.91 -9.86,-21.9 -24,-41.77 -37.42,-61.95 -18.35,-27.56 -35.35,-55.7 -52.96,-83.51 -9.65,-15.26 -19.73,-30.75 -29.85,-46.14"/>
                            <path d="M417.68 318.7c-1.03,-4.71 -2.12,-9.28 -3.18,-12.66 -4.63,-14.73 -12.07,-28.53 -19.98,-41.92 -11.23,-18.98 -23.41,-37.14 -35.54,-55.39 -25.41,-38.26 -50.53,-76.86 -76.37,-114.93"/>
                            <path d="M452.63 303.71c-0.64,-3.7 -1.32,-7.19 -1.97,-9.79 -4.69,-18.66 -14.72,-35.73 -25.08,-52.37 -23.15,-37.24 -47.8,-72.39 -72.76,-107.6 -13.33,-18.81 -26.72,-37.61 -40.15,-56.43"/>
                            <path d="M484.84 289.79c-1.94,-9.66 -4.25,-19.78 -6.98,-27.43 -6.46,-18.06 -17.19,-34.5 -28.06,-50.75 -34.29,-51.3 -69.83,-100.74 -105.66,-149.78"/>
                            <path d="M518.72 275.06c-0.5,-5.97 -1.19,-11.87 -2.16,-16.25 -4.5,-20.31 -16.28,-38.7 -28.25,-56.45 -17.07,-25.29 -34.53,-49.26 -52.24,-73.27 -20.11,-27.23 -40.44,-54.35 -60.92,-81.54"/>
                            <path d="M551.08 260.9c-1.21,-10.87 -2.98,-22.18 -6.01,-30.87 -5.83,-16.77 -16.96,-31.62 -27.84,-46.42 -34.08,-46.33 -65.81,-92.14 -100.27,-136.6 -3.04,-3.92 -6.03,-7.75 -9.01,-11.54"/>
                            <path d="M582.93 246.9c-0.03,-2.6 -0.12,-4.98 -0.31,-6.83 -1.67,-16.18 -9.47,-31.46 -17.94,-45.99 -17.71,-30.38 -38.35,-57.43 -59.16,-84.6 -22.21,-29 -43.22,-56.32 -64.85,-84.41"/>
                            <path d="M613.17 233.54c-0.46,-7.31 -1.21,-14.66 -2.5,-20.26 -4.52,-19.45 -15.91,-37.06 -27.58,-54.11 -26.79,-39.19 -54.97,-75.38 -84.07,-111.35 -9.23,-11.41 -17.54,-21.58 -25.83,-31.64"/>
                            <path d="M644.51 219.63c0.07,-8.15 -2.89,-16.58 -6.21,-24.61 -8.17,-19.7 -18.5,-36.91 -29.69,-53.73 -30.05,-45.25 -66.23,-87.67 -101.58,-130.11l-1.78 -2.13"/>
                            <path d="M673.38 206.75c0.85,-10.17 -2.69,-20.74 -6.99,-30.63 -8.77,-20.19 -20.7,-37.51 -33.18,-54.57 -27.05,-36.98 -56.7,-72.72 -86.46,-108.13l-8.12 -9.64"/>
                            <path d="M700.66 194.54c0.14,-5.34 -0.7,-10.82 -1.94,-16.08 -1.24,-5.23 -2.86,-10.25 -4.81,-15.16 -4.22,-10.67 -9.95,-20.81 -16.07,-30.65 -18.68,-29.95 -41.2,-57.03 -64.03,-83.78 -13.68,-16.03 -27.47,-31.95 -41.32,-47.81"/>
                            <path d="M726.77 182.81c0.09,-1.77 0.11,-3.46 0.04,-5.02 -0.4,-8.31 -2.94,-16.55 -6.11,-24.41 -6.57,-16.26 -15.93,-30.91 -25.97,-45.04 -19.14,-26.88 -40.82,-51.83 -62.38,-76.84 -9.39,-10.88 -18.16,-21.09 -26.77,-31.14"/>
                            <path d="M752.01 171.42c0.15,-9.04 -2.48,-18.19 -5.85,-26.87 -4.94,-12.66 -11.45,-24.28 -18.72,-35.44 -13.27,-20.35 -29.06,-39.13 -44.83,-57.8 -13.74,-16.27 -27.47,-32.48 -41.44,-48.45"/>
                            <path d="M774.88 161.07c0.04,-0.73 0.07,-1.44 0.09,-2.14 0.2,-8.45 -1.5,-17.07 -4.58,-24.97 -4.65,-11.91 -12.42,-22.21 -20.22,-32.44 -20.97,-27.47 -42.01,-54.27 -64.31,-80.14 -2.91,-3.38 -5.81,-6.7 -8.69,-9.97"/>
                            <path d="M795.65 151.62c0.06,-0.85 0.1,-1.69 0.12,-2.52 0.23,-11.96 -4.2,-24.05 -10.01,-34.93 -5.29,-9.91 -11.75,-18.8 -18.41,-27.57 -6.63,-8.75 -13.46,-17.38 -20.43,-25.91"/>
                        </g>
                    </svg>
                </div>          

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 800 210.1" ref={svg_bottom}>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={corb}>
                            <path d="M396.9 210.09c-262.74,-0.4 -396.9,-51.94 -396.9,-51.94l0 -61.86c100.98,-101.17 400,-96.25 400,-96.25l0.87 -0.01c17.6,-0.19 301.3,-1.76 399.13,96.26l0 61.86c0,0 -135.21,51.95 -400,51.95l-3.1 -0.01z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                            <path d="M23.37 89.08c-8.26,4.79 -16.23,10.14 -23.37,16.08l0 5.19c3.59,-2.61 10.95,-7.33 23.37,-12.81l0 -8.46z"/>
                            <path d="M77.45 65.19c-8.11,2.74 -17.39,6.19 -27.03,10.37l0 11.93c7.94,-2.49 16.92,-4.97 27.03,-7.35l0 -14.95z"/>
                            <path d="M105.46 56.98l0 17.49c8.7,-1.51 18.02,-2.93 28,-4.19l0 -9.49c-8.13,-1.46 -17.61,-2.87 -27.9,-3.83l-0.1 0.02z"/>
                            <path d="M192.69 40.81c-9.17,1.36 -19.44,3.03 -30.8,5.09 0,0 8.69,0.7 30.8,8.43l0 -13.52z"/>
                            <path d="M222.71 36.99l0 28.37c3.68,-0.06 14.22,-0.11 30.44,0.76l0 -30.38c-0.92,-0.24 -1.85,-0.49 -2.78,-0.73 0,0 -9.19,0.05 -27.66,1.98z"/>
                            <path d="M284.6 45.91l0 22.58c9.89,0.95 20.78,2.19 32.57,3.78l0 -12.38c-9.43,-4.53 -20.48,-9.42 -32.57,-13.98z"/>
                            <path d="M515 46.06l0 22.47c-9.99,0.97 -21.01,2.22 -32.94,3.85l0 -12.12c9.49,-4.58 20.68,-9.55 32.94,-14.2z"/>
                            <path d="M546.56 35.82c1.02,-0.28 2.04,-0.54 3.07,-0.81 0,0 9,0.05 27.09,1.92l0 28.42c-4.13,-0.05 -14.55,-0.06 -30.16,0.78l0 -30.31z"/>
                            <path d="M606.88 40.75c9.28,1.36 19.69,3.06 31.23,5.15 0,0 -8.79,0.72 -31.23,8.59l0 -13.74z"/>
                            <path d="M693.84 57.01l0 17.33c-8.52,-1.46 -17.62,-2.84 -27.36,-4.07l0 -9.47c7.99,-1.44 17.27,-2.81 27.36,-3.79z"/>
                            <path d="M721.77 64.93c8.32,2.79 17.94,6.34 27.93,10.68l0 11.92c-8.17,-2.57 -17.45,-5.13 -27.93,-7.57l0 -15.03z"/>
                            <path d="M775.83 88.62c8.56,4.91 16.8,10.42 24.17,16.54l0 5.19c-3.66,-2.66 -11.27,-7.54 -24.17,-13.16l0 -8.57z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M50.42 75.56c-8.94,3.88 -18.16,8.37 -27.05,13.52l0 8.46c7.22,-3.19 16.15,-6.63 27.05,-10.05l0 -11.93z"/>
                            <path d="M105.46 56.98c-1.15,0.27 -12.43,2.94 -28.01,8.21l0 14.95c8.51,-1.99 17.83,-3.9 28.01,-5.67l0 -17.49z"/>
                            <path d="M133.46 60.79l0 9.49c8.93,-1.14 18.4,-2.15 28.43,-3.03 0,0 -11.19,-3.36 -28.43,-6.46z"/>
                            <path d="M222.71 36.99c-8.18,0.85 -18.19,2.07 -30.02,3.82l0 13.52c7.8,2.73 17.26,6.32 28.57,11.06 0,0 0.5,-0.02 1.45,-0.03l0 -28.37z"/>
                            <path d="M253.15 35.74l0 30.38c8.86,0.47 19.41,1.21 31.45,2.37l0 -22.58c-9.9,-3.73 -20.49,-7.25 -31.45,-10.17z"/>
                            <path d="M317.17 59.89l0 12.38c10.14,1.38 20.95,3.02 32.33,4.98 0,0 -12.6,-7.89 -32.33,-17.36z"/>
                            <path d="M482.06 60.26l0 12.12c-9.91,1.36 -20.45,2.96 -31.56,4.87 0,0 12.28,-7.68 31.56,-16.99z"/>
                            <path d="M546.56 35.82l0 30.31c-8.9,0.48 -19.49,1.23 -31.56,2.4l0 -22.47c9.93,-3.76 20.57,-7.3 31.56,-10.24z"/>
                            <path d="M576.72 36.93c8.18,0.85 18.24,2.06 30.16,3.82l0 13.74c-7.7,2.7 -17.03,6.24 -28.14,10.9 0,0 -0.69,-0.03 -2.02,-0.04l0 -28.42z"/>
                            <path d="M666.48 60.8l0 9.47c-8.91,-1.13 -18.36,-2.15 -28.37,-3.02 0,0 11.16,-3.35 28.37,-6.45z"/>
                            <path d="M693.84 57.01c0.19,-0.01 0.4,-0.03 0.6,-0.05 0,0 11.28,2.59 27.33,7.97l0 15.03c-8.5,-1.97 -17.79,-3.86 -27.93,-5.62l0 -17.33z"/>
                            <path d="M749.7 75.61c8.62,3.75 17.53,8.07 26.13,13.01l0 8.57c-7.05,-3.08 -15.68,-6.37 -26.13,-9.66l0 -11.92z"/>
                        </g>
                        <g fill={logob}>
                            <path d="M377.76 57.81c-8.81,4.52 -18.29,6.73 -19.78,-1.21 -1.59,-8.53 10.75,-21.81 33.59,-22.74 4.71,-0.19 10.61,0.19 14.57,1.02 0.16,0.04 0.2,0.06 0.2,0.08 0,0.02 -0.08,0.03 -0.21,0.02 -4.61,-0.06 -8.98,0.05 -13.46,0.73 -14.49,2.2 -24.66,9.05 -23.79,12.9 0.65,2.91 7.1,2.9 14.9,0.15 4.67,-1.65 9.36,-4.2 14.57,-6.94 12.71,-6.67 29.57,-8.93 40.67,2.49 5.41,5.56 3.59,9.61 -3.19,7.75 -5.15,-1.43 -9.38,-5.7 -13.31,-9.11 -2.35,-2.03 -4.45,-3.59 -7.49,-4.88 -0.1,-0.04 -0.14,-0.07 -0.13,-0.09 0.01,-0.02 0.1,-0.01 0.27,0.03 3.98,0.97 7.3,3.16 9.88,4.89 1.3,0.86 2.66,1.46 4.19,1.86 4.37,1.14 5.83,-0.45 2.59,-3.04 -2.18,-1.75 -5.89,-3.66 -11.11,-4.31 -0.46,-0.05 -0.93,-0.1 -1.41,-0.14l0.05 0.02c0,0 -0.09,-0.02 -0.26,-0.03 -16.57,-1.13 -27.65,13.53 -41.34,20.55z"/>
                        </g>
                    </svg>
                </div>
            </div>

            <div className="row mb-4 g-0">
                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Top: </b>{Cores[cort].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cort} clique={ setCort }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor1].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor1} clique={ setCor1 }/></div>
                        <div><div><b>Design 02: </b>{Cores[cor2].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor2} clique={ setCor2 }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logot} clique={setLogot}/>                                
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Bottom: </b>{Cores[corb].tecido}</div><PaletaCores paleta="Paleta01" selecionada={corb} clique={ setCorb }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logob} clique={setLogob}/>
                        </div>
                    </div>
                </div>

                <div className="mt-3 d-flex justify-content-center">
                    <button className="btn btn-lg btn-success d-block d-sm-none w-100" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                    <button className="btn btn-lg btn-success d-none d-sm-block w-50" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                </div>

            </div>

        </ColunaCentral>
        <Rodape/>

        <FormSolid parapente={parapente} tamanhos={tamanhos} show={showModal} fechar={setShowModal} imgtop={imgtop} imgbottom={imgbottom} corestop={coresT} coresbottom={coresB}/>

    </>
}

export default Fun2;