import React, { useContext, useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

import { LinguaContext } from '../../Contextos/Linguas';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Bloco from '../../Componentes/Bloco';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Cores from './Cores.js';
import FormSolid from '../../Componentes/FormSolid';
import PaletaAdesivos from '../../Componentes/PaletaAdesivos';
import PaletaCores from '../../Componentes/PaletaCores';
import Rodape from '../../Componentes/Rodape';
import T from '../../Traducoes';

function Runnexc() {
    const { idioma } = useContext(LinguaContext);
    const svg_top = useRef(null);
    const svg_bottom = useRef(null);
    const parapente = 'Runner XC';
    const [ imgtop, setImgtop ] = useState('');
    const [ imgbottom, setImgbottom ] = useState('');
    const [ coresT, setCoresT ] = useState('');
    const [ coresB, setCoresB ] = useState('');
    const [ cort, setCort ] = useState('#FFFFFF');
    const [ corb, setCorb ] = useState('#FFFFFF');
    const [ cor1, setCor1 ] = useState('#FFFFFF');
    const [ cor2, setCor2 ] = useState('#FFFFFF');
    const [ cor3, setCor3 ] = useState('#FFFFFF');
    const [ cor4, setCor4 ] = useState('#FFFFFF');
    const [ logot, setLogot ] = useState('transparent');
    const [ logob, setLogob ] = useState('transparent');
    const [ showModal, setShowModal ] = useState(false);
    const tamanhos = [
        {value: "150", label: "150" },
        {value: "165", label: "165" },
        {value: "180", label: "180" },
        {value: "195", label: "195" },
        {value: "210", label: "210" },
        {value: "225", label: "225" },
        {value: "240", label: "240" },
    ];
    
    function padrao1() {
        setCort('#FBE111');
        setCorb('#FBE111');
        setCor1('#FBE111');
        setCor2('#191D24');
        setCor3('#191D24');
        setCor4('#191D24');
    }
        
    function padrao2() {
        setCort('#1F4194');
        setCorb('#1F4194');
        setCor1('#1F4194');
        setCor2('#191D24');
        setCor3('#FFFFFF');
        setCor4('#FFFFFF');
    }

    function padrao3() {
        setCort('#F00008');
        setCorb('#F00008');
        setCor1('#F00008');
        setCor2('#191D24');
        setCor3('#FFFFFF');
        setCor4('#FFFFFF');
    }

    function padrao4() {
        setCort('#FBE111');
        setCorb('#FBE111');
        setCor1('#FBE111');
        setCor2('#191D24');
        setCor3('#FFFFFF');
        setCor4('#FFFFFF');
    }

    useEffect(() => {
        padrao1();
    },[]);

    function gerarImagem() {
        htmlToImage.toPng(svg_top.current)
        .then(function (dataURL) {
            setImgtop(dataURL);
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
        
        htmlToImage.toPng(svg_bottom.current)
        .then(function (dataURL) {
            setImgbottom(dataURL)
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
    }

    function abrirModalEnviar() {
        gerarImagem();
        let lt="";
        if (logot==="transparent") lt="NENHUM / NO LOGO";
        if (logot==="#000000") lt="Preto / Black";
        if (logot==="#FF0000") lt="Vermelho / Red";

        let lb="";
        if (logob==="transparent") lb="NENHUM / NO LOGO";
        if (logob==="#000000") lb="Preto / Black";
        if (logob==="#FF0000") lb="Vermelho / Red";

        const novasCoresTop = 
        'Top: '+Cores[cort].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Design 03: '+Cores[cor3].descricao+'\n'+
        'Design 04: '+Cores[cor4].descricao+'\n'+
        'Logo: '+lt+'\n\r';

        const novasCoresBottom = 
        'Bottom: '+Cores[corb].descricao+'\n'+
        'Logo: '+lb+'\n';

        setCoresT(novasCoresTop);
        setCoresB(novasCoresBottom);

        setShowModal(true);
    }

    return <>
        <BarraSuperior/>
        <Bloco className="container" id="195"/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <div className="row mb-4">

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="w-100 d-flex justify-content-right mb-3 overflow-auto">
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/runner_padrao1.jpg" onClick={() => padrao1()} alt="1" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/runner_padrao2.jpg" onClick={() => padrao2()} alt="2" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/runner_padrao3.jpg" onClick={() => padrao3()} alt="3" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/runner_padrao4.jpg" onClick={() => padrao4()} alt="4" style={{maxWidth: '70px'}}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 446.41 92.25" ref={svg_top}>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={cort}>
                            <path d="M348.77 38.56c0,0 12.43,-1.63 31.12,6.41 0,0 40.89,-2.35 55.08,29.77l-0.01 0c0,0 -72.38,17.26 -211.76,17.26 -139.37,0 -211.76,-17.26 -211.76,-17.26l0.02 0c14.19,-32.12 55.08,-29.77 55.08,-29.77 18.68,-8.04 31.11,-6.41 31.11,-6.41l15.26 4.23c-20.63,-1.1 -38.76,5.27 -38.76,5.27 0,0 23.83,-3.15 46.83,2.43 9.75,2.37 19.82,3.22 29.79,2.13 5.55,-0.61 11.76,-1.77 18.24,-3.83 4.22,-1.34 8.31,-3.08 12.28,-5.07 5.79,-2.9 21.02,-9.74 36.17,-9.74l11.48 0c15.15,0 30.38,6.84 36.18,9.74 3.96,1.99 8.05,3.73 12.28,5.07 6.48,2.06 12.68,3.22 18.23,3.83 9.98,1.09 20.05,0.24 29.8,-2.13 22.99,-5.58 46.82,-2.43 46.82,-2.43 0,0 -18.13,-6.37 -38.76,-5.27l15.28 -4.23z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                            <path d="M223.2 0.25c-59.66,0 -103.4,4.29 -135.45,10.44l0.08 -0.01c0,0 12.61,3.53 22.09,10.58 9.69,7.21 45.23,20.12 71.96,8.85 0,0 -30.01,18.94 -74.72,3.61 -44.07,-15.12 -93.34,1.66 -105.13,20.04l0.53 5.17 0.41 3.88c5.59,-6.93 35.56,-40.75 94.68,-24.25l15.26 4.23c0,0 28.58,9.53 51.98,1 23.41,-8.54 39.29,-14.74 52.57,-14.74l11.48 0c13.28,0 29.17,6.2 52.57,14.74 23.41,8.53 51.98,-1 51.98,-1l15.28 -4.23c59.12,-16.5 89.09,17.32 94.68,24.25l0.38 -3.79 0.54 -5.26c-11.78,-18.38 -61.06,-35.16 -105.13,-20.04 -44.7,15.33 -74.72,-3.61 -74.72,-3.61 26.74,11.27 62.27,-1.64 71.96,-8.85 9.49,-7.05 22.1,-10.58 22.1,-10.58l0.12 0.02c-32.05,-6.16 -75.8,-10.45 -135.5,-10.45z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M109.92 21.26c-9.48,-7.05 -22.09,-10.58 -22.09,-10.58 -82.26,15.8 -87.58,43.62 -87.58,43.62l0.86 9.73 0.94 10.71 2.11 0 -1.6 -15.81 -0.53 -5.17c11.79,-18.38 61.06,-35.16 105.13,-20.04 44.71,15.33 74.72,-3.61 74.72,-3.61 -26.73,11.27 -62.27,-1.64 -71.96,-8.85z"/>
                            <path d="M358.58 10.68c0,0 -12.61,3.53 -22.1,10.58 -9.69,7.21 -45.22,20.12 -71.96,8.85 0,0 30.02,18.94 74.72,3.61 44.07,-15.12 93.35,1.66 105.13,20.04l-0.54 5.26 -1.56 15.72 2.09 0 0.94 -10.71 0.85 -9.73c0,0 -5.31,-27.76 -87.57,-43.62z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor3}>
                            <path d="M333.49 42.79c0,0 -28.57,9.53 -51.98,0.99 -23.4,-8.53 -39.29,-14.73 -52.57,-14.73l-11.48 0c-13.28,0 -29.16,6.2 -52.57,14.73 -23.4,8.54 -51.98,-0.99 -51.98,-0.99 -20.63,-1.1 -38.76,5.27 -38.76,5.27 0,0 23.83,-3.15 46.83,2.43 9.75,2.37 19.82,3.22 29.79,2.13 5.55,-0.61 11.76,-1.77 18.24,-3.83 4.22,-1.34 8.31,-3.08 12.28,-5.07 5.79,-2.9 21.02,-9.74 36.17,-9.74l11.48 0c15.15,0 30.38,6.84 36.18,9.74 3.96,1.99 8.05,3.73 12.28,5.07 6.48,2.06 12.68,3.22 18.23,3.83 9.98,1.09 20.05,0.24 29.8,-2.13 22.99,-5.58 46.82,-2.43 46.82,-2.43 0,0 -18.13,-6.37 -38.76,-5.27z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor4}>
                            <path d="M2.97 62.81l1.19 11.93 7.3 0c14.19,-32.12 55.08,-29.77 55.08,-29.77 18.68,-8.04 31.11,-6.41 31.11,-6.41 -59.12,-16.5 -89.09,17.32 -94.68,24.25z"/>
                            <path d="M443.45 62.81l-1.18 11.93 -7.3 0c-14.19,-32.12 -55.08,-29.77 -55.08,-29.77 -18.69,-8.04 -31.12,-6.41 -31.12,-6.41 59.12,-16.5 89.09,17.32 94.68,24.25z"/>
                        </g>
                        <g fill={logot}>
                            <path d="M207.8 56.86c-6.1,3.13 -12.66,4.66 -13.69,-0.84 -1.1,-5.9 7.44,-15.1 23.25,-15.74 3.26,-0.13 7.34,0.13 10.09,0.71 0.11,0.02 0.14,0.03 0.14,0.05 -0.01,0.02 -0.06,0.02 -0.15,0.02 -3.19,-0.05 -6.21,0.03 -9.31,0.5 -10.03,1.52 -17.08,6.27 -16.48,8.93 0.45,2.01 4.92,2.01 10.32,0.1 3.23,-1.14 6.48,-2.9 10.09,-4.8 8.79,-4.62 20.46,-6.19 28.15,1.72 3.74,3.85 2.48,6.66 -2.2,5.37 -3.58,-0.99 -6.5,-3.95 -9.23,-6.31 -1.61,-1.41 -3.08,-2.49 -5.17,-3.38 -0.08,-0.03 -0.1,-0.05 -0.1,-0.06 0.01,-0.02 0.07,-0.01 0.19,0.02 2.75,0.68 5.05,2.19 6.84,3.39 0.9,0.59 1.84,1.01 2.91,1.29 3.01,0.78 4.02,-0.32 1.79,-2.11 -1.51,-1.21 -4.08,-2.54 -7.7,-2.98 -0.32,-0.04 -0.64,-0.07 -0.98,-0.1l0.04 0.01c0,0 -0.06,-0.01 -0.17,-0.02 -11.49,-0.78 -19.15,9.37 -28.63,14.23z"/>
                        </g>
                    </svg>
                </div>          

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 608.27 125.39" ref={svg_bottom}>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={corb}>                           
                            <path d="M304.14 0.14c-288.95,0 -304,73.7 -304,73.7l2.45 27.87 12.81 0c0,0 98.7,23.54 288.74,23.54 190.04,0 288.73,-23.54 288.73,-23.54l12.81 0 2.45 -27.87c0,0 -15.05,-73.7 -303.99,-73.7z"/>
                        </g>
                        {/* <g stroke="#A6A6A6" strokeWidth="1" fill="#FFFFFF">
                            <path d="M149.68 28.8c-12.94,-9.62 -30.13,-14.44 -30.13,-14.44 -112.15,21.55 -119.41,59.48 -119.41,59.48l1.17 13.26 1.28 14.61 2.89 0 -2.19 -21.56 -0.72 -7.04c16.07,-25.06 83.26,-47.94 143.34,-27.33 60.96,20.9 101.89,-4.92 101.89,-4.92 -36.46,15.37 -84.91,-2.24 -98.12,-12.06z"/>
                            <path d="M488.72 14.36c0,0 -17.19,4.82 -30.13,14.44 -13.21,9.82 -61.66,27.43 -98.12,12.06 0,0 40.93,25.82 101.89,4.92 60.09,-20.61 127.27,2.27 143.34,27.33l-0.73 7.17 -2.13 21.43 2.85 0 1.28 -14.61 1.16 -13.26c0,0 -7.24,-37.85 -119.41,-59.48z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="1" fill="#FFFFFF">
                            <path d="M454.52 58.15c0,0 -38.96,13 -70.88,1.35 -31.91,-11.64 -53.57,-20.09 -71.68,-20.09l-15.65 0c-18.11,0 -39.77,8.45 -71.68,20.09 -31.91,11.65 -70.88,-1.35 -70.88,-1.35 -28.12,-1.5 -52.84,7.19 -52.84,7.19 0,0 32.49,-4.3 63.84,3.31 13.3,3.22 27.03,4.39 40.63,2.9 7.57,-0.83 16.03,-2.42 24.86,-5.22 5.77,-1.83 11.34,-4.2 16.75,-6.91 7.9,-3.96 28.66,-13.28 49.32,-13.28l15.65 0c20.66,0 41.42,9.32 49.33,13.28 5.4,2.71 10.98,5.08 16.74,6.91 8.84,2.8 17.3,4.39 24.87,5.22 13.6,1.49 27.33,0.32 40.62,-2.9 31.36,-7.61 63.84,-3.31 63.84,-3.31 0,0 -24.71,-8.69 -52.84,-7.19z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="1" fill="#FFFFFF">
                            <path d="M3.85 85.45l1.61 16.26 9.96 0c19.35,-43.79 75.1,-40.59 75.1,-40.59 25.48,-10.96 42.43,-8.74 42.43,-8.74 -80.61,-22.5 -121.48,23.61 -129.1,33.07z"/>
                            <path d="M604.45 85.45l-1.61 16.26 -9.96 0c-19.35,-43.79 -75.1,-40.59 -75.1,-40.59 -25.47,-10.96 -42.43,-8.74 -42.43,-8.74 80.62,-22.5 121.48,23.61 129.1,33.07z"/>
                        </g> */}
                        <g fill={logob}>
                            <path d="M282.32 75.43c-8.65,4.44 -17.94,6.6 -19.4,-1.18 -1.56,-8.36 10.54,-21.39 32.93,-22.3 4.63,-0.19 10.41,0.19 14.3,1 0.15,0.04 0.19,0.06 0.19,0.08 0,0.02 -0.07,0.03 -0.2,0.02 -4.53,-0.06 -8.81,0.05 -13.2,0.71 -14.21,2.16 -24.18,8.88 -23.34,12.65 0.65,2.86 6.98,2.85 14.62,0.15 4.58,-1.62 9.19,-4.11 14.29,-6.8 12.46,-6.55 28.99,-8.77 39.88,2.43 5.29,5.45 3.51,9.44 -3.12,7.6 -5.07,-1.4 -9.21,-5.58 -13.07,-8.93 -2.29,-1.99 -4.36,-3.52 -7.33,-4.78 -0.11,-0.04 -0.14,-0.07 -0.14,-0.09 0.01,-0.03 0.1,-0.01 0.27,0.03 3.9,0.96 7.16,3.1 9.69,4.79 1.27,0.85 2.61,1.44 4.11,1.83 4.28,1.11 5.71,-0.44 2.54,-2.98 -2.14,-1.72 -5.78,-3.59 -10.9,-4.23 -0.45,-0.05 -0.91,-0.1 -1.38,-0.13l0.04 0.01c0,0 -0.08,-0.01 -0.24,-0.03 -16.26,-1.1 -27.12,13.27 -40.54,20.15z"/>
                        </g>
                    </svg>
                </div>
            </div>

            <div className="row mb-4 g-0">
                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Top: </b>{Cores[cort].tecido}</div><PaletaCores paleta="Paleta08" selecionada={cort} clique={ setCort }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor1].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor1} clique={ setCor1 }/></div>
                        <div><div><b>Design 02: </b>{Cores[cor2].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor2} clique={ setCor2 }/></div>
                        <div><div><b>Design 03: </b>{Cores[cor3].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor3} clique={ setCor3 }/></div>
                        <div><div><b>Design 04: </b>{Cores[cor4].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor4} clique={ setCor4 }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logot} clique={setLogot}/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Bottom: </b>{Cores[corb].tecido}</div><PaletaCores paleta="Paleta08" selecionada={corb} clique={ setCorb }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logob} clique={setLogob}/>
                        </div>
                    </div>
                </div>

                <div className="mt-3 d-flex justify-content-center">
                    <button className="btn btn-lg btn-success d-block d-sm-none w-100" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                    <button className="btn btn-lg btn-success d-none d-sm-block w-50" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                </div>

            </div>

        </ColunaCentral>
        <Rodape/>

        <FormSolid parapente={parapente} tamanhos={tamanhos} show={showModal} fechar={setShowModal} imgtop={imgtop} imgbottom={imgbottom} corestop={coresT} coresbottom={coresB}/>

    </>
}

export default Runnexc;