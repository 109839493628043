import React, { useContext, useEffect, useState } from 'react';

import { LinguaContext } from '../Contextos/Linguas';
import { UnidadeContext } from '../Contextos/Unidades';
import Api from '../Api';
// import Banners from '../Componentes/Banners';
// import BannersColunas from '../Componentes/BannersColunas';
import BannersSwipers from '../Componentes/BannersSwipers';
import BarraSuperior from '../Componentes/BarraSuperior';
import Rodape from '../Componentes/Rodape';
import NewsHorizontal from '../Componentes/NewsHorizontal';
import VideosHorizontal from '../Componentes/VideosHorizontal';
import VideoWrapper from '../Componentes/VideoWrapper';
import T from '../Traducoes';


function HomeParagliders() {
    const { idioma } = useContext(LinguaContext);
    const { unidade } = useContext(UnidadeContext);
    const [ banners, setBanners ] = useState([]);
    const [ banners2, setBanners2 ] = useState([]);
    const [ video1, setVideo1 ] = useState([]);
    const [ video2, setVideo2 ] = useState([]);

    async function getBanners(banner) {
        const formData = new FormData();
        formData.append('op','listar');
        formData.append('banner',banner);
        const response = await Api.post('banners.php',formData);
        if (response.data.erro==='N') {
            setBanners(response.data.msg);
        } else {
            console.log('Erro ao consultar os banners');
        }
    }

    async function getBanners2(banner) {
        const formData = new FormData();
        formData.append('op','listar');
        formData.append('banner',banner);
        const response = await Api.post('banners.php',formData);
        if (response.data.erro==='N') {
            setBanners2(response.data.msg);
        } else {
            console.log('Erro ao consultar os banners');
        }
    }

    async function getVideo1() {
        const formData = new FormData();
        formData.append('op','videoini');
        formData.append('unidade',unidade);
        formData.append('idioma',idioma);
        formData.append('numero',1);
        const response = await Api.post('videos.php',formData);
        if (response.data.erro==='N') {
            setVideo1(response.data.msg);
        } else {
            console.log('Erro ao consultar os banners');
        }
    }

    async function getVideo2() {
        const formData = new FormData();
        formData.append('op','videoini');
        formData.append('unidade',unidade);
        formData.append('idioma',idioma);
        formData.append('numero','2');
        const response = await Api.post('videos.php',formData);
        if (response.data.erro==='N') {
            setVideo2(response.data.msg);
        } else {
            console.log('Erro ao consultar os banners');
        }
    }

    useEffect(() => {
        getBanners(15);
        getBanners2(18);
        getVideo1();
        getVideo2();
        // if (unidade==="1") getBanners(10);
        // if (unidade==="2") getBanners(20);
        // if (unidade==="3") getBanners(30);
        // if (unidade==="4") getBanners(40);
        // eslint-disable-next-line
    },[unidade])
    
    return <>
        <BarraSuperior />
        <div className="space-top"></div>
            {/* <Banners banners={banners}/> */}
        {/* <BannersColunas banners={banners} autoPlay={true} groupCells={false} classeCell=""/> */}
        <BannersSwipers classeName="" banners={banners} autoPlay={4000} slidesPerView={1} spaceBetween={0} loop={true} />

        <div className="container mt-5">
            {/* <BannersColunas banners={banners2} autoPlay={false} groupCells={2} classeCell="col-6 p-1"/> */}
            <BannersSwipers classeName="" banners={banners2} autoPlay={0} slidesPerView={2} spaceBetween={5} />
            <div className="mb-5"></div>
        </div>

        <div className="container-fluid">
            <VideoWrapper className="mb-5" video={video1.VID_LINK+'?controls=0&autoplay=1'} imagem={video1.VID_IMAGEMDESTAQUE} titulo={video1.VID_NOME} descricao={video1.VID_DESCRICAOCURTA}/>
        </div>

        <div className="container">
            <h3 className="text-center">{T(idioma,"Últimas Notícias")}</h3>
            <NewsHorizontal/>
            <h3 className="text-center">{T(idioma,"Últimos Vídeos")}</h3>
            <VideosHorizontal/>
        </div>

        <div className="container-fluid">
            <VideoWrapper className="mb-5" video={video2.VID_LINK+'?controls=0&autoplay=1'} imagem={video2.VID_IMAGEMDESTAQUE} titulo={video2.VID_NOME} descricao={video2.VID_DESCRICAOCURTA}/>
            {/* <VideoWrapper className="mb-5" video='joWGdF4vwPg?controls=0&autoplay=1' imagem="https://solparagliders.com.br/midias/videos/99/99.jpg" titulo="SOL 30 Anos" descrição="teste"/> */}
        </div>

        <Rodape/>
    </>
}

export default HomeParagliders;