
const Cores = {
    "#FFFFFF": { tecido: "WH 001",  pantone: "C WHITE", descricao: "Branco (Pantone C WHITE - Tecido WH001)" },   
    "#FBE111": { tecido: "YE W029", pantone: "C 102",   descricao: "Amarelo (Pantone C 102 - Tecido YE W029)" },
    "#F75439": { tecido: "OR W470", pantone: "C 021",   descricao: "Laranja (Pantone C 021 - Tecido OR W470)" },
    "#F00008": { tecido: "RE W001", pantone: "C 200",   descricao: "Vermelho (Pantone C200 - Tecido RE W001)" },
    "#094A39": { tecido: "GN W913", pantone: "C 328",   descricao: "Verde (Pantone C 328 - Tecido GN W913)" },
    "#1F4194": { tecido: "BL W320", pantone: "C 2728",  descricao: "Azul (Pantone C 2728 - Tecido BL W320)" },
    "#3C7196": { tecido: "BL W280", pantone: "C 7454",  descricao: "Azul Petroleo (Pantone C 7454 - Tecido BL W280)" },
    "#535A62": { tecido: "GR 0122", pantone: "C 5497",  descricao: "Cinza (Pantone C 5497 - Tecido GR 0122)" },
    "#191D24": { tecido: "BK W001", pantone: "C BLACK", descricao: "Preto (Pantone C BLACK - Tecido BK W001)" },
    "#AC211A": { tecido: "RE W022", pantone: "C 180",   descricao: "Rusty Red (Pantone C 180 - Tecido RE W022)" },
}

export default Cores;