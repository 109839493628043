import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import Api from '../Api';
import { AuthContext } from '../Contextos/Auth';
import { LinguaContext } from '../Contextos/Linguas';
import BarraSuperior from '../Componentes/BarraSuperior';
import ColunaCentral from '../Componentes/ColunaCentral';
import Rodape from '../Componentes/Rodape';
import IfComponent from '../Componentes/IfComponent';
import ProdutoCard from '../Componentes/ProdutoCard';

function ProdutosLista() {
    const { familia, grupo } = useParams();
    const { logout } = useContext(AuthContext);
    const { idioma } = useContext(LinguaContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    // const [ grupos, setGrupos ] = useState([]);
    const [ operacao, setOperacao ] = useState('inserir');

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
                        
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('idioma', idioma);
            formData.set('familia', familia);
            formData.set('grupo', grupo);
            Api.post('produtos.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar) {
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    // const getGrupos = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','grupos');
    //     Api.post('produtos.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setGrupos(response.data.msg);
    //         }
    //     });
    // },[]);

    // useEffect(() => {
    //     getGrupos();
    // },[getGrupos]);

    useEffect(() => {
        setOperacao('');

        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            if (operacao==="listar" || operacao==="") {
                listar(pagina,final,false); 
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    return <>
        <BarraSuperior />
        <ColunaCentral left="false" right="false" className="container space-top">

            {/* <div className="row">
                <div className="col-3">
                    <IfComponent condicional={ grupos.length > 0}>

                        <div className="accordion accordion-flush accordion-pett" id="grupos">                    
                            {                     
                                grupos.map(grupo => (
                                    <React.Fragment key={ grupo.FAM_CODIGO }>
                                        <IfComponent condicional={ grupo.FAM_DROP==="S" } >
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={'H'+grupo.FAM_CODIGO}>
                                                    <button className="accordion-button accordion-button-pett ps-3 py-3 pe-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#FAM'+grupo.FAM_CODIGO} aria-expanded="false" aria-controls={'FAM'+grupo.FAM_CODIGO}>{grupo.FAM_DESCRICAO}</button>
                                                </h2>
                                                <div id={'FAM'+grupo.FAM_CODIGO} className="accordion-collapse collapse" aria-labelledby={'H'+grupo.FAM_CODIGO} data-bs-parent="#grupos">
                                                    <div className="accordion-body p-0 ps-3">
                                                        <div className="accordion accordion-flush" id={'ac'+grupo.FAM_CODIGO}>
                                                            <FamiliaFilhos data={grupo.FILHOS} parent={'ac'+grupo.FAM_CODIGO}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </IfComponent>
                                        <IfComponent condicional={ grupo.FAM_DROP==="N" } >
                                            <div className="accordion-item accordion-item-pett">
                                                <div className="accordion-body ps-3 py-3 pe-2"><a href={'/produtos/'+grupo.FAM_CODIGO+'/'+grupo.GRU_CODIGO} className="d-block">{grupo.GRU_DESCRICAO}</a></div>
                                            </div>
                                        </IfComponent>
                                    </React.Fragment>
                                ))
                            }
                        </div>

                    </IfComponent>
                </div>
                <div className="col-9"> */}

                    <IfComponent condicional={ listagem.length > 0}>
                        <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4 mb-5">
                            {                     
                                listagem.map((produto,index) => (
                                    <ProdutoCard key={index} produto={produto} />
                                ))
                            }   
                        </div>
                    </IfComponent>

                {/* </div>
            </div>
 */}
    
            <IfComponent condicional={ carregando && !final }>
                <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
            </IfComponent>
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-cubes"></i></span><br/>Nenhum produto encontrado!</p>
                </div>                    
            </IfComponent>
            <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>

        </ColunaCentral>
        <Rodape />                
    </>
}

// function FamiliaFilhos(props) {
//     const { data, parent } = props;
//     // const location = window.location;

//     return <>
//         <IfComponent condicional={ data.length > 0 }>
//             {
//                 data.map(grupo => (
//                     <React.Fragment key={ grupo.GRU_CODIGO }>
//                         <IfComponent condicional={ grupo.FAM_DROP==="S" }>
//                             <div className="accordion-item">
//                                 <h2 className="accordion-header" id={'H'+grupo.FAM_CODIGO}>
//                                     <button className="accordion-button accordion-button-pett ps-3 py-3 pe-2 collapsed text-uppercase" type="button" data-bs-toggle="collapse" data-bs-target={'#FAM'+grupo.FAM_CODIGO} aria-expanded="false" aria-controls={'FAM'+grupo.FAM_CODIGO}>{grupo.FAM_DESCRICAO}</button>
//                                 </h2>
//                                 <div id={'FAM'+grupo.FAM_CODIGO} className="accordion-collapse collapse" aria-labelledby={'H'+grupo.FAM_CODIGO} data-bs-parent={'#'+parent}>
//                                     <div className="accordion-body p-0 ps-3">
//                                         <div className="accordion accordion-flush" id={'ac'+grupo.FAM_CODIGO}>
//                                             <FamiliaFilhos data={grupo.FILHOS} parent={'ac'+grupo.FAM_CODIGO}/>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </IfComponent>
//                         <IfComponent condicional={ grupo.FAM_DROP==="N" }>
//                             <div className="accordion-item accordion-item-pett">
//                                 <div className="accordion-body ps-3 py-3 pe-2"><a href={'/produtos/'+grupo.FAM_CODIGO+'/'+grupo.GRU_CODIGO} className="d-block">{grupo.GRU_DESCRICAO}</a></div>
//                             </div>
//                         </IfComponent>
//                     </React.Fragment>
//                 ))
//             }
//         </IfComponent>
//     </>
// }

export default ProdutosLista;