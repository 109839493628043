import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';

import Api from '../../Api';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Cabecalho from '../../Componentes/Cabecalho';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Rodape from '../../Componentes/Rodape';

function ResetSenha() {
    const [ email, setEmail ] = useState('');
    const [ msg, setMsg ] = useState('');
    const [ token, setToken ] = useState(false);
    const [ resetando, setResetando ] = useState(false);

    async function resetar() {
        setResetando(true);
        var formData = new FormData();
        formData.set('op','resetar');
        formData.set('email',email);
        const response = await Api.post('usuario/reset.php',formData);
        setResetando(false);
        if (response.data.erro==='N') {
            // Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            setEmail('');
            setMsg(response.data.msg);
        }
        if (response.data.erro==='S') {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    function verificarCaptcha(e) {
        setToken(true);
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <Cabecalho titulo="Resetar Senha" texto="Informe o email que vc utilizou quando fez o cadastro." />
            <p className="lead">{msg}</p>
            <div className="row mt-5 gx-2 p-inputtext-sm">
                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        <label htmlFor="email">EMAIL</label>
                    </span>
                </div>
                <div className="col-md-12 form-floating mb-5">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA} theme='dark' onChange={(e) => verificarCaptcha(e)}/>
                </div>
            </div>

            <div className="box shadow-sm mb-5">
                {   resetando
                        ? <button className="btn btn-success disabled" type="button" disabled><i className='pi pi-fw pi-spin pi-spinner'></i> Solicitando nova senha...</button>
                        : <button className={token?"btn btn-success":"btn btn-success disabled"} type="button" onClick={() => resetar()}>Solicitar nova senha</button>
                }                
            </div>

        </ColunaCentral>
        <Rodape/>
    </>
}

export default ResetSenha;