import React from 'react';

import Bloco from './Bloco';
import NewsletterRodape from './NewsletterRodape';

function Rodape() {
    return <>
        <div className="border-top border-dark bg-white">
        {/* <div className="secondary" style={{backgroundColor: '#d2d2d2'}}> */}
        {/* <div className="secondary bg-color2"> */}
            {/* <div className="container" style={{ paddingTop: '10px', paddingBottom: '10px', verticalAlign: 'middle', fontSize: '15px' }}> */}
            <div className="container" style={{ }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <Bloco className="container my-5" id="258"/>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <Bloco className="container" id="252"/>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <Bloco className="container" id="253"/>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <Bloco className="container" id="266"/>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <Bloco className="container" id="254"/>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <Bloco className="container" id="255"/>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <Bloco className="container mb-3" id="257"/>
                    </div>
                    {/* <div className="col-12 d-flex justify-content-between">
                        <Bloco className="container border" id="252"/>
                        <Bloco className="container border" id="253"/>
                        <Bloco className="container border" id="254"/>
                        <Bloco className="container border" id="255"/>
                        <Bloco className="container border" id="257"/>
                    </div> */}

                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <NewsletterRodape/>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12">
                        &nbsp;
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <Bloco className="container mt-3" id="259"/>
                    </div>
                    <div className="col-12 mb-4">
                        <Bloco className="container mt-3" id="261"/>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Rodape;