import React, { useContext, useEffect, useState } from 'react';

import { LinguaContext } from '../Contextos/Linguas';
import Api from '../Api';
import Cabecalho from './Cabecalho';
import IfComponent from './IfComponent';

function Bloco(props) {
    const { id, className } = props;
    const { idioma } = useContext(LinguaContext);
    const [ encontrado, setEncontrado ] = useState(true);
    const [ bloco, setBloco ] = useState({BLO_CODIGO: 0, BLO_CONTEUDO: '', BLO_MOSTRARTITULO: 'N', BLO_NOME: '', BLO_PAGINA: ''});

    async function verBloco() {
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', id);
        formData.set('idioma', idioma);
        try {
            const response = await Api.post('paginas.php',formData);
            if (response.data.erro==='N') {
                setBloco(response.data.msg);
                setEncontrado(true);
            } else {
                setEncontrado(false);
            }
        } catch (error) {
            console.log('CATCH',error);
        };            
    }

    useEffect(() => {
        verBloco();    
        // eslint-disable-next-line
    },[id]);

    return <>
        <IfComponent condicional={encontrado===false}>
            <div className="container space-top">
                <p>Conteúdo não encontrado!</p>
            </div>
        </IfComponent>

        <IfComponent condicional={encontrado===true}>

            <IfComponent condicional={bloco.BLO_IMAGEMDESTAQUE!==""}>
                <img className="img-fluid space-top" src={bloco.BLO_IMAGEMDESTAQUE} alt=""/>
                <div className={className}>
                    <IfComponent condicional={bloco.BLO_MOSTRARTITULO==="S"}>
                        <Cabecalho className="mb-3" titulo={bloco.BLO_NOME} texto=""/>
                    </IfComponent>
                    <section className="" dangerouslySetInnerHTML={{ __html: bloco.BLO_CONTEUDO }} />
                </div>
            </IfComponent>

            <IfComponent condicional={bloco.BLO_IMAGEMDESTAQUE===""}>
                <div className={className}>
                    <IfComponent condicional={bloco.BLO_MOSTRARTITULO==="S"}>
                        <Cabecalho className="mb-3" titulo={bloco.BLO_NOME} texto=""/>
                    </IfComponent>
                    <section className="" dangerouslySetInnerHTML={{ __html: bloco.BLO_CONTEUDO }} />
                </div>
            </IfComponent>

        </IfComponent>

    </>
}

export default Bloco;