import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { LinguaContext } from '../Contextos/Linguas';
import { UnidadeContext } from '../Contextos/Unidades';
import Api from '../Api';
import BarraSuperior from '../Componentes/BarraSuperior';
import ColunaCentral from '../Componentes/ColunaCentral';
import IfComponent from '../Componentes/IfComponent';
import DownloadCard from '../Componentes/DownloadCard';

import Rodape from '../Componentes/Rodape';
import DownloadsCategoriasTree from '../Componentes/DownloadsCategoriasTree';

function DownloadsList() {
    const { idioma } = useContext(LinguaContext);
    const { unidade } = useContext(UnidadeContext);
    const { slug } = useParams();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('cat', slug || '');
            formData.set('idioma',idioma);
            formData.set('unidade',unidade);
            formData.set('unidade','1');
            
            Api.post('/downloads.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 30) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {      
        listar(pagina,final,false); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
   

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">

            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 hidden-xs">
                    <DownloadsCategoriasTree/>
                </div> 
                {/* <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12"> */}
                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <IfComponent condicional={ listagem.length > 0}>

                        <div className="row">
                            {                     
                                listagem.map(down => (
                                    <DownloadCard download={down} key={down.DOW_CODIGO}/>
                                ))
                            }
                        </div>

                        <div className="col-12 text-center mb-5">
                            <IfComponent condicional={carregando && !final}>
                                <button className="btn btn-success" disabled><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</button>
                            </IfComponent>
                            <IfComponent condicional={!carregando && !final}>
                                <button className="btn btn-success" onClick={()=>listar(pagina,final,false)}>Carregar mais</button>
                            </IfComponent>
                            <IfComponent condicional={final}>
                                <p>...Sem mais registros...</p>
                            </IfComponent>
                        </div>
                    </IfComponent>
                </div>
            </div>


        </ColunaCentral>
        <Rodape/>
    </>
}

export default DownloadsList;