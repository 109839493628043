import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';

import { LinguaContext } from '../Contextos/Linguas';
import Api from '../Api';
import BarraSuperior from '../Componentes/BarraSuperior';
import Cabecalho from '../Componentes/Cabecalho';
import ColunaCentral from '../Componentes/ColunaCentral';
import IfComponent from '../Componentes/IfComponent';
import Rodape from '../Componentes/Rodape';
import T from '../Traducoes';

function EscolasParamotor() {
    const { idioma } = useContext(LinguaContext);
    const { id } = useParams();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ estado, setEstado ] = useState('');
    const [ estados, setEstados ] = useState([]);

    function listar() {
        setCarregando(true);    
        
        var formData = new FormData();
        formData.set('op', 'listar');
        formData.set('estado', estado);
        
        Api.post('/escolasparamotor.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setListagem(response.data.msg);
                setCarregando(false);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            setCarregando(false);
        });
    }

    const getEstados = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        formData.set('idioma',idioma);
        Api.post('/escolasparamotor.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setEstados(response.data.msg);
            }
        });
    },[idioma]);

    useEffect(() => {      
        // listar(pagina,final,false); 
        getEstados();
        if (id !== '') {
            setEstado(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    useEffect(() => {  
        if (estado>0) {
            setListagem([]);
            listar(); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[estado]);

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <Cabecalho titulo={T(idioma,'Escolas de Paramotor no Brasil')} texto=''/>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 form-floating p-inputtext-sm pett-input mb-1">
                    <span className="p-float-label pett-label">
                        <Dropdown className="w-100" name="estado" value={estado || ''} options={estados} onChange={(e) => setEstado(e.target.value)} placeholder=""/>
                        <label htmlFor="estado">{T(idioma,"SELECIONE O ESTADO")}</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                    {/* <Dropdown className="w-100" name="pais" value={pais || ''} options={paises} onChange={(e) => setCampo(e,'representante',false)} placeholder="Selecione"/> */}
                    <IfComponent condicional={ listagem.length > 0}>

                        <div className="row row-cols-1 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 mt-2 mb-5 g-3">
                            {                     
                                listagem.map(item => (  
                                    <div className="col" key={item.REP_CODIGO}>
                                        <div className="card h-100" key={item.REP_CODIGO}>
                                            <div className="row g-0 m-1">
                                                <div className="col-md-3 d-flex align-items-center justify-content-center">
                                                    <img src={item.LOGO} className="img-fluid rounded" alt={item.REP_CODIGO} />
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{item.REP_NOME}</h5>
                                                        <p className="card-text" style={{fontSize: '12px'}}>
                                                        {/* <p className="card-text"> */}
                                                            <i className="fa fa-fw fa-user"></i> {item.REP_RESPONSAVEL}<br/>
                                                            <i className="fa fa-fw fa-city"></i> <b>{item.CID_NOME}</b><br/>
                                                            <i className="fa fa-fw fa-map-pin"></i> {item.REP_ENDERECO}<br/>
                                                            <i className="fa fa-fw fa-whatsapp"></i> {item.REP_FONE1} | {item.REP_FONE2} | {item.REP_FONE3}<br/>
                                                            <i className="fa fa-fw fa-envelope"></i> <a href={'mailto:'+item.REP_EMAIL}>{item.REP_EMAIL}</a><br/>
                                                            <i className="fa fa-fw fa-home"></i> <a href={item.REP_SITE} target="_blank" rel="noreferrer">{item.REP_SITE}</a><br/>
                                                            <i className="fa fa-fw fa-facebook"></i> {item.REP_FACEBOOK}<br/>
                                                            <i className="fa fa-fw fa-instagram"></i> {item.REP_INSTAGRAM}<br/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="col-12 text-center mb-5">
                            <IfComponent condicional={carregando}>
                                <button className="btn btn-success" disabled><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando...</button>
                            </IfComponent>
                        </div>
                    </IfComponent>
                </div>
            </div>


        </ColunaCentral>
        <Rodape/>
    </>
}

export default EscolasParamotor;