import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Flickity from 'react-flickity-component'
//import "./styles.css";
//import "./flickity.css";
import "flickity/css/flickity.css";

import Api from '../Api';
import { LinguaContext } from '../Contextos/Linguas';
import BarraSuperior from '../Componentes/BarraSuperior';
import ColunaCentral from '../Componentes/ColunaCentral';
import IfComponent from '../Componentes/IfComponent';
import Rodape from '../Componentes/Rodape';
import T from '../Traducoes';

function Produto() {
    const { id } = useParams();
    const { idioma } = useContext(LinguaContext);
    const [ produto, setProduto ] = useState({PRO_CODIGO:0});
    const [ produtoImagens, setProdutoImagens ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);

    const verProduto = useCallback(() => {
        setCarregando(true);
        const formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', id);
        formData.set('idioma', idioma);
        Api.post('produtos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setProduto(response.data.msg);
                setProdutoImagens(response.data.msg.IMAGENS);
            } else {
                setProduto({PRO_CODIGO:0});
            }
            setCarregando(false);
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        });
    },[id,idioma]);

    useEffect(() => {      
        verProduto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const flickityOptions = {
        initialIndex: 0
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">

            <IfComponent condicional={produto.PRO_CODIGO!==0}>
                <div className="row">
                    <div className="col-md-3 col-sm-12 mt-2">

                        <IfComponent condicional={ produtoImagens.length > 0}>
                            <Flickity
                                className={'carousel'} // default ''
                                elementType={'div'} // default 'div'
                                options={flickityOptions} // takes flickity options {}
                                disableImagesLoaded={false} // default false
                                reloadOnUpdate // default false
                                static // default false
                            >
                                {
                                    produtoImagens.map((imagem,index) => (
                                        <img className="img-thumbnail img-fluid" src={process.env.REACT_APP_URL_SITE+imagem.pasta+imagem.arquivo} alt="" key={index} data-fancybox={"galery"+produto.PRO_CODIGO}/>
                                    ))
                                }
                            </Flickity>
                        </IfComponent>

                    </div>
                    <div className="col-md-9 col-sm-12">
                        <p className="news">{produto.PRO_NOME}</p>
                        <small>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-white py-1 px-2 rounded">
                                    <li className="breadcrumb-item"><a href="/produtos">Produtos</a></li>
                                    <li className="breadcrumb-item"><a href={"/produtos/"+produto.PRO_FAMILIA}>{produto.FAM_DESCRICAO}</a></li>
                                    <li className="breadcrumb-item"><a href={"/produtos/"+produto.PRO_FAMILIA+"/"+produto.PRO_GRUPO}>{produto.GRU_DESCRICAO}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{produto.PRO_NOME}</li>
                                </ol>
                            </nav>
                        </small>
                        <div className="mt-0 mb-2"><small>Ref. {produto.PRO_REFERENCIA}</small></div>
                        <IfComponent condicional={idioma==="BR"}>
                            <div className="my-4">
                                <Link className="btn btn-dark" to={produto.PRO_LINK} target="_blank">{T(idioma,"Comprar")}</Link>
                            </div>
                        </IfComponent>
                        {/* <IfComponent condicional={produto.PRO_MOSTRARPRECO==="S"}>
                            <IfComponent condicional={produto.PRO_PRECOTACHADO!==0}>

                            </IfComponent>
                        </IfComponent> */}

                        <section className="" dangerouslySetInnerHTML={{ __html: produto.PRO_DESCRICAO }} />

                    </div>
                </div>

            </IfComponent>
            <IfComponent condicional={carregando===true}>
                <div className="text-center fs-4 m-5"><i className="fa fa-fw fa-spinner fa-spin"></i></div>
            </IfComponent>
            <IfComponent condicional={produto.PRO_CODIGO===0 && carregando===false}>
                <div className="text-center fs-4 mt-5">Produto não localizado.</div>
            </IfComponent>       

        </ColunaCentral>
        <Rodape/>
    </>
}

export default Produto;