import React, { useContext, useEffect, useState } from 'react';

import { LinguaContext } from '../Contextos/Linguas';
import { UnidadeContext } from '../Contextos/Unidades';
import Api from '../Api';
import IfComponent from '../Componentes/IfComponent';
import T from '../Traducoes';

function VideosHorizontal() {
    const { idioma } = useContext(LinguaContext);
    const { unidade } = useContext(UnidadeContext);
    const [ listagem, setListagem ] = useState([]);

    async function listar() {          
        var formData = new FormData();
        formData.set('op', 'listar');
        formData.set('pagina',1);
        formData.set('idioma',idioma);
        formData.set('unidade',unidade);
            
        try {
            const response = await Api.post('/videos.php',formData);
            if (response.data.erro==='N') {
                if (response.data.contador > 0) {
                    setListagem(response.data.msg);
                } else {
                    console.log('ERRO VideosHorizontal',response.data.msg);
                    setListagem([]);
                    // Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }
        } catch (error) {
            console.log('CATCH VideosHorizontal',error);
        }
    }

    useEffect(() => {      
        listar(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
   
    return <>

        <div className="container-fluid mb-5">
            <div className="row d-flex flex-row flex-nowrap overflow-x-scroll px-0 py-3 element">
                <IfComponent condicional={ listagem.length > 0}>

                    {                     
                        listagem.map(video => (
                            <div className="card p-0 me-4 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 bg-transparent border-0" key={video.VID_CODIGO}>
                                <div className="pett-card-img">
                                    <img className="card-img-top rounded-0" src={video.VID_IMAGEMDESTAQUE} alt={video.VID_CODIGO} />
                                    <div className="pett-card-img-overlay">
                                        <a className="stretched-link" href={'/videos/'+video.VID_SLUG}><i className="fa fa-fw fa-2x fa-play-circle"></i></a>
                                    </div>
                                </div>
                                
                                <div className="card-body p-2">
                                    <p className="text-secondary m-0"><span className="badge bg-secondary bg-opacity-10 text-secondary">{video.VID_DATA}</span></p>
                                    <h5 className="card-title pb-0 mb-0"><a className="link-secondary" href={'/videos/'+video.VID_SLUG}>{video.VID_NOME}</a></h5>
                                    <span>{video.VID_DESCRICAOCURTA}</span>
                                </div>
                                <div className="card-footer p-0 bg-transparent text-end px-3 d-flex justify-content-between align-items-center">
                                    <a className="link-dark" href={'/videos/'+video.VID_SLUG}>{T(idioma,"Assistir...")}</a>
                                    {/* <a className="fs-5" href={'/news/'+video.VID_SLUG}><i className="fa fa-fw fa-play"></i></a> */}
                                </div>
                            </div>
                        ))
                    }

                    <div className="card p-0 shadow-sm me-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 bg-transparent">
                        <div className="card-body bg-transparent p-2 d-flex justify-content-center align-items-center">
                            <a className="btn btn-success" href="/videos/">{T(idioma,"Ver mais vídeos")}</a>
                        </div>
                    </div>
                </IfComponent>

                </div>
            </div>
    </>
}

export default VideosHorizontal;