import React, { useCallback, useContext, useState, useEffect } from 'react';

import { UnidadeContext } from '../Contextos/Unidades';
import Api from '../Api';
import IfComponent from './IfComponent';
import TreeView from './TreeView';

function DownloadsCategoriasTree() {
    const { unidade } = useContext(UnidadeContext);
    const [ categorias, setCategorias ] = useState([]);

    const getCategorias = useCallback(() => {
        const formData = new FormData();
        formData.set('op','listar');
        formData.set('unidade',unidade);
        Api.post('downloadscat.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setCategorias(response.data.msg);
            }
        });
    },[unidade]);

    useEffect(() => {
        getCategorias();
    },[getCategorias]);
 
    function selecionarCategoria(event,id,descricao,slug) {
        event.preventDefault();
        const link = document.createElement('a');
        // link.download = response.data.msg;
        link.href = process.env.REACT_APP_URL_SITE+'downloads/cat/'+slug;
        link.click();
    }

    return <>
        <ul className="navbar-nav menu-font">
            <div className="accordion accordion-flush accordion-pett" id="menucategorias">
                <IfComponent condicional={ categorias.length > 0 }>
                    <TreeView data={categorias} id="DWC_CODIGO" descricao="DWC_DESCRICAO" filhos="DWC_FILHOS" slug="DWC_SLUG" fechar={selecionarCategoria}/>
                </IfComponent>
            </div>
        </ul> 
    </>
}

// function Filhos(props) {
//     const { data } = props;
//     const location = window.location;
//     return <>
//         <IfComponent condicional={ data.length > 0 }>
//             {
//                 data.map(menu => (
//                     <React.Fragment key={ menu.MEN_CODIGO }>
//                         <IfComponent condicional={ menu.MEN_DROP==="S" }>
//                             <li className="nav-item dropdown">
//                                 <a className="dropdown-item dropdown-toggle" href={ menu.MEN_LINK } id={ 'MED'+menu.MEN_CODIGO } role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ menu.MEN_DESCRICAO }</a>
//                                 <ul className="dropdown-menu" aria-labelledby={ 'MED'+menu.MEN_CODIGO }>
//                                     <MenuFilhos data={ menu.MEN_FILHOS }/>
//                                 </ul>                                    
//                             </li>
//                         </IfComponent>
//                         <IfComponent condicional={ menu.MEN_DROP==="N" }>
//                             <li>
//                                 {/* <Link className={ location.pathname===menu.MEN_LINK?"dropdown-item active":"dropdown-item" } to={ menu.MEN_LINK } target={ menu.MEN_TARGET }>{ menu.MEN_DESCRICAO }</Link> */}
//                                 <a className={ location.pathname===menu.MEN_LINK?"dropdown-item active":"dropdown-item" } href={ menu.MEN_LINK } target={ menu.MEN_TARGET }>{ menu.MEN_DESCRICAO }</a>
//                             </li>
//                         </IfComponent>
//                     </React.Fragment>
//                 ))
//             }
//         </IfComponent>
//     </>
// }
   
export default DownloadsCategoriasTree;