import React, { useContext, useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

import { LinguaContext } from '../../Contextos/Linguas';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Bloco from '../../Componentes/Bloco';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Cores from './Cores.js';
import FormSolid from '../../Componentes/FormSolid';
import PaletaAdesivos from '../../Componentes/PaletaAdesivos';
import PaletaCores from '../../Componentes/PaletaCores';
import Rodape from '../../Componentes/Rodape';
import T from '../../Traducoes';

function Austergt2() {
    const { idioma } = useContext(LinguaContext);
    const svg_top = useRef(null);
    const svg_bottom = useRef(null);
    const parapente = 'Auster GT 2';
    const [ imgtop, setImgtop ] = useState('');
    const [ imgbottom, setImgbottom ] = useState('');
    const [ coresT, setCoresT ] = useState('');
    const [ coresB, setCoresB ] = useState('');
    const [ cort, setCort ] = useState('#FFFFFF');
    const [ corb, setCorb ] = useState('#FFFFFF');
    const [ cor1, setCor1 ] = useState('#FFFFFF');
    const [ cor2, setCor2 ] = useState('#FFFFFF');
    const [ cor3, setCor3 ] = useState('#FFFFFF');
    const [ logot, setLogot ] = useState('transparent');
    const [ logob, setLogob ] = useState('transparent');
    const [ showModal, setShowModal ] = useState(false);
    const tamanhos = [
        {value: "XXS", label: "XXS" },
        {value: "XS", label: "XS" },
        {value: "S", label: "S" },
        {value: "M", label: "M" },
        {value: "L", label: "L" },
        {value: "XL", label: "XL" },
        // {value: "XXL", label: "XXL" },
    ];
    
    function padrao1() {
        setCort('#FBE111');
        setCorb('#FBE111');
        setCor1('#094A39');
        setCor2('#FFFFFF');
        setCor3('#FFFFFF');
    }
        
    function padrao2() {
        setCort('#535A62');
        setCorb('#535A62');
        setCor1('#F00008');
        setCor2('#FFFFFF');
        setCor3('#FFFFFF');
    }

    function padrao3() {
        setCort('#F00008');
        setCorb('#F00008');
        setCor1('#094A39');
        setCor2('#FFFFFF');
        setCor3('#FFFFFF');
    }

    function padrao4() {
        setCort('#191D24');
        setCorb('#191D24');
        setCor1('#F75439');
        setCor2('#FFFFFF');
        setCor3('#FFFFFF');
    }

    useEffect(() => {
        padrao1();
    },[]);

    function gerarImagem() {
        htmlToImage.toPng(svg_top.current)
        .then(function (dataURL) {
            setImgtop(dataURL);
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
        
        htmlToImage.toPng(svg_bottom.current)
        .then(function (dataURL) {
            setImgbottom(dataURL)
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
    }

    function abrirModalEnviar() {
        gerarImagem();
        let lt="";
        if (logot==="transparent") lt="NENHUM / NO LOGO";
        if (logot==="#000000") lt="Preto / Black";
        if (logot==="#FF0000") lt="Vermelho / Red";

        let lb="";
        if (logob==="transparent") lb="NENHUM / NO LOGO";
        if (logob==="#000000") lb="Preto / Black";
        if (logob==="#FF0000") lb="Vermelho / Red";

        const novasCoresTop = 
        'Top: '+Cores[cort].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Design 03: '+Cores[cor3].descricao+'\n'+
        'Logo: '+lt+'\n\r';

        const novasCoresBottom = 
        'Bottom: '+Cores[corb].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Logo: '+lb+'\n';

        setCoresT(novasCoresTop);
        setCoresB(novasCoresBottom);

        setShowModal(true);
    }

    return <>
        <BarraSuperior/>
        <Bloco className="container" id="220"/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <div className="row mb-4">

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="w-100 d-flex justify-content-right mb-3 overflow-auto">
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/austergt2_padrao1.jpg" onClick={() => padrao1()} alt="1" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/austergt2_padrao2.jpg" onClick={() => padrao2()} alt="2" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/austergt2_padrao3.jpg" onClick={() => padrao3()} alt="3" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/austergt2_padrao4.jpg" onClick={() => padrao4()} alt="4" style={{maxWidth: '70px'}}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 421.08 234.97" ref={svg_top}>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={cort}>
                            <path d="M419.79 75.91c-0.57,1.24 -1.59,2.22 -2.83,2.79 -21.11,9.63 -235.4,107.19 -349.29,148.75 0,0 -38.3,16.05 -54.97,0.53 0,0 -5.15,-5.3 -11.54,-23.01 -1.35,-3.72 -1.21,-7.81 0.38,-11.43 9.87,-22.41 53.75,-106.4 194.23,-168.51 4.49,-1.99 51.49,-20.14 91.45,-23.57 50.87,-4.36 71.76,3.33 87.74,17.11 14.43,12.45 40.23,33.48 45.2,48.34 0.98,2.92 0.94,6.11 -0.33,8.91 -0.01,0.03 -0.03,0.06 -0.04,0.09l0 0z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                            <path d="M138.94 199.71c-25.93,10.54 -50.31,20.09 -71.27,27.74 0,0 -38.3,16.05 -54.97,0.53 0,0 -5.15,-5.3 -11.54,-23.01 -1.07,-2.95 -1.21,-6.13 -0.43,-9.12 9.23,18.06 18.19,45.14 90.81,11.05 1.97,-0.82 15.65,-12.6 28.7,-31.62l0.34 -0.51c15.76,-22.96 52.03,-61.94 118.96,-67.01 0,0 -84.32,24.61 -100.6,91.95l0 0z"/>
                            <path d="M414.46 56.73c-22.2,-4.08 -62.06,-12.87 -99.15,6.54l0.1 -0.07c0.04,-0.04 0.07,-0.1 0.11,-0.14 -7.97,5.21 -15.67,12.24 -22.84,21.61 0,0 48.57,-41.39 125.37,-6.62 0.74,-0.56 1.35,-1.29 1.74,-2.14 0.01,-0.03 0.03,-0.06 0.04,-0.09 1.27,-2.8 1.31,-5.99 0.33,-8.91 -1.09,-3.23 -2.86,-6.26 -5.7,-10.18l0 0z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M198.24 115.99c0.39,-0.13 0.77,-0.27 1.16,-0.39 -21.34,3.34 -51.15,15.28 -66.58,30.17 -22.6,21.84 -86.29,104.46 -129.96,44.85 -0.81,1.72 -1.62,3.38 -2.13,5.23 9.23,18.06 18.19,45.14 90.81,11.05 1.97,-0.82 15.65,-12.6 28.7,-31.62l0.34 -0.51c12.15,-17.7 36.49,-44.91 77.66,-58.78l0 0z"/>
                            <path d="M404.26 44.99c3.87,3.95 7.39,7.92 10.2,11.74 -22.2,-4.08 -62.06,-12.87 -99.15,6.54l0.1 -0.07c4.39,-5.59 34.68,-21.4 73.26,-19.83 5.11,0.21 10.33,0.8 15.59,1.62l0 0z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor3}>
                            <path d="M267.63 88.44c-0.64,0.24 -14.6,7.99 -29.73,9.01 -14.91,1.01 -38.37,6.06 -51.03,15.85 0,0 26.43,-9.02 46.04,-9.87 15.64,-0.67 30.18,-5.77 37.37,-9.38l0.09 -0.04c7.97,-4.09 13.03,-8.17 23.46,-19.77 12.72,-14.14 25.17,-20.21 25.17,-20.21 -15.65,3.41 -23.54,10.64 -33.89,21.4 -10.85,11.3 -17.45,13.01 -17.45,13.01l-0.03 0z"/>
                        </g>
                        <g fill={logot}>
                            <path d="M258.75 64.63c4.18,-4.15 9.15,-7.11 11.43,-2.88 2.44,4.55 -2.17,14.3 -14.94,18.94 -2.64,0.96 -6.05,1.81 -8.46,2.05 -0.09,0.01 -0.12,0.01 -0.12,-0.01 0,-0.01 0.04,-0.03 0.11,-0.04 2.63,-0.8 5.09,-1.65 7.51,-2.84 7.81,-3.87 12.35,-9.58 11.16,-11.6 -0.89,-1.54 -4.55,-0.36 -8.47,2.6 -2.36,1.78 -4.56,4.06 -7.02,6.56 -6,6.07 -15.14,10.39 -23.5,5.92 -4.07,-2.18 -3.77,-4.81 0.4,-4.97 3.19,-0.12 6.36,1.53 9.2,2.76 1.7,0.73 3.17,1.24 5.12,1.42 0.07,0.01 0.09,0.01 0.1,0.03 -0.01,0.01 -0.06,0.02 -0.17,0.03 -2.42,0.16 -4.7,-0.48 -6.48,-0.99 -0.89,-0.26 -1.77,-0.35 -2.71,-0.3 -2.68,0.14 -3.22,1.3 -0.92,2.19 1.55,0.6 4,1.01 7.08,0.44 0.27,-0.05 0.54,-0.11 0.82,-0.18l-0.02 0c0,0 0.04,-0.01 0.14,-0.03 9.6,-2.35 13.24,-12.65 19.74,-19.1l0 0z"/>
                        </g>
                        <g stroke="#000000" strokeWidth="0.3" fill="transparent">
                            <path d="M256.72 150.05c-0.19,-3.98 -0.54,-10.08 -1.38,-13.89 -1.94,-8.75 -7.02,-16.68 -12.17,-24.33 -7.36,-10.89 -14.88,-21.22 -22.51,-31.56 -12.15,-16.45 -24.71,-33.11 -37.14,-49.58"/>
                            <path d="M270.9 143.88c-0.55,-6.31 -1.27,-14.34 -3.28,-20.12 -2.51,-7.23 -7.31,-13.63 -12,-20 -14.68,-19.97 -28.35,-39.7 -43.2,-58.86 -5.29,-6.84 -10.51,-13.34 -16,-20.09"/>
                            <path d="M283.71 138.29c0.17,-2.73 0.36,-7.55 0.09,-10.21 -0.72,-6.97 -4.09,-13.55 -7.73,-19.81 -7.63,-13.09 -16.52,-24.75 -25.49,-36.45 -13.63,-17.79 -26.35,-34.32 -40.15,-52.22"/>
                            <path d="M297.26 132.34c-0.12,-4.72 -0.35,-11.33 -1.38,-15.8 -1.94,-8.38 -6.85,-15.97 -11.88,-23.32 -11.54,-16.88 -23.68,-32.47 -36.22,-47.96 -8.69,-10.75 -15.7,-19.19 -24.62,-29.88"/>
                            <path d="M308.1 127.56c0.58,-1.34 1.92,-4.76 2.17,-6.14 0.74,-4.16 -0.76,-8.58 -2.49,-12.75 -3.51,-8.48 -7.97,-15.9 -12.79,-23.15 -12.94,-19.49 -28.53,-37.77 -43.76,-56.05 -5.78,-6.93 -9.2,-11.07 -14.91,-18.01"/>
                            <path d="M242.98 156c-1.03,-5.56 -2.44,-13.07 -4.32,-18.32 -2.78,-7.78 -7.4,-14.86 -12.09,-21.86 -18.02,-26.97 -37.11,-53.12 -55.99,-78.85"/>
                            <path d="M32.55 234.72c-2.86,-2.15 -7.83,-6.01 -9.96,-8.72 -2.52,-3.21 -4.12,-7.15 -5.63,-11.07 -2.79,-7.25 -5.28,-14.42 -7.85,-21.62 -1.3,-3.64 -1.81,-5.08 -3.11,-8.73"/>
                            <path d="M40.79 234.36c-2.77,-2 -8.08,-5.47 -9.95,-7.46 -1.8,-1.92 -3.07,-4.33 -4.28,-6.75 -3.01,-6.02 -5.62,-12.06 -7.99,-18.22 -3.36,-8.74 -5.59,-15.75 -8.62,-24.66"/>
                            <path d="M47.57 233.35c-2.89,-2.65 -8.25,-7.28 -10.65,-10.32 -3.59,-4.56 -5.66,-10.22 -7.85,-15.74 -3.64,-9.19 -7.63,-17.99 -11.03,-27.11 -1.47,-3.94 -2.31,-6.37 -3.66,-10.38"/>
                            <path d="M58.08 230.8c-3.87,-3.28 -9.83,-8.35 -12.91,-12.24 -4.73,-5.97 -7.59,-13.38 -10.4,-20.67 -3.86,-10 -7.62,-19.8 -11.34,-29.69 -1.11,-2.93 -1.83,-4.85 -2.93,-7.8"/>
                            <path d="M68.06 227.36c-4.21,-3.76 -9.97,-8.93 -13.32,-13.36 -3.75,-4.93 -6.42,-10.67 -9,-16.42 -6.92,-15.47 -12.85,-30.47 -18.91,-46.05"/>
                            <path d="M80.02 222.93c-3.34,-2.71 -8.06,-6.57 -10.83,-9.56 -4.77,-5.13 -8.26,-11.41 -11.44,-17.76 -4.31,-8.6 -8.04,-17.33 -11.74,-26.1 -3.89,-9.24 -7.72,-18.47 -11.56,-27.77"/>
                            <path d="M92.1 218.34c-3.43,-2.76 -8.45,-7.04 -11.29,-10.33 -2.58,-3.01 -4.7,-6.43 -6.67,-9.93 -4.51,-7.98 -8.23,-16.29 -12.05,-24.56 -6.31,-13.63 -13.1,-27.65 -19.72,-41.15"/>
                            <path d="M104.42 213.56c-3.54,-3 -8.92,-7.68 -11.74,-11.24 -3.45,-4.35 -5.88,-9.5 -8.45,-14.54 -4.55,-8.98 -9.51,-17.59 -14.14,-26.4 -6.46,-12.31 -12.85,-26.21 -18.6,-38.95"/>
                            <path d="M116.75 208.69c-2.24,-2.52 -5.99,-6.92 -8.09,-9.56 -5.03,-6.3 -9.33,-13.2 -13.34,-20.22 -7.4,-12.94 -13.88,-26.32 -20.32,-39.72 -4.03,-8.4 -9.06,-18.87 -13.07,-27.27"/>
                            <path d="M130.41 203.22c-3.33,-4.56 -8.1,-10.95 -11.2,-15.65 -6.95,-10.52 -12.87,-21.7 -18.94,-32.77 -6.46,-11.77 -13.1,-23.43 -19.65,-35.14 -2.6,-4.63 -6.58,-11.84 -9.17,-16.49"/>
                            <path d="M143.72 197.82c-3.16,-4.75 -7.65,-11.24 -10.69,-16.08 -6.92,-11.02 -13.2,-22.45 -19.56,-33.82 -8.58,-15.33 -17.29,-30.57 -26.09,-45.77 -1.07,-1.86 -3.81,-6.61 -4.89,-8.47"/>
                            <path d="M157.98 191.97c-2.05,-3.67 -5.13,-9.04 -7.22,-12.69 -8.42,-14.74 -17.34,-29.18 -26.16,-43.69 -9.75,-16.05 -21.04,-34.92 -30.66,-51.05"/>
                            <path d="M171.73 186.26c-1.99,-4.29 -4.79,-10.32 -6.93,-14.53 -6.38,-12.54 -14.02,-24.42 -21.56,-36.34 -12.08,-19.13 -25.27,-40.63 -37.06,-59.9"/>
                            <path d="M185.5 180.5c-1.34,-3.93 -3.31,-9.66 -4.91,-13.46 -5.05,-12 -12.57,-22.85 -19.86,-33.78 -14.27,-21.42 -28.58,-44.68 -41.92,-66.44"/>
                            <path d="M199.78 174.48c-1.84,-5.83 -4.27,-13.5 -6.75,-19.02 -4.25,-9.44 -10.34,-18 -16.12,-26.69 -7.91,-11.87 -15.23,-24 -22.82,-35.98 -7.11,-11.23 -14.99,-23.12 -22.35,-34.22"/>
                            <path d="M214.16 168.37c-0.69,-3.33 -1.78,-8.64 -2.79,-11.87 -2,-6.34 -5.2,-12.29 -8.61,-18.06 -4.84,-8.18 -10.08,-16 -15.31,-23.86 -12.63,-19.01 -25.09,-38.22 -38.06,-57.05 -1.45,-2.11 -3.13,-4.62 -4.59,-6.72"/>
                            <path d="M228.88 162.07c-0.42,-2.96 -1.21,-7.92 -1.94,-10.79 -2.01,-8.04 -6.34,-15.39 -10.8,-22.56 -9.97,-16.04 -20.6,-31.19 -31.35,-46.36 -9.01,-12.71 -18.33,-25.78 -27.44,-38.52"/>
                            <path d="M344.81 111.28c0.57,-3.11 1.26,-6.94 1.11,-10.03 -0.17,-3.58 -1.27,-7.13 -2.63,-10.52 -2.84,-7 -6.87,-13.31 -11.19,-19.4 -8.25,-11.58 -17.59,-22.33 -26.88,-33.11 -10.68,-12.39 -19.63,-22.86 -30.23,-35.29"/>
                            <path d="M355.34 106.57c0.62,-2.36 1.31,-5.13 1.42,-7.51 0.18,-4.08 -0.99,-8.22 -2.51,-12.13 -2.12,-5.46 -4.93,-10.46 -8.06,-15.27 -5.72,-8.77 -12.52,-16.86 -19.31,-24.9 -8.41,-9.95 -16.8,-19.85 -25.5,-29.48 -5.26,-5.83 -9.22,-10.06 -14.63,-15.78"/>
                            <path d="M365.3 102.11c0.69,-2.95 1.29,-5.99 1.37,-8.99 0.08,-3.63 -0.65,-7.35 -1.98,-10.75 -2,-5.14 -5.35,-9.57 -8.71,-13.98 -9.03,-11.83 -18.1,-23.38 -27.71,-34.53 -10.13,-11.75 -19.75,-21.9 -30.54,-33.16"/>
                            <path d="M373.93 98.24c0.83,-3.13 1.63,-6.2 1.7,-9.35 0.1,-5.15 -1.81,-10.36 -4.32,-15.05 -2.28,-4.27 -5.06,-8.1 -7.93,-11.88 -6.86,-9.05 -14.22,-17.82 -21.74,-26.4 -10.78,-12.29 -21.22,-23.41 -32.37,-35.3"/>
                            <path d="M320.41 122.12c0.77,-1.94 2.12,-5.56 2.39,-7.55 0.66,-4.63 -0.94,-9.51 -2.91,-14.04 -3.78,-8.7 -8.92,-16.16 -14.3,-23.51 -11.65,-15.94 -24.43,-31.33 -37.24,-46.58 -7.11,-8.44 -11.83,-14.03 -18.94,-22.41"/>
                            <path d="M332.31 116.84c0.56,-1.24 1.62,-3.78 1.93,-5.08 0.79,-3.29 0.37,-6.86 -0.42,-10.22 -0.54,-2.25 -1.24,-4.42 -2.08,-6.53 -1.82,-4.6 -4.28,-8.97 -6.92,-13.21 -8.05,-12.9 -17.75,-24.57 -27.59,-36.09 -8.63,-10.12 -17.35,-20.12 -26.13,-30.1 -3.77,-4.29 -5.36,-6.1 -9.14,-10.38"/>
                            <path d="M381.56 94.81c1.35,-2.75 2.57,-5.32 2.88,-8.19 0.37,-3.35 -0.66,-6.88 -2.01,-10.16 -2.99,-7.28 -7.57,-13.38 -12.31,-19.37 -5.91,-7.47 -12.07,-14.77 -18.42,-21.9 -8.37,-9.4 -17.06,-18.5 -25.94,-27.42 -2.7,-2.71 -4.8,-4.8 -7.53,-7.49"/>
                            <path d="M390.46 90.79c0.53,-2.25 1.01,-4.4 1.26,-6.7 0.28,-2.63 0.2,-5.32 -0.36,-7.88 -0.65,-2.89 -1.93,-5.61 -3.37,-8.23 -2.72,-4.96 -5.99,-9.54 -9.51,-13.91 -5.04,-6.26 -10.59,-12.1 -15.97,-18.07 -5.39,-5.96 -10.61,-12.05 -16.1,-17.89 -5.78,-6.15 -11.22,-11.42 -17.35,-17.24"/>
                            <path d="M396.91 87.88c0.8,-3.68 1.58,-7.38 1.39,-11.06 -0.2,-3.93 -1.51,-7.86 -3.4,-11.38 -2.43,-4.55 -5.81,-8.4 -9.15,-12.28 -5.21,-6.05 -10.31,-12.14 -15.61,-18.06 -5.66,-6.33 -11.56,-12.46 -17.59,-18.44 -5.31,-5.27 -9.98,-9.71 -15.43,-14.82"/>
                            <path d="M405.03 84.2c0.27,-2.24 0.54,-4.82 0.49,-7.08 -0.05,-2.52 -0.42,-5.07 -1.18,-7.46 -1.52,-4.8 -4.59,-8.98 -7.8,-12.97 -5.87,-7.3 -12.17,-13.95 -18.52,-20.63 -5.79,-6.08 -11.62,-12.2 -17.66,-18.07 -5.52,-5.35 -10.26,-9.64 -15.98,-14.78"/>
                            <path d="M410.5 81.71c0.44,-2.23 0.89,-5.12 0.68,-7.32 -0.25,-2.64 -1.25,-5.23 -2.41,-7.7 -2.92,-6.22 -6.85,-11.72 -11.12,-16.93 -7.93,-9.68 -17.02,-18.39 -26.25,-26.9 -6.82,-6.28 -12.5,-11.37 -19.39,-17.53"/>
                            <path d="M415.24 79.55c0.16,-1.94 0.38,-5.07 0.23,-6.98 -0.61,-7.63 -5.57,-14.45 -10.76,-20.62 -8.85,-10.54 -18.37,-19.23 -28.12,-28.01 -6.36,-5.72 -11.49,-10.27 -17.95,-16.03"/>
                            <path d="M418.84 77.51c0,-0.51 -0.06,-4.41 -0.1,-4.92 -0.61,-7.63 -5.57,-14.44 -10.76,-20.61 -8.86,-10.54 -18.37,-19.24 -28.12,-28.02 -4.78,-4.29 -7.9,-7.12 -12.74,-11.44"/>
                            <path d="M25.17 233.91c-2.48,-1.77 -6.88,-4.63 -8.6,-7.04 -1.15,-1.61 -1.82,-3.58 -2.52,-5.52 -2.48,-6.83 -5.34,-13.25 -8.02,-19.85 -1.6,-3.92 -2.08,-4.86 -3.79,-9.14"/>
                        </g>
                    </svg>
                </div>          

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 801 177.47" ref={svg_bottom}>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={corb}>
                            <path d="M399.82 176.97c-13.88,0.16 -238.43,2.04 -376.48,-39.57l-18.93 -2.59c0,0 -6.34,-16.85 -2.97,-34.5 0,0 42.31,-99.86 399.06,-99.86l2.08 0c354.84,0.4 396.98,99.86 396.98,99.86 3.37,17.65 -2.97,34.5 -2.97,34.5l-18.93 2.59c-142.49,42.95 -377.16,39.56 -377.16,39.56l-0.68 0.01z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                            <path d="M166.26 20.07c20.27,7.99 35.8,17.72 35.8,17.72 75.91,47.1 156.6,22.86 156.6,22.86 -39.17,22.56 -115.26,19.09 -146.74,5.96 -31.49,-13.14 -47.35,-14.23 -47.35,-14.23 -120.56,-5 -153.4,54.48 -161.6,77.7 -1.65,-6.24 -3.82,-17.83 -1.53,-29.77 0,0 21.68,-51.17 164.82,-80.24z"/>
                            <path d="M634.74 20.07c-20.27,7.99 -35.8,17.72 -35.8,17.72 -75.91,47.1 -156.6,22.86 -156.6,22.86 39.17,22.56 115.26,19.09 146.74,5.96 31.49,-13.14 47.35,-14.23 47.35,-14.23 120.56,-5 153.4,54.48 161.6,77.7 1.65,-6.24 3.82,-17.83 1.53,-29.77 0,0 -21.68,-51.17 -164.82,-80.24z"/>
                        </g>
                        <g fill={logob}>
                            <path d="M372.58 101.2c-11.09,5.59 -22.98,8.27 -24.78,-1.69 -1.91,-10.69 13.67,-27.23 42.29,-28.18 5.91,-0.2 13.29,0.33 18.25,1.41 0.19,0.05 0.25,0.07 0.24,0.1 0,0.03 -0.09,0.03 -0.25,0.02 -5.79,-0.12 -11.26,-0.02 -16.87,0.79 -18.17,2.63 -30.98,11.12 -29.94,15.94 0.8,3.66 8.89,3.7 18.68,0.33 5.87,-2.03 11.77,-5.17 18.32,-8.55 15.97,-8.25 37.11,-10.94 50.92,3.47 6.72,7.02 4.41,12.08 -4.05,9.68 -6.46,-1.83 -11.72,-7.22 -16.62,-11.53 -2.91,-2.57 -5.54,-4.54 -9.32,-6.18 -0.13,-0.05 -0.18,-0.09 -0.17,-0.11 0.01,-0.03 0.13,-0.02 0.34,0.04 4.98,1.26 9.11,4.03 12.34,6.21 1.61,1.09 3.31,1.85 5.23,2.37 5.45,1.46 7.29,-0.51 3.27,-3.79 -2.71,-2.21 -7.35,-4.63 -13.88,-5.49 -0.58,-0.07 -1.17,-0.14 -1.77,-0.19l0.06 0.02c0,0 -0.1,-0.01 -0.31,-0.04 -20.76,-1.56 -34.77,16.7 -51.98,25.37z"/>
                        </g>
                    </svg>
                </div>
            </div>

            <div className="row mb-4 g-0">
                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Top: </b>{Cores[cort].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cort} clique={ setCort }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor1].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor1} clique={ setCor1 }/></div>
                        <div><div><b>Design 02: </b>{Cores[cor2].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor2} clique={ setCor2 }/></div>
                        <div><div><b>Design 03: </b>{Cores[cor3].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor3} clique={ setCor3 }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logot} clique={setLogot}/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Bottom: </b>{Cores[corb].tecido}</div><PaletaCores paleta="Paleta01" selecionada={corb} clique={ setCorb }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logob} clique={setLogob}/>
                        </div>
                    </div>
                </div>

                <div className="mt-3 d-flex justify-content-center">
                    <button className="btn btn-lg btn-success d-block d-sm-none w-100" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                    <button className="btn btn-lg btn-success d-none d-sm-block w-50" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                </div>

            </div>

        </ColunaCentral>
        <Rodape/>

        <FormSolid parapente={parapente} tamanhos={tamanhos} show={showModal} fechar={setShowModal} imgtop={imgtop} imgbottom={imgbottom} corestop={coresT} coresbottom={coresB}/>

    </>
}

export default Austergt2;