import React from 'react';

function ProdutoCard(props) {
    return <>
        <div className="col" key={props.produto.PRO_CODIGO}>
            <div className="card h-100 border-light-subtle text-center d-flex justify-content-center">
                <a href={'/produto/'+props.produto.PRO_SLUG}><img className="card-img-top" style={{maxWidth: '220px'}} src={props.produto.PRO_IMAGEM} alt={props.produto.PRO_CODIGO}/></a>
                <div className="card-body p-2 py-4">
                    <p className="text-secondary m-0"><span className="badge bg-secondary bg-opacity-10 text-secondary">{props.produto.PRO_REFERENCIA}</span></p>
                    <p className="card-title"><a className="link-secondary" href={'/produto/'+props.produto.PRO_SLUG}>{props.produto.PRO_NOME}</a></p>
                </div>
            </div>
        </div>
    </>
}

export default ProdutoCard;