import React, { useContext, useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

import { LinguaContext } from '../../Contextos/Linguas';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Bloco from '../../Componentes/Bloco';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Cores from './Cores.js';
import FormSolid from '../../Componentes/FormSolid';
import PaletaAdesivos from '../../Componentes/PaletaAdesivos';
import PaletaCores from '../../Componentes/PaletaCores';
import Rodape from '../../Componentes/Rodape';
import T from '../../Traducoes';

function Hercules2() {
    const { idioma } = useContext(LinguaContext);
    const svg_top = useRef(null);
    const svg_bottom = useRef(null);
    const parapente = 'Hercules 2';
    const [ imgtop, setImgtop ] = useState('');
    const [ imgbottom, setImgbottom ] = useState('');
    const [ coresT, setCoresT ] = useState('');
    const [ coresB, setCoresB ] = useState('');
    const [ cort, setCort ] = useState('#FFFFFF');
    const [ corb, setCorb ] = useState('#FFFFFF');
    const [ cor1, setCor1 ] = useState('#FFFFFF');
    const [ cor2, setCor2 ] = useState('#FFFFFF');
    const [ cor3, setCor3 ] = useState('#FFFFFF');
    const [ cor4, setCor4 ] = useState('#FFFFFF');
    const [ cor5, setCor5 ] = useState('#FFFFFF');
    const [ cor6, setCor6 ] = useState('#FFFFFF');
    const [ cor7, setCor7 ] = useState('#FFFFFF');
    const [ logot, setLogot ] = useState('transparent');
    const [ logob, setLogob ] = useState('transparent');
    const [ showModal, setShowModal ] = useState(false);
    const tamanhos = [
        {value: "300", label: "300" },
        {value: "380", label: "380" },
    ];
    
    function padrao1() {
        setCort('#F75439');
        setCorb('#F75439');
        setCor1('#F75439');
        setCor2('#191D24');
        setCor3('#FFFFFF');
        setCor4('#FFFFFF');
        setCor5('#F75439');    
        setCor6('#FFFFFF');    
        setCor7('#FFFFFF');    
    }
        
    function padrao2() {
        setCort('#1F4194');
        setCorb('#1F4194');
        setCor1('#1F4194');
        setCor2('#F00008');
        setCor3('#FFFFFF');
        setCor4('#FFFFFF');
        setCor5('#1F4194'); 
        setCor6('#FFFFFF'); 
        setCor7('#FFFFFF'); 
    }

    useEffect(() => {
        padrao1();
    },[]);

    function gerarImagem() {
        htmlToImage.toPng(svg_top.current)
        .then(function (dataURL) {
            setImgtop(dataURL);
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
        
        htmlToImage.toPng(svg_bottom.current)
        .then(function (dataURL) {
            setImgbottom(dataURL)
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
    }

    function abrirModalEnviar() {
        gerarImagem();
        let lt="";
        if (logot==="transparent") lt="NENHUM / NO LOGO";
        if (logot==="#000000") lt="Preto / Black";
        if (logot==="#FF0000") lt="Vermelho / Red";

        let lb="";
        if (logob==="transparent") lb="NENHUM / NO LOGO";
        if (logob==="#000000") lb="Preto / Black";
        if (logob==="#FF0000") lb="Vermelho / Red";

        const novasCoresTop = 
        'Top: '+Cores[cort].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Design 03: '+Cores[cor3].descricao+'\n'+
        'Design 04: '+Cores[cor4].descricao+'\n'+
        'Logo: '+lt+'\n\r';

        const novasCoresBottom = 
        'Bottom: '+Cores[corb].descricao+'\n'+
        'Design 01: '+Cores[cor5].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Design 03: '+Cores[cor6].descricao+'\n'+
        'Design 04: '+Cores[cor7].descricao+'\n'+
        'Logo: '+lb+'\n';

        setCoresT(novasCoresTop);
        setCoresB(novasCoresBottom);

        setShowModal(true);
    }

    return <>
        <BarraSuperior/>
        <Bloco className="container" id="202"/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <div className="row mb-4">

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="w-100 d-flex justify-content-right mb-3 overflow-auto">
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/hercules_two_padrao1.jpg" onClick={() => padrao1()} alt="1" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/hercules_two_padrao2.jpg" onClick={() => padrao2()} alt="2" style={{maxWidth: '70px'}}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 431.47 240.78" ref={svg_top}>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                            <path d="M397.61 94.08c-54.77,24.74 -173.87,77.94 -269.07,116.14l40.36 -42.3c1.86,-1.94 3.71,-3.9 5.47,-5.93 13.31,-15.32 29.12,-26.31 43.75,-34.08l0 0c79.15,-6.16 100.92,-39.3 100.92,-39.3l0 -0.03 0 0c6.26,-2.05 13.9,-2.87 24.89,-0.94 24.23,4.28 46.4,5.97 53.68,6.43l0 0.01z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M3.4 202.2l9.15 21.24c0,0 44.61,20.59 126.77,-44.64 0,0 35.82,-41.39 65.22,-54.25 29.4,-12.89 59.84,-13.78 59.84,-13.78 0,0 -54.08,9.89 -90.01,51.22 -1.76,2.03 -3.61,3.99 -5.47,5.93l-40.36 42.3c-0.21,0.1 -0.44,0.18 -0.63,0.26 -0.81,0.32 -1.59,0.63 -2.4,0.95 -0.43,0.18 -0.89,0.36 -1.34,0.55 -0.82,0.33 -1.64,0.64 -2.47,0.97 -1.23,0.5 -2.44,0.99 -3.65,1.46 -0.27,0.1 -0.5,0.2 -0.77,0.3 -0.96,0.38 -1.93,0.78 -2.89,1.16 -0.12,0.04 -0.25,0.09 -0.36,0.13 -15.98,6.33 -31.05,12.09 -44.69,17.06 0,0 -24.91,10.46 -43.68,6.65 -4.79,-0.96 -9.17,-2.86 -12.63,-6.09 0,0 -2.97,-3.07 -7.22,-12.24l-0.02 -0.01c-1.41,-3.07 -2.96,-6.81 -4.6,-11.33 -0.8,-2.21 -1.07,-4.53 -0.87,-6.83 0.02,-0.06 0.02,-0.13 0.02,-0.2 0.05,-0.31 0.08,-0.59 0.13,-0.9 0.01,-0.01 0,-0.04 0.01,-0.08 0.21,-1.27 0.57,-2.52 1.09,-3.71 0.1,-0.22 0.19,-0.45 0.31,-0.68l1.52 4.56 0 0z"/>
                            <path d="M293.56 100.63c2.9,-2.12 6.14,-6.78 10.6,-13.01 0.03,-0.05 0.08,-0.09 0.11,-0.12 1.88,-1.87 19.51,-17.83 61.82,-8.2 18.76,4.26 42.67,-2.4 64.13,-11.59 0.11,0.27 0.22,0.57 0.31,0.85 1,2.99 0.96,6.24 -0.33,9.12 -0.01,0.04 -0.03,0.07 -0.05,0.1 -0.58,1.27 -1.63,2.26 -2.9,2.85 -3.94,1.79 -14.41,6.57 -29.64,13.44 -7.28,-0.46 -29.45,-2.15 -53.68,-6.43 -27.26,-4.81 -33.88,7.38 -50.35,13.03l-0.02 -0.04 0 0z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor3}>
                            <path d="M366.05 79.29c-44.88,-10.2 -61.99,8.4 -61.99,8.4 0,0 12.18,-21.98 43.92,-17.55 31.73,4.44 62.17,0.15 75.39,-13.78 3.1,3.99 5.5,7.8 6.86,11.33 -21.47,9.21 -45.4,15.88 -64.18,11.6z"/>
                            <path d="M1.86 197.63c0.6,-1.37 1.48,-3.27 2.79,-5.87 0,0 12.28,12.54 42.22,14.95 29.96,2.43 65.73,-13.91 116.04,-61.43 0,0 10.19,-10.18 27.04,-19.27 17.42,-9.41 37.02,-14.03 56.81,-13.72l0.21 0.31c-11.43,1.76 -27.07,5.21 -42.43,11.95 -29.4,12.86 -65.22,54.25 -65.22,54.25 -82.18,65.22 -126.77,44.64 -126.77,44.64l-9.17 -21.25 -1.52 -4.56 0 0z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor4}>
                            <path d="M293.58 100.67c9.82,-3.37 16.15,-9.08 25.46,-12.09l0 0.03c0,0 -21.77,33.14 -100.92,39.3l-0.02 -0.01c24.85,-13.21 46.27,-17.11 46.27,-17.11 0,0 10.1,-1.76 29.21,-10.12z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cort}>
                            <path d="M304.39 87.15c-0.15,0.21 -0.25,0.38 -0.33,0.54l-0.06 0.15 -0.04 0.06 -0.07 0.1 -0.12 0.18 -0.02 0.01 -0.1 0.15 -0.1 0.14 -0.01 0.02 -0.11 0.16 -0.08 0.11 -0.04 0.07 -0.12 0.16 -0.05 0.03 -0.08 0.12 -0.11 0.16 -0.01 0.01 -0.1 0.15 -0.09 0.13 -0.02 0.03 -0.1 0.14 -0.08 0.11 -0.04 0.05 -0.11 0.16 -0.04 0.07 -0.07 0.09 -0.12 0.14 -0.02 0.03 -0.09 0.13 -0.09 0.13 -0.01 0.01 -0.11 0.16 -0.07 0.1 -0.04 0.05 -0.11 0.16 -0.03 0.05 -0.07 0.09 -0.1 0.15 -0.02 0.03 -0.11 0.12 -0.09 0.12 -0.01 0.02 -0.1 0.15 -0.07 0.09 -0.03 0.05 -0.1 0.14 -0.05 0.07 -0.06 0.08 -0.11 0.13 -0.01 0.02 -0.09 0.13 -0.09 0.12 -0.02 0.02 -0.1 0.15 -0.05 0.08 -0.04 0.04 -0.1 0.15 -0.06 0.05 -0.05 0.08 -0.09 0.13 -0.03 0.03 -0.08 0.11 -0.09 0.13 0 0 -0.11 0.14 -0.06 0.08 -0.03 0.04 -0.1 0.15 -0.04 0.05 -0.05 0.08 -0.11 0.11 -0.01 0.02 -0.08 0.11 -0.09 0.13 0 0 -0.12 0.14 -0.05 0.08 -0.04 0.04 -0.09 0.13 -0.03 0.05 -0.06 0.05 -0.09 0.13 -0.02 0.03 -0.07 0.1 -0.01 0.02 -0.1 0.1 -0.09 0.13 -0.05 0.08 -0.04 0.02 -0.09 0.13 -0.04 0.04 -0.05 0.08 -0.1 0.1 -0.01 0.02 -0.07 0.1 -0.09 0.1 -0.01 0.01 -0.08 0.12 -0.07 0.07 -0.04 0.04 -0.08 0.12 -0.09 0.1c-0.16,0.15 -0.31,0.33 -0.43,0.51 -1.21,1.45 -2.34,2.59 -3.43,3.39l0.02 0.04 0 0c-19.11,8.36 -29.21,10.12 -29.21,10.12l-0.07 0 0.08 -0.02c0,0 -6.95,0.21 -17.41,1.83l-0.21 -0.31c-19.79,-0.31 -39.39,4.31 -56.81,13.72 -16.85,9.09 -27.04,19.27 -27.04,19.27 -50.31,47.52 -86.08,63.86 -116.04,61.43 -29.95,-2.4 -42.22,-14.95 -42.22,-14.95 -0.27,0.53 -0.52,1.03 -0.76,1.5 13.6,-28.3 61.27,-107.73 196.7,-167.61 4.61,-2.04 52.76,-20.65 93.72,-24.15 52.13,-4.48 73.52,3.41 89.9,17.54 11.15,9.6 28.9,24.2 39.15,37.34 -13.22,13.93 -43.67,18.19 -75.38,13.76 -28.8,-4.02 -41.49,13.7 -43.59,17.01l0 0zm-9.46 13.03c-0.14,0.06 -0.3,0.12 -0.46,0.18 0.16,-0.06 0.32,-0.12 0.46,-0.18zm-0.64 0.24c-0.23,0.08 -0.45,0.16 -0.67,0.23 0.22,-0.07 0.44,-0.15 0.67,-0.23zm-292.52 97.48l0.11 -0.26 0 0 -0.11 0.26z"/>
                        </g>
                        <g stroke="#000000" strokeWidth="0.3" fill="transparent">
                            <path d="M24.6 239.47c-2.55,-1.82 -6.76,-4.52 -8.52,-7 -1.19,-1.64 -1.86,-3.67 -2.58,-5.64 -2.55,-7.02 -5.59,-13.55 -8.34,-20.31 -1.63,-4.03 -2.07,-4.67 -3.3,-8.86m22.74 41.81l0 0 0 0z"/>
                            <path d="M430.15 77.78c-0.58,1.27 -1.64,2.28 -2.9,2.85 -21.64,9.88 -241.22,109.84 -357.91,152.43 0,0 -39.25,16.45 -56.33,0.54 0,0 -5.27,-5.41 -11.82,-23.57 -1.38,-3.81 -1.26,-8.01 0.38,-11.71 10.12,-22.97 55.08,-109.04 199.02,-172.67 4.61,-2.04 52.77,-20.65 93.72,-24.15 52.14,-4.48 73.52,3.41 89.91,17.54 14.78,12.74 41.22,34.3 46.3,49.53 1.01,2.98 0.96,6.25 -0.33,9.12l-0.04 0.09 0 0z"/>
                            <path d="M32.51 240.51c-2.92,-2.19 -7.99,-6.12 -10.17,-8.92 -2.59,-3.27 -4.23,-7.31 -5.77,-11.33 -2.87,-7.45 -5.42,-14.78 -8.04,-22.17 -1.35,-3.72 -1.65,-4.52 -2.98,-8.26m26.96 50.68l0 0 0 0z"/>
                            <path d="M41.08 240.24c-2.84,-2.06 -8.38,-5.67 -10.29,-7.73 -1.85,-1.97 -3.16,-4.44 -4.4,-6.91 -3.07,-6.17 -5.75,-12.35 -8.17,-18.67 -3.45,-8.94 -5.49,-15.47 -8.59,-24.6m31.45 57.91l0 0 0 0z"/>
                            <path d="M48.02 239.21c-2.96,-2.71 -8.55,-7.54 -11,-10.67 -3.68,-4.65 -5.8,-10.47 -8.04,-16.13 -3.74,-9.42 -7.82,-18.42 -11.31,-27.75 -1.5,-4.05 -2.11,-5.84 -3.5,-9.94m33.85 64.49l0 0 0 0z"/>
                            <path d="M58.85 236.65c-3.96,-3.37 -10.22,-8.71 -13.37,-12.68 -4.86,-6.11 -7.79,-13.71 -10.68,-21.17 -3.95,-10.25 -7.8,-20.3 -11.6,-30.42 -1.15,-3.01 -1.66,-4.39 -2.78,-7.4m38.43 71.67l0 0 0 0z"/>
                            <path d="M69.15 233.19c-4.33,-3.87 -10.43,-9.36 -13.88,-13.88 -3.83,-5.05 -6.57,-10.94 -9.21,-16.83 -7.09,-15.85 -13,-30.78 -19.22,-46.74m42.31 77.45l0 0 0 0z"/>
                            <path d="M81.37 228.59c-3.43,-2.76 -8.45,-6.87 -11.28,-9.93 -4.88,-5.26 -8.47,-11.7 -11.74,-18.2 -4.39,-8.82 -8.24,-17.75 -12.02,-26.74 -3.99,-9.47 -7.7,-18.41 -11.63,-27.94m46.67 82.81l0 0 0 0z"/>
                            <path d="M93.77 223.92c-3.49,-2.83 -8.87,-7.38 -11.77,-10.75 -2.66,-3.08 -4.84,-6.6 -6.85,-10.18 -4.61,-8.18 -8.43,-16.7 -12.33,-25.17 -6.47,-13.96 -13.2,-27.86 -19.99,-41.7m50.94 87.8l0 0 0 0z"/>
                            <path d="M106.37 219.01c-3.61,-3.07 -9.32,-8.03 -12.22,-11.69 -3.53,-4.46 -6.03,-9.73 -8.66,-14.89 -4.66,-9.2 -9.73,-18.02 -14.48,-27.06 -6.63,-12.61 -12.97,-26.41 -18.84,-39.46m54.2 93.1l0 0 0 0z"/>
                            <path d="M119 214.07c-2.3,-2.59 -6.31,-7.31 -8.48,-10 -5.16,-6.46 -9.55,-13.53 -13.67,-20.72 -7.59,-13.26 -14.21,-26.97 -20.81,-40.71 -4.12,-8.6 -9.06,-18.87 -13.18,-27.48m56.14 98.91l0 0 0 0z"/>
                            <path d="M132.95 208.43c-3.41,-4.66 -8.43,-11.4 -11.61,-16.22 -7.12,-10.77 -13.2,-22.23 -19.4,-33.56 -6.62,-12.07 -13.43,-24.02 -20.14,-36.02 -2.66,-4.75 -6.54,-11.75 -9.2,-16.53m60.35 102.33l0 0 0 0z"/>
                            <path d="M146.58 202.9c-3.25,-4.86 -7.96,-11.69 -11.07,-16.65 -7.1,-11.31 -13.53,-23.01 -20.06,-34.67 -8.79,-15.7 -17.71,-31.32 -26.71,-46.89 -1.11,-1.9 -3.71,-6.43 -4.82,-8.33m62.66 106.54l0 0 0 0z"/>
                            <path d="M161.18 196.93c-2.11,-3.76 -5.37,-9.46 -7.51,-13.2 -8.64,-15.1 -17.77,-29.91 -26.81,-44.78 -9.98,-16.44 -21.35,-35.46 -31.2,-51.98m65.52 109.96l0 0 0 0z"/>
                            <path d="M175.26 191.09c-2.04,-4.39 -5.01,-10.78 -7.21,-15.09 -6.54,-12.87 -14.36,-25.04 -22.08,-37.24 -12.4,-19.62 -25.71,-41.33 -37.8,-61.07m67.09 113.4l0 0 0 0z"/>
                            <path d="M189.34 185.2c-1.37,-4.02 -3.46,-10.12 -5.11,-14.03 -5.18,-12.28 -12.88,-23.39 -20.34,-34.6 -14.64,-21.95 -29.12,-45.49 -42.79,-67.79m68.24 116.42l0 0 0 0z"/>
                            <path d="M203.97 179.04c-1.88,-5.96 -4.45,-14.08 -7,-19.74 -4.33,-9.66 -10.59,-18.42 -16.51,-27.33 -8.1,-12.18 -15.61,-24.59 -23.38,-36.87 -7.27,-11.51 -15.17,-23.43 -22.72,-34.79m69.61 118.73l0 0 0 0z"/>
                            <path d="M218.68 172.78c-0.71,-3.42 -1.88,-9.09 -2.91,-12.4 -2.04,-6.5 -5.33,-12.59 -8.82,-18.5 -4.95,-8.39 -10.34,-16.4 -15.69,-24.45 -12.93,-19.49 -25.71,-39.18 -39,-58.46 -1.48,-2.16 -3.01,-4.48 -4.52,-6.64m70.94 120.45l0 0 0 0z"/>
                            <path d="M233.75 166.34c-0.44,-3.03 -1.28,-8.36 -2.01,-11.31 -2.08,-8.25 -6.5,-15.77 -11.07,-23.11 -10.23,-16.45 -21.12,-31.97 -32.13,-47.5 -9.22,-13.04 -18.61,-26.19 -27.94,-39.24m73.15 121.16l0 0 0 0z"/>
                            <path d="M248.21 160.1c-1.06,-5.69 -2.54,-13.62 -4.47,-18.99 -2.85,-7.97 -7.59,-15.24 -12.38,-22.41 -18.47,-27.64 -37.86,-54.21 -57.22,-80.56m74.07 121.96l0 0 0 0z"/>
                            <path d="M262.27 154.18c-0.2,-4.09 -0.57,-10.74 -1.45,-14.65 -1.97,-8.97 -7.18,-17.09 -12.46,-24.92 -7.54,-11.17 -15.26,-21.74 -23.08,-32.34 -12.43,-16.86 -25.16,-33.74 -37.9,-50.61m74.89 122.52l0 0 0 0z"/>
                            <path d="M276.81 147.85c-0.56,-6.47 -1.34,-15.1 -3.41,-21.02 -2.57,-7.4 -7.47,-13.97 -12.28,-20.49 -15.03,-20.46 -29.06,-40.69 -44.27,-60.31 -5.43,-7.01 -10.61,-13.48 -16.23,-20.39m76.19 122.21l0 0 0 0z"/>
                            <path d="M289.87 142.16c0.18,-2.79 0.39,-8.17 0.12,-10.9 -0.74,-7.14 -4.18,-13.88 -7.92,-20.3 -7.81,-13.42 -16.94,-25.36 -26.13,-37.34 -13.95,-18.24 -26.91,-35.07 -41.05,-53.42m74.98 121.96l0 0 0 0z"/>
                            <path d="M303.78 135.9c-0.11,-4.84 -0.35,-11.88 -1.41,-16.47 -1.99,-8.57 -7.02,-16.36 -12.18,-23.89 -11.82,-17.3 -24.27,-33.27 -37.11,-49.14 -8.91,-11.03 -15.95,-19.52 -25.1,-30.48m75.8 119.98l0 0 0 0z"/>
                            <path d="M314.75 131.06c0.6,-1.37 2.1,-5.22 2.36,-6.62 0.77,-4.27 -0.78,-8.8 -2.54,-13.08 -3.6,-8.69 -8.17,-16.29 -13.11,-23.72 -13.27,-19.96 -29.24,-38.68 -44.84,-57.43 -5.92,-7.09 -9.31,-11.21 -15.17,-18.32m73.3 119.17l0 0 0 0z"/>
                            <path d="M327.36 125.49c0.8,-2 2.31,-6.04 2.59,-8.07 0.68,-4.76 -0.96,-9.74 -2.99,-14.39 -3.86,-8.9 -9.13,-16.56 -14.64,-24.1 -11.95,-16.31 -25.02,-32.09 -38.16,-47.73 -7.28,-8.64 -12.01,-14.25 -19.31,-22.83m72.51 117.12l0 0 0 0z"/>
                            <path d="M339.54 120.09c0.58,-1.27 1.81,-4.23 2.14,-5.56 0.8,-3.36 0.38,-7.03 -0.44,-10.47 -0.54,-2.3 -1.26,-4.52 -2.12,-6.68 -1.86,-4.72 -4.39,-9.2 -7.1,-13.54 -8.25,-13.24 -18.19,-25.17 -28.27,-36.99 -8.84,-10.36 -17.79,-20.62 -26.77,-30.83 -3.87,-4.41 -5.4,-6.15 -9.29,-10.55m71.85 114.62l0 0 0 0z"/>
                            <path d="M352.44 114.34c0.6,-3.17 1.37,-7.42 1.2,-10.57 -0.18,-3.67 -1.29,-7.32 -2.7,-10.79 -2.9,-7.18 -7.02,-13.63 -11.45,-19.87 -8.46,-11.88 -18.03,-22.88 -27.55,-33.93 -10.94,-12.69 -20.04,-23.34 -30.9,-36.09m71.4 111.25l0 0 0 0z"/>
                            <path d="M363.22 109.54c0.62,-2.43 1.42,-5.58 1.52,-8.02 0.2,-4.17 -1,-8.41 -2.57,-12.43 -2.16,-5.58 -5.04,-10.73 -8.25,-15.65 -5.86,-8.99 -12.83,-17.27 -19.79,-25.52 -8.61,-10.19 -17.21,-20.32 -26.13,-30.21 -5.4,-5.95 -9.4,-10.24 -14.94,-16.11m70.16 107.94l0 0 0 0z"/>
                            <path d="M373.44 104.95c0.69,-3.03 1.38,-6.45 1.46,-9.5 0.09,-3.75 -0.65,-7.54 -2.01,-11.03 -2.05,-5.27 -5.5,-9.81 -8.94,-14.33 -9.25,-12.12 -18.54,-23.96 -28.39,-35.38 -10.37,-12.05 -20.21,-22.4 -31.26,-33.95m69.14 104.19l0 0 0 0z"/>
                            <path d="M382.26 101c0.87,-3.22 1.75,-6.67 1.81,-9.9 0.11,-5.28 -1.84,-10.62 -4.39,-15.42 -2.35,-4.37 -5.19,-8.3 -8.13,-12.18 -7.05,-9.27 -14.59,-18.25 -22.3,-27.05 -11.03,-12.58 -21.73,-23.99 -33.16,-36.16m66.17 100.71l0 0 0 0z"/>
                            <path d="M389.99 97.52c1.39,-2.81 2.81,-5.79 3.13,-8.75 0.37,-3.42 -0.68,-7.05 -2.06,-10.41 -3.06,-7.46 -7.75,-13.71 -12.62,-19.84 -6.05,-7.66 -12.37,-15.15 -18.88,-22.46 -8.57,-9.61 -17.48,-18.94 -26.56,-28.07 -2.79,-2.79 -4.96,-4.95 -7.75,-7.7m64.74 97.23l0 0 0 0z"/>
                            <path d="M399.22 93.36c0.54,-2.31 1.09,-4.81 1.35,-7.19 0.29,-2.67 0.22,-5.45 -0.37,-8.06 -0.65,-2.97 -1.97,-5.76 -3.44,-8.44 -2.78,-5.08 -6.14,-9.78 -9.76,-14.25 -5.16,-6.41 -10.84,-12.4 -16.37,-18.52 -5.51,-6.11 -10.86,-12.34 -16.49,-18.34 -5.92,-6.3 -11.57,-11.77 -17.86,-17.72m62.94 92.52l0 0 0 0z"/>
                            <path d="M405.83 90.38c0.81,-3.77 1.68,-7.87 1.49,-11.64 -0.22,-4.03 -1.56,-8.05 -3.48,-11.67 -2.51,-4.65 -5.96,-8.6 -9.39,-12.59 -5.33,-6.19 -10.55,-12.43 -16,-18.49 -5.8,-6.48 -11.83,-12.77 -18.01,-18.91 -5.44,-5.39 -10.36,-10.08 -15.96,-15.31m61.35 88.61l0 0 0 0z"/>
                            <path d="M351.84 3.09c5.85,5.27 10.93,9.88 16.59,15.35 6.2,6.02 12.16,12.3 18.1,18.54 6.51,6.83 12.96,13.65 18.99,21.14 3.29,4.08 6.44,8.36 7.98,13.27 0.79,2.46 1.16,5.07 1.22,7.65 0.05,2.33 -0.25,5.25 -0.54,7.54m-62.34 -83.49l0 0 0 0z"/>
                            <path d="M359.37 5.04c7.08,6.32 13.4,11.96 20.37,18.4 9.46,8.71 18.78,17.65 26.91,27.56 4.37,5.35 8.4,10.97 11.39,17.35 1.2,2.53 2.21,5.18 2.47,7.9 0.22,2.24 -0.3,5.51 -0.75,7.8m-60.39 -79.01l0 0 0 0z"/>
                            <path d="M366.03 7.55c6.62,5.9 12.52,11.14 19.04,16.99 9.99,9.01 19.74,17.91 28.81,28.71 5.33,6.32 10.4,13.3 11.02,21.12 0.17,1.96 -0.09,5.47 -0.26,7.45m-58.61 -74.27l0 0 0 0z"/>
                            <path d="M373.9 11.53c4.96,4.43 9.63,8.63 14.51,13.03 9.99,9.01 19.75,17.92 28.82,28.72 5.32,6.31 10.41,13.29 11.03,21.12 0.05,0.51 0.1,5.05 0.12,5.57m-54.48 -68.44l0 0 0 0z"/>
                        </g>
                        <g fill={logot}>
                            <path d="M271.19 81.34c3.55,-3.49 7.75,-5.98 9.67,-2.38 2.03,3.85 -1.9,12.07 -12.7,15.94 -2.24,0.8 -5.13,1.5 -7.15,1.69 -0.08,0.02 -0.1,0.02 -0.1,0 -0.01,-0.01 0.03,-0.02 0.1,-0.04 2.22,-0.66 4.3,-1.36 6.34,-2.38 6.62,-3.22 10.47,-8.03 9.49,-9.75 -0.77,-1.3 -3.85,-0.32 -7.18,2.17 -2,1.5 -3.88,3.41 -5.96,5.51 -5.08,5.11 -12.83,8.71 -19.86,4.9 -3.44,-1.85 -3.17,-4.07 0.35,-4.18 2.7,-0.09 5.37,1.31 7.77,2.36 1.41,0.62 2.66,1.06 4.32,1.22 0.05,0.01 0.07,0.02 0.07,0.02 0,0.02 -0.04,0.03 -0.13,0.03 -2.06,0.13 -3.98,-0.42 -5.49,-0.87 -0.73,-0.21 -1.48,-0.29 -2.28,-0.26 -2.26,0.12 -2.73,1.09 -0.78,1.86 1.3,0.51 3.38,0.86 5.98,0.4 0.22,-0.06 0.45,-0.1 0.69,-0.16l-0.02 0.02c0,0 0.04,-0.01 0.12,-0.03 8.11,-1.95 11.24,-10.65 16.75,-16.07l0 0z"/>
                        </g>
                    </svg>
                </div>          

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 1208.68 275.23" ref={svg_bottom}>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor5}>
                            <path d="M1017.37 39.65c-89.2,-22.22 -220.29,-38.97 -413.02,-38.97l-0.01 0 0 0c-192.74,0 -323.83,16.75 -413.02,38.97 18.24,5.92 41.41,13.74 70.56,24.01 72.35,25.5 108.03,31.47 146.61,28.63l0 -0.03c0,0 101.07,-40.73 196.03,-40.73 0.3,0 0.6,0.01 0.9,0.01 0.3,0 0.6,-0.01 0.9,-0.01 94.95,0 195.84,40.88 195.84,40.88l0 0.01c37.85,2.5 73.52,-3.69 144.64,-28.76 29.15,-10.27 52.33,-18.09 70.57,-24.01z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M1206.99 147.82c3.87,8.94 -4.57,67.05 -4.57,67.05l-3.21 0.15 1.35 -52.6c0,0 -102.1,-119.78 -261.4,-59.07 0,0 -89.85,32.55 -208,-3.4l-0.21 -0.08c-0.03,-0.02 -0.07,-0.02 -0.11,-0.04 -6.27,-2.84 -12.71,-5.87 -19.38,-9.15 0,0 -18.82,-11.22 -64.86,-15.94l0 -0.02c0,0 34.31,-1.38 101.66,10.32 67.36,11.7 96.39,14.63 198.54,-21.38 29.17,-10.27 52.35,-18.09 70.6,-24.02 166.72,41.53 186.99,102.17 189.59,108.18z"/>
                            <path d="M1.69 147.82c-3.87,8.94 4.58,67.05 4.58,67.05l3.21 0.15 -1.36 -52.6c0,0 102.1,-119.78 261.41,-59.07 0,0 88.09,31.9 204.52,-2.37 1.22,-0.37 2.44,-0.73 3.68,-1.11 0.06,-0.03 0.12,-0.05 0.19,-0.07 6.25,-2.83 12.67,-5.85 19.31,-9.12 0,0 18.82,-11.22 64.85,-15.94l0 -0.02c0,0 -34.3,-1.38 -101.66,10.32 -67.36,11.7 -96.39,14.63 -198.54,-21.38 -29.17,-10.27 -52.35,-18.09 -70.59,-24.02 -166.73,41.53 -186.99,102.17 -189.6,108.18z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor6}>
                            <path d="M939.16 103.35c159.3,-60.71 261.4,59.07 261.4,59.07l-1.36 52.63 -15.32 0.7 -0.03 -0.02 0.01 0c0,0 -49.38,-104.8 -157.39,-103.9 -103.67,0.87 -144.34,56.44 -295.51,-11.94 118.25,36.04 208.2,3.46 208.2,3.46z"/>
                            <path d="M269.53 103.35c-159.31,-60.71 -261.41,59.07 -261.41,59.07l1.36 52.63 15.33 0.7 0.03 -0.02 -0.02 0c0,0 49.39,-104.8 157.39,-103.9 103.68,0.87 144.35,56.45 295.52,-11.94 -118.25,36.04 -208.2,3.46 -208.2,3.46z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={cor7}>
                            <path d="M606.32 51.53c-0.3,0 -0.6,0.01 -0.9,0.01 -0.3,0 -0.6,-0.01 -0.9,-0.01 -94.96,0 -196.03,40.73 -196.03,40.73l0 0.03c15.9,-1.17 32.28,-3.84 51.93,-7.25 67.36,-11.7 101.66,-10.32 101.66,-10.32l0 0.02 -0.1 0.01c12.06,-1.24 25.98,-2.03 41.92,-2.06l0.89 0c15.9,0.03 29.78,0.82 41.81,2.05l0 -0.02c0,0 34.31,-1.38 101.66,10.32 20.46,3.55 37.39,6.3 53.9,7.38l0 -0.01c0,0 -100.89,-40.88 -195.84,-40.88z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={corb}>
                            <path d="M1026.47 111.83c-108,0.9 -147.6,61.21 -315.01,-21.15 0,0 -29.94,-17.85 -106.67,-17.99l-0.89 0c-76.74,0.14 -106.67,17.99 -106.67,17.99 -167.41,82.36 -207.01,22.05 -315.02,21.15 -108,-0.9 -157.39,103.9 -157.39,103.9l1.24 0.06c223.56,55.55 442.15,59.76 533.56,58.61l0 0 44.28 0 0.43 0 0.01 0 0.01 0 0.01 0 0.43 0 44.28 0 0 0c91.41,1.15 310,-3.06 533.56,-58.61l1.23 -0.06c0,0 -49.38,-104.8 -157.39,-103.9z"/>
                        </g>
                        <g fill={logob}>
                            <path d="M565.18 129.98c-13.22,6.78 -27.43,10.09 -29.66,-1.82 -2.39,-12.78 16.12,-32.71 50.37,-34.1 7.07,-0.29 15.91,0.29 21.86,1.54 0.23,0.05 0.29,0.08 0.29,0.11 0,0.04 -0.12,0.04 -0.31,0.04 -6.92,-0.1 -13.46,0.06 -20.18,1.08 -21.73,3.31 -36.99,13.59 -35.69,19.34 0.98,4.37 10.66,4.36 22.35,0.23 7.01,-2.48 14.05,-6.29 21.85,-10.39 19.06,-10.02 44.34,-13.42 60.99,3.72 8.1,8.33 5.38,14.42 -4.77,11.62 -7.75,-2.14 -14.08,-8.54 -19.99,-13.66 -3.5,-3.04 -6.67,-5.38 -11.21,-7.32 -0.16,-0.06 -0.21,-0.1 -0.21,-0.13 0.01,-0.04 0.16,-0.02 0.41,0.04 5.97,1.47 10.95,4.75 14.83,7.33 1.94,1.3 3.98,2.2 6.29,2.8 6.53,1.7 8.71,-0.68 3.87,-4.56 -3.26,-2.63 -8.83,-5.5 -16.66,-6.46 -0.69,-0.08 -1.4,-0.16 -2.12,-0.21l0.07 0.02c0,0 -0.13,-0.02 -0.37,-0.04 -24.87,-1.7 -41.47,20.29 -62.01,30.82z"/>
                        </g>
                    </svg>
                </div>
            </div>

            <div className="row mb-4 g-0">
                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Top: </b>{Cores[cort].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cort} clique={ setCort }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor1].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor1} clique={ setCor1 }/></div>
                        <div><div><b>Design 02: </b>{Cores[cor2].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor2} clique={ setCor2 }/></div>
                        <div><div><b>Design 03: </b>{Cores[cor3].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor3} clique={ setCor3 }/></div>
                        <div><div><b>Design 04: </b>{Cores[cor4].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor4} clique={ setCor4 }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logot} clique={setLogot}/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Bottom: </b>{Cores[corb].tecido}</div><PaletaCores paleta="Paleta01" selecionada={corb} clique={ setCorb }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor5].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor5} clique={ setCor5 }/></div>
                        <div><div><b>Design 03: </b>{Cores[cor6].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor6} clique={ setCor6 }/></div>
                        <div><div><b>Design 04: </b>{Cores[cor7].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor7} clique={ setCor7 }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logob} clique={setLogob}/>
                        </div>
                    </div>
                </div>

                <div className="mt-3 d-flex justify-content-center">
                    <button className="btn btn-lg btn-success d-block d-sm-none w-100" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                    <button className="btn btn-lg btn-success d-none d-sm-block w-50" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                </div>

            </div>

        </ColunaCentral>
        <Rodape/>

        <FormSolid parapente={parapente} tamanhos={tamanhos} show={showModal} fechar={setShowModal} imgtop={imgtop} imgbottom={imgbottom} corestop={coresT} coresbottom={coresB}/>

    </>
}

export default Hercules2;