import React from 'react';

import IfComponent from './IfComponent';

function DownloadCard(props) {
    const { download } = props;

    return <>
        <IfComponent condicional={download}>
            <div className="col-12">
                <h4><a className="link-secondary" href={'/downloads/'+download.DOW_SLUG}>{download.DOW_TITULO}</a></h4>
                <small>
                    <div className="" style={{marginTop: '-6px'}}>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <span className="fw-bold me-2">{download.DOW_DTCADASTRO} </span>
                                {
                                    download.BREAD.map((item,idx) => (
                                        <li className="breadcrumb-item" key={idx}><a className="link-secondary" href={'/downloads/cat/'+item.DWC_CODIGO}>{item.DWC_DESCRICAO}</a></li>
                                        // <li class="breadcrumb-item active" aria-current="page">Library</li>
                                    ))
                                }
                            </ol>
                        </nav>
                                {/* <a className="link-secondary" href={'/downloads/cat/'+item.DWC_CODIGO}>{item.DWC_DESCRICAO} </a> */}
                    </div>
                </small>
                <hr/>
            </div>
        </IfComponent>
    </>
}

export default DownloadCard;