import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//import Api from '../Api';
import IfComponent from './IfComponent';
import { ReactComponent as Logo } from "../Imagens/logo_sol_horizontal_color.svg";
import Perfil from './Perfil';
import { LinguaContext } from '../Contextos/Linguas';

function MenuMobile(props) {
    //const navigate = useNavigate;
    const { setarIdioma } = useContext(LinguaContext);
    const { data } = props;
    const [ menus, setMenus ] = useState([]);
    // const location = window.location;
    // const { tipo } = props;

    //var myOffcanvas = document.getElementById('offcanvasRuntime')
    // var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
    
    // function off() {
    //     var offcanvas = document.getElementById('offcanvasRuntime');
    //     var offcanvasBackdrop = document.getElementsByClassName('offcanvas-backdrop');
    //     //console.log('passou aqui', myOffcanvas[0].classList.remove('show'))
    //     offcanvas.classList.remove('show');
    //     offcanvasBackdrop[0].remove();
    //     //navigate('/home');
    //     //myOffcanvas.
    //     //myOffcanvas.classList.remove('show');
    // }


    // function getMenu() {
    //     let cancel = false;

    //     var formData = new FormData();
    //     formData.set('op','menu');
    //     formData.set('tipo',tipo);
    //     Api.post('menu.php',formData).then((response) => {
    //         if (cancel) return;

    //         if (response.data.erro==='N') {             
    //             //MontarMenu(response.data.msg);
    //             setMenus(response.data.msg);
    //         } else {
    //             console.log('menu erros', response.data.erro);
    //             //Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
    //         }
    //     }).catch(({response}) => {
    //         console.log('catch: '+JSON.stringify(response));
    //     });  

    //     return () => {
    //         cancel = true;
    //     };
    // }

    // useEffect(() => {        
    //     getMenu();
    //     //eslint-disable-next-line
    // },[]);
    
    useEffect(() => {        
        setMenus(data);
    },[data]);

    return <>
        {                     
            <div className="offcanvas offcanvas-start m-0 p-0" tabIndex="-1" id="offcanvasRuntime" aria-labelledby="offcanvasRuntimeLabel">
                <div className="offcanvas-header bg-color2 navbar-height">
                    <Logo height="36" id="offcanvasRuntimeLabel"/>
                    {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">Runtime Sistemas</h5> */}
                    <button type="button" className="btn-close btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body bg-color3 p-0">
                    <div className="mx-2 my-2">
                        <Perfil/>
                    </div>
                    <div className="">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown dropdown-center px-2">
                                <button className="btn btn-sm btn-light border border-secondary border-opacity-50 dropdown-toggle w-100" data-bs-toggle="dropdown" aria-expanded="false">
                                    Paramotors
                                </button>
                                <ul className="dropdown-menu font-reset">
                                    <li><a className="dropdown-item" href="https://solparagliders.com.br">Paragliders</a></li>
                                    <li><a className="dropdown-item active disabled" href="https://solparamotors.com.br">Paramotors</a></li>
                                    <li><a className="dropdown-item" href="https://solsportswear.com.br">Sports</a></li>
                                    <li><a className="dropdown-item" href="https://soldefense.com.br">Defense</a></li>
                                </ul>
                            </li>
                        </ul>
                        {/* <select className="form-select">
                            <option value="A">Paragliders</option>
                            <option value="A">Paramotors</option>
                            <option value="A">Sports</option>
                            <option value="A">Defense</option>
                        </select> */}
                    </div>
                    <div className="mx-2 my-2 d-flex justify-content-around">
                        <Link onClick={() => setarIdioma('BR')}><img style={{maxWidth: '32px' }} src="/imagens/flag_brazil2.png" alt="" /></Link>
                        <Link onClick={() => setarIdioma('EN')}><img style={{maxWidth: '32px' }} src="/imagens/flag_great_britain2.png" alt="" /></Link>
                        <Link onClick={() => setarIdioma('DE')}><img style={{maxWidth: '32px' }} src="/imagens/flag_germany2.png" alt="" /></Link>
                        {/* <Link onClick={() => setarIdioma('FR')}><img style={{maxWidth: '32px' }} src="/imagens/flag_france2.png" alt="" /></Link> */}
                        <Link to="https://solfrance.fr"><img style={{maxWidth: '32px' }} src="/imagens/flag_france2.png" alt="" /></Link>
                    </div>
                    <div className="w-100">
                        <div className="accordion accordion-flush accordion-pett" id="menumobile">
                            <IfComponent condicional={ menus.length > 0 }>
                                {                     
                                    menus.map(menu => (
                                        <React.Fragment key={ menu.MEN_CODIGO }>
                                            <IfComponent condicional={ menu.MEN_DROP==="S" } >
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={'H'+menu.MEN_CODIGO}>
                                                        <button className="accordion-button accordion-button-pett ps-3 py-3 pe-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#MEN'+menu.MEN_CODIGO} aria-expanded="false" aria-controls={'MEN'+menu.MEN_CODIGO}>{menu.MEN_DESCRICAO}</button>
                                                    </h2>
                                                    <div id={'MEN'+menu.MEN_CODIGO} className="accordion-collapse collapse" aria-labelledby={'H'+menu.MEN_CODIGO} data-bs-parent="#menumobile">
                                                        <div className="accordion-body p-0 ps-3">
                                                            <div className="accordion accordion-flush" id={'ac'+menu.MEN_CODIGO}>
                                                                <MenuFilhos data={menu.MEN_FILHOS} parent={'ac'+menu.MEN_CODIGO}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </IfComponent>
                                            <IfComponent condicional={ menu.MEN_DROP==="N" } >
                                                <div className="accordion-item accordion-item-pett">
                                                    <div className="accordion-body ps-3 py-3 pe-2"><a href={menu.MEN_LINK} className="text-uppercase d-block" target={menu.MEN_TARGET}>{menu.MEN_DESCRICAO}</a></div>
                                                </div>
                                            </IfComponent>
                                        </React.Fragment>
                                    ))
                                }
                            </IfComponent>
                        </div>
                    </div>
                </div>
            </div>
        }        
    </>
}

function MenuFilhos(props) {
    const { data, parent } = props;
    // const location = window.location;

    return <>
        <IfComponent condicional={ data.length > 0 }>
            {
                data.map(menu => (
                    <React.Fragment key={ menu.MEN_CODIGO }>
                        <IfComponent condicional={ menu.MEN_DROP==="S" }>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={'H'+menu.MEN_CODIGO}>
                                    <button className="accordion-button accordion-button-pett ps-3 py-3 pe-2 collapsed text-uppercase" type="button" data-bs-toggle="collapse" data-bs-target={'#MEN'+menu.MEN_CODIGO} aria-expanded="false" aria-controls={'MEN'+menu.MEN_CODIGO}>{menu.MEN_DESCRICAO}</button>
                                </h2>
                                <div id={'MEN'+menu.MEN_CODIGO} className="accordion-collapse collapse" aria-labelledby={'H'+menu.MEN_CODIGO} data-bs-parent={'#'+parent}>
                                    <div className="accordion-body p-0 ps-3">
                                        <div className="accordion accordion-flush" id={'ac'+menu.MEN_CODIGO}>
                                            <MenuFilhos data={menu.MEN_FILHOS} parent={'ac'+menu.MEN_CODIGO}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IfComponent>
                        <IfComponent condicional={ menu.MEN_DROP==="N" }>
                            <div className="accordion-item accordion-item-pett">
                                <div className="accordion-body ps-3 py-3 pe-2"><a href={menu.MEN_LINK} className="text-uppercase d-block" target={menu.MEN_TARGET}>{menu.MEN_DESCRICAO}</a></div>
                            </div>
                        </IfComponent>
                    </React.Fragment>
                ))
            }
        </IfComponent>
    </>
}

export default MenuMobile;