import React, { useContext, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';

import Api from '../Api';
import { AuthContext } from '../Contextos/Auth';
import { LinguaContext } from '../Contextos/Linguas';
import { UnidadeContext } from '../Contextos/Unidades';
import Menu from './Menu';

import { ReactComponent as Logo } from "../Imagens/logo_sol_horizontal_color.svg";
import IfComponent from './IfComponent';
import MenuMobile from './MenuMobile';
import ComboLinguas from './ComboLinguas';

function BarraSuperior() {
    const { idioma } = useContext(LinguaContext);
    const { unidade } = useContext(UnidadeContext);
    // const { unidade, setarUnidade } = useContext(UnidadeContext);
    const { logado, usuarioAvatar } = useContext(AuthContext);
    const [ menus, setMenus ] = useState([]);

    function getMenu() {
        let cancel = false;
        let tipo = 1;

        var formData = new FormData();
        formData.set('op','menu');
        formData.set('tipo',tipo);
        formData.set('unidade',unidade);
        formData.set('idioma',idioma);
        Api.post('menu.php',formData).then((response) => {
            if (cancel) return;

            if (response.data.erro==='N') {             
                //MontarMenu(response.data.msg);
                setMenus(response.data.msg);
            } else {
                console.log('menu erros', response.data.erro);
                //Swal.fire({ title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529' });
            }
        }).catch(({response}) => {
            console.log('CATCH: '+JSON.stringify(response));
        });  

        return () => {
            cancel = true;
        };
    }

    useEffect(() => {        
        getMenu();
        //eslint-disable-next-line
    },[idioma,unidade]);

    return <>
        <header className="fixed-top">  
            {/* <nav className="navbar navbar-expand-lg navbar-dark d-none d-lg-block d-xl-block bg-color1" style={{height: '24px', maxHeight: '24px'}} id="barramenu1">
                <div className="container">
                    <div className="navbar-collapse collapse" id="barramenu1">
                        <div className="menubarra">
                            { !logado?<a href="/login">ACESSAR</a>:<a href="/#" onClick={logout}>SAIR</a> }
                            <a href="https://wa.me/5547991963060" target="_blank" rel="noreferrer"><i className="fab fa-fw fa-whatsapp"></i> WhatsApp</a>
                            <a href="https://www.facebook.com/rogeriopett/" target="_blank" rel="noreferrer"><i className="fab fa-fw fa-facebook"></i> Facebook</a>
                            <a href="https://instagram.com/rogeriopett/" target="_blank" rel="noreferrer"><i className="fab fa-fw fa-instagram"></i> Instagram</a>
                            <a href="/carrinho.php"><i className="fa fa-fw fa-shopping-cart"></i> Carrinho</a>
                        </div>
                    </div>
                </div>
            </nav> */}

            <nav className="navbar navbar-expand-lg navbar-height bg-color2"> 
            {/* <nav className="navbar navbar-expand-lg navbar-dark bg-color2"> */}
                <div className="container">
                    <a className="navbar-brand" href="/"><Logo height="36"/></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRuntime" aria-controls="offcanvasRuntime">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="barramenu2">
            	        <div className="w-100 d-flex justify-content-between">
                            <div className="">
                                <IfComponent condicional={logado===false}>
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        {/* <li className="nav-item">
                                            <Link className="nav-link" to='/login'>Acessar</Link>
                                        </li> */}
                                        <li className="nav-item dropdown p-2">
                                            <button className="btn btn-sm btn-light bg-transparent dropdown-toggle font-reset" data-bs-toggle="dropdown" aria-expanded="false">
                                                Paramotors
                                            </button>
                                            <ul className="dropdown-menu font-reset">
                                                <li><a className='dropdown-item' href="https://solparagliders.com.br">Paragliders</a></li>
                                                <li><a className='dropdown-item active disabled' href="https://solparamotors.com.br">Paramotors</a></li>
                                                <li><a className='dropdown-item' href="https://solsportswear.com.br">Sports</a></li>
                                                <li><a className='dropdown-item' href="https://soldefense.com.br">Defense</a></li>
                                            </ul>
                                        </li>
                                        {/* desta forma todas as unidades abrem na mesma URL <li className="nav-item dropdown p-2">
                                            <button className="btn btn-sm btn-light bg-transparent dropdown-toggle font-reset" data-bs-toggle="dropdown" aria-expanded="false">
                                                <IfComponent condicional={unidade==='1'}>Paragliders</IfComponent>
                                                <IfComponent condicional={unidade==='2'}>Paramotors</IfComponent>
                                                <IfComponent condicional={unidade==='3'}>Sports</IfComponent>
                                                <IfComponent condicional={unidade==='4'}>Defense</IfComponent>
                                            </button>
                                            <ul className="dropdown-menu font-reset">
                                                <li><a className={'dropdown-item '+(unidade==="1"?'active disabled':'')} href="\#" onClick={(e) => setarUnidade(e,'1')}>Paragliders</a></li>
                                                <li><a className={'dropdown-item '+(unidade==="2"?'active disabled':'')} href="\#" onClick={(e) => setarUnidade(e,'2')}>Paramotors</a></li>
                                                <li><a className={'dropdown-item '+(unidade==="3"?'active disabled':'')} href="\#" onClick={(e) => setarUnidade(e,'3')}>Sports</a></li>
                                                <li><a className={'dropdown-item '+(unidade==="4"?'active disabled':'')} href="\#" onClick={(e) => setarUnidade(e,'4')}>Defense</a></li>
                                            </ul>
                                        </li> */}
                                    </ul>
                                </IfComponent>
                                <IfComponent condicional={logado===true}>
                                    <ul className="navbar-nav me-auto">
                                        <li className="nav-item dropdown p-2">
                                            <button className="btn btn-sm btn-light bg-transparent dropdown-toggle font-reset" data-bs-toggle="dropdown" aria-expanded="false">
                                                Paramotors
                                            </button>
                                            <ul className="dropdown-menu font-reset">
                                                <li><a className='dropdown-item' href="https://solparagliders.com.br">Paragliders</a></li>
                                                <li><a className='dropdown-item active disabled' href="https://solparamotors.com.br">Paramotors</a></li>
                                                <li><a className='dropdown-item' href="https://solsportswear.com.br">Sports</a></li>
                                                <li><a className='dropdown-item' href="https://soldefense.com.br">Defense</a></li>
                                            </ul>
                                        </li>
                                        {/* <li className="nav-item dropdown p-2">
                                            <button className="btn btn-sm btn-light bg-transparent dropdown-toggle font-reset" data-bs-toggle="dropdown" aria-expanded="false">
                                                <IfComponent condicional={unidade==='1'}>Paragliders</IfComponent>
                                                <IfComponent condicional={unidade==='2'}>Paramotors</IfComponent>
                                                <IfComponent condicional={unidade==='3'}>Sports</IfComponent>
                                                <IfComponent condicional={unidade==='4'}>Defense</IfComponent>
                                            </button>
                                            <ul className="dropdown-menu font-reset">
                                                <li><a className={'dropdown-item '+(unidade==="1"?'active disabled':'')} href="\#" onClick={(e) => setarUnidade(e,'1')}>Paragliders</a></li>
                                                <li><a className={'dropdown-item '+(unidade==="2"?'active disabled':'')} href="\#" onClick={(e) => setarUnidade(e,'2')}>Paramotors</a></li>
                                                <li><a className={'dropdown-item '+(unidade==="3"?'active disabled':'')} href="\#" onClick={(e) => setarUnidade(e,'3')}>Sports</a></li>
                                                <li><a className={'dropdown-item '+(unidade==="4"?'active disabled':'')} href="\#" onClick={(e) => setarUnidade(e,'4')}>Defense</a></li>
                                            </ul>
                                        </li> */}
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown" href="\#" role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRuntime" aria-controls="offcanvasRuntime" aria-expanded="false">
                                                <img src={usuarioAvatar} className='rounded-circle' alt='' style={{maxHeight: '32px'}}/>
                                            </a>
                                        </li>

                                        {/* <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href='#' id='LNG' role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">DEFENSE</a>
                                            <ul className="dropdown-menu font-reset" aria-labelledby='LNG'>
                                                <li><a className='dropdown-item' href="https://solparagliders.com.br">Paragliders</a></li>
                                                <li><a className='dropdown-item active disabled' href="https://solparamotors.com.br">Paramotors</a></li>
                                                <li><a className='dropdown-item' href="https://solsports.com.br">Sports</a></li>
                                                <li><a className='dropdown-item' href="https://soldefense.com.br">Defense</a></li>
                                            </ul>                                    
                                        </li> */}

                                    </ul>
                                </IfComponent>     
                            </div>
                            <div>
                                <Menu tipo="1" data={menus}/>
                            </div>
                            <div>
                                <ComboLinguas/>          
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        <MenuMobile tipo="1" data={menus}/>
    </>
}

export default BarraSuperior;