import React from "react";
import { Link } from "react-router-dom";
// import Swiper from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './BannersSwipers.css';

function BannersSwipers(props) {
    const { banners, autoPlay, className, slidesPerView, spaceBetween, loop } = props;

    return <>
        <Swiper 
            className={className}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            modules={[ Autoplay, Mousewheel, Navigation, Pagination ]}
            pagination={{ clickable: true }}
            navigation
            autoplay={{delay: autoPlay===0?'false':autoPlay}}
            loop = { loop }
            // mousewheel
        >
            
            { 
                banners.map((item,idx) =>(
                    <SwiperSlide key={idx} >
                        {/* <div className="col-6"> */}
                            <Link to={item.BAF_LINK}>
                                <img className="img-fluid" src={item.BAF_ARQUIVO} alt={item.BAF_CODIGO} loading="lazy"/>
                                <div className="swiper-lazy-preloader"></div>
                            </Link>
                        {/* </div> */}
                    </SwiperSlide>
                ))
            }
        </Swiper>
    </>
}

export default BannersSwipers;