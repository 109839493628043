import React, { useContext, useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

import { LinguaContext } from '../../Contextos/Linguas';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Bloco from '../../Componentes/Bloco';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Cores from './Cores.js';
import FormSolid from '../../Componentes/FormSolid';
import PaletaAdesivos from '../../Componentes/PaletaAdesivos';
import PaletaCores from '../../Componentes/PaletaCores';
import Rodape from '../../Componentes/Rodape';
import T from '../../Traducoes';

function Effectxt() {
    const { idioma } = useContext(LinguaContext);
    const svg_top = useRef(null);
    const svg_bottom = useRef(null);
    const parapente = 'Effect XT';
    const [ imgtop, setImgtop ] = useState('');
    const [ imgbottom, setImgbottom ] = useState('');
    const [ coresT, setCoresT ] = useState('');
    const [ coresB, setCoresB ] = useState('');
    const [ cort, setCort ] = useState('#FFFFFF');
    const [ corb, setCorb ] = useState('#FFFFFF');
    const [ cor1, setCor1 ] = useState('#FFFFFF');
    const [ cor2, setCor2 ] = useState('#FFFFFF');
    const [ cor3, setCor3 ] = useState('#FFFFFF');
    const [ cor4, setCor4 ] = useState('#FFFFFF');
    // const [ logot, setLogot ] = useState('transparent');
    const [ logob, setLogob ] = useState('transparent');
    const [ showModal, setShowModal ] = useState(false);
    const tamanhos = [
        {value: "XXS", label: "XXS" },
        {value: "XS", label: "XS" },
        {value: "S", label: "S" },
        {value: "M", label: "M" },
        {value: "L", label: "L" },
        {value: "XL", label: "XL" },
        {value: "XXL", label: "XXL" },
    ];
    
    function padrao1() {
        setCort('#F00008');
        setCorb('#F00008');
        setCor1('#1F4194');
        setCor2('#191D24');
        setCor3('#FFFFFF');
        setCor4('#FFFFFF');
    }
        
    function padrao2() {
        setCort('#1F4194');
        setCorb('#1F4194');
        setCor1('#F00008');
        setCor2('#FFFFFF');
        setCor3('#191D24');
        setCor4('#FFFFFF');
    }

    function padrao3() {
        setCort('#FBE111');
        setCorb('#FBE111');
        setCor1('#1F4194');
        setCor2('#FFFFFF');
        setCor3('#191D24');
        setCor4('#191D24');
    }

    useEffect(() => {
        padrao1();
    },[]);

    function gerarImagem() {
        htmlToImage.toPng(svg_top.current)
        .then(function (dataURL) {
            setImgtop(dataURL);
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
        
        htmlToImage.toPng(svg_bottom.current)
        .then(function (dataURL) {
            setImgbottom(dataURL)
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
    }

    function abrirModalEnviar() {
        gerarImagem();
        let lt="";
        // if (logot==="transparent") lt="NENHUM / NO LOGO";
        // if (logot==="#000000") lt="Preto / Black";
        // if (logot==="#FF0000") lt="Vermelho / Red";

        let lb="";
        if (logob==="transparent") lb="NENHUM / NO LOGO";
        if (logob==="#000000") lb="Preto / Black";
        if (logob==="#FF0000") lb="Vermelho / Red";

        const novasCoresTop = 
        'Top: '+Cores[cort].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Design 03: '+Cores[cor3].descricao+'\n'+
        'Logo: '+lt+'\n\r';

        const novasCoresBottom = 
        'Bottom: '+Cores[corb].descricao+'\n'+
        'Logo: '+lb+'\n';

        setCoresT(novasCoresTop);
        setCoresB(novasCoresBottom);

        setShowModal(true);
    }

    return <>
        <BarraSuperior/>
        <Bloco className="container" id="175"/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <div className="row mb-4">

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="w-100 d-flex justify-content-right mb-3 overflow-auto">
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/effect_xt_padrao1.jpg" onClick={() => padrao1()} alt="1" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/effect_xt_padrao2.jpg" onClick={() => padrao2()} alt="2" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/effect_xt_padrao3.jpg" onClick={() => padrao3()} alt="3" style={{maxWidth: '70px'}}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 312.72 70.17" ref={svg_top}>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={cort}>
                            <path d="M4.18 59.8c1.41,0.03 3.2,0.04 5.36,0.2 13.79,2.14 28.36,4.07 44.81,5.49 32.84,2.23 65.25,4.63 104.64,4.34 24.06,0.69 48.57,-0.92 73.1,-2.55 24.63,-1.17 48.09,-3.73 70.71,-7.28 2.09,-0.23 3.81,-0.26 5.74,-0.23l-0.16 0c-17.61,-36.7 -61.63,-38.81 -85.41,-14.79 -15.93,-13.79 -45.34,-27.02 -66.73,-26.95 -27.72,0.54 -52.52,16.01 -67.01,27.05 -27.9,-28.25 -73.58,-16.04 -84.94,14.71l-0.11 0.01z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M312.54 48.59l0 -8.18c-11.9,-15.08 -28.3,-22.84 -46.45,-27.75 -2.28,0.15 -5.73,1.28 -7.79,1.98 -16.44,7.83 -28.56,11.66 -35.47,10.69 -5.28,-0.35 -9.72,-1.39 -13.9,-2.66 -15.26,-5.93 -32.1,-9.92 -52.13,-9.98 -18.51,-0.36 -34.85,2.8 -49.51,8.68 -8.04,2.77 -14.48,4.12 -19.23,3.96 -8.37,-0.27 -19.03,-3.08 -34.37,-11.06 -2.23,-0.76 -4.4,-1.34 -6.5,-1.64 -21.23,5.94 -37.88,14.65 -47.01,27.91l0 8.53c20.2,-24.65 55.05,-37.51 89.25,-13.98 19.39,-11.05 41.12,-18.52 66.93,-19.67 20.4,0 44.7,7.22 66.76,19.67 30.02,-18.75 60.48,-17.45 89.42,13.5z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor3}>
                            <path d="M0.18 60.2c1.38,-0.21 2.39,-0.37 4.23,-0.41 11.37,-30.75 57.05,-42.96 84.94,-14.71 14.49,-11.04 39.3,-26.51 67.01,-27.05 21.39,-0.07 50.8,13.16 66.73,26.95 23.78,-24.02 67.8,-21.91 85.41,14.79l4.04 0.23 0 -11.41c-28.94,-30.95 -59.4,-32.25 -89.42,-13.5 -22.06,-12.45 -46.36,-19.67 -66.76,-19.67 -25.81,1.15 -47.54,8.62 -66.93,19.67 -34.2,-23.53 -69.05,-10.67 -89.25,13.98l0 11.13z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                            <path d="M46.91 12.63c0.06,0.01 0.12,0.02 0.18,0.03l0.1 -0.03c2.1,0.3 4.27,0.88 6.5,1.64 15.34,7.98 26,10.79 34.37,11.06 4.75,0.16 11.19,-1.19 19.23,-3.96 14.66,-5.88 31,-9.04 49.51,-8.68 20.03,0.06 36.87,4.05 52.13,9.98 4.17,1.27 8.62,2.31 13.9,2.66 6.91,0.97 19.03,-2.86 35.47,-10.69 1.84,-0.63 4.8,-1.6 7.03,-1.9 0.14,-0.04 0.3,-0.07 0.48,-0.08 -30.86,-8.98 -69.57,-12.35 -109.74,-12.47 -38.63,-0.26 -75.6,2.74 -109.16,12.44z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor4}>
                            <path d="M140.21 58.98c2.85,-3.67 6.02,-7.29 12.89,-10.41 1.99,-0.65 4.13,-0.72 6.5,0 4.99,2.16 9.34,5.55 12.91,10.41l-0.01 -5.73c-3.42,-4.67 -7.73,-8.13 -12.9,-10.41 5.01,-2.24 9.42,-5.52 12.92,-10.41l-0.01 -5.72c-3.29,4.63 -7.62,8.07 -12.92,10.41 -1.1,0.35 -2.17,0.51 -3.23,0.52 -1.15,0.01 -2.22,-0.19 -3.24,-0.52 -5.76,-2.52 -10.29,-6.5 -12.91,-10.41l0 5.72c3.44,4.7 7.73,8.19 12.91,10.41 -5.12,2.27 -9.54,5.57 -12.91,10.41l0 5.73z"/>
                        </g>
                    </svg>                    
                </div>          

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 312.72 70.17" ref={svg_bottom}>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={corb}>
                            <path d="M4.18 59.8c1.41,0.03 3.2,0.04 5.36,0.2 13.79,2.14 28.36,4.07 44.81,5.49 32.84,2.23 65.25,4.63 104.64,4.34 24.06,0.69 48.57,-0.92 73.1,-2.55 24.63,-1.17 48.09,-3.73 70.71,-7.28 2.09,-0.23 3.81,-0.26 5.74,-0.23l-0.16 0c-17.61,-36.7 -61.63,-38.81 -85.41,-14.79 -15.93,-13.79 -45.34,-27.02 -66.73,-26.95 -27.72,0.54 -52.52,16.01 -67.01,27.05 -27.9,-28.25 -73.58,-16.04 -84.94,14.71l-0.11 0.01z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={corb}>
                            <path d="M312.54 48.59l0 -8.18c-11.9,-15.08 -28.3,-22.84 -46.45,-27.75 -2.28,0.15 -5.73,1.28 -7.79,1.98 -16.44,7.83 -28.56,11.66 -35.47,10.69 -5.28,-0.35 -9.72,-1.39 -13.9,-2.66 -15.26,-5.93 -32.1,-9.92 -52.13,-9.98 -18.51,-0.36 -34.85,2.8 -49.51,8.68 -8.04,2.77 -14.48,4.12 -19.23,3.96 -8.37,-0.27 -19.03,-3.08 -34.37,-11.06 -2.23,-0.76 -4.4,-1.34 -6.5,-1.64 -21.23,5.94 -37.88,14.65 -47.01,27.91l0 8.53c20.2,-24.65 55.05,-37.51 89.25,-13.98 19.39,-11.05 41.12,-18.52 66.93,-19.67 20.4,0 44.7,7.22 66.76,19.67 30.02,-18.75 60.48,-17.45 89.42,13.5z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={corb}>
                            <path d="M0.18 60.2c1.38,-0.21 2.39,-0.37 4.23,-0.41 11.37,-30.75 57.05,-42.96 84.94,-14.71 14.49,-11.04 39.3,-26.51 67.01,-27.05 21.39,-0.07 50.8,13.16 66.73,26.95 23.78,-24.02 67.8,-21.91 85.41,14.79l4.04 0.23 0 -11.41c-28.94,-30.95 -59.4,-32.25 -89.42,-13.5 -22.06,-12.45 -46.36,-19.67 -66.76,-19.67 -25.81,1.15 -47.54,8.62 -66.93,19.67 -34.2,-23.53 -69.05,-10.67 -89.25,13.98l0 11.13z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={corb}>
                            <path d="M46.91 12.63c0.06,0.01 0.12,0.02 0.18,0.03l0.1 -0.03c2.1,0.3 4.27,0.88 6.5,1.64 15.34,7.98 26,10.79 34.37,11.06 4.75,0.16 11.19,-1.19 19.23,-3.96 14.66,-5.88 31,-9.04 49.51,-8.68 20.03,0.06 36.87,4.05 52.13,9.98 4.17,1.27 8.62,2.31 13.9,2.66 6.91,0.97 19.03,-2.86 35.47,-10.69 1.84,-0.63 4.8,-1.6 7.03,-1.9 0.14,-0.04 0.3,-0.07 0.48,-0.08 -30.86,-8.98 -69.57,-12.35 -109.74,-12.47 -38.63,-0.26 -75.6,2.74 -109.16,12.44z"/>
                        </g>
                        <g fill={logob}>
                            {/* <!-- X-> <path d="M140.21 58.98c2.85,-3.67 6.02,-7.29 12.89,-10.41 1.99,-0.65 4.13,-0.72 6.5,0 4.99,2.16 9.34,5.55 12.91,10.41l-0.01 -5.73c-3.42,-4.67 -7.73,-8.13 -12.9,-10.41 5.01,-2.24 9.42,-5.52 12.92,-10.41l-0.01 -5.72c-3.29,4.63 -7.62,8.07 -12.92,10.41 -1.1,0.35 -2.17,0.51 -3.23,0.52 -1.15,0.01 -2.22,-0.19 -3.24,-0.52 -5.76,-2.52 -10.29,-6.5 -12.91,-10.41l0 5.72c3.44,4.7 7.73,8.19 12.91,10.41 -5.12,2.27 -9.54,5.57 -12.91,10.41l0 5.73z"/> --> */}
                            <path d="M146.41 39.53c-3.95,2.03 -8.18,3.02 -8.84,-0.53 -0.72,-3.82 4.8,-9.76 15.01,-10.17 2.11,-0.08 4.75,0.09 6.52,0.46 0.07,0.01 0.09,0.02 0.08,0.03 0,0.01 -0.03,0.01 -0.09,0.01 -2.06,-0.03 -4.01,0.02 -6.02,0.33 -6.47,0.98 -11.02,4.05 -10.63,5.76 0.29,1.31 3.18,1.3 6.66,0.07 2.09,-0.73 4.18,-1.87 6.51,-3.09 5.68,-3 13.22,-4.01 18.18,1.1 2.42,2.49 1.61,4.3 -1.41,3.47 -2.31,-0.64 -4.21,-2.55 -5.96,-4.08 -1.05,-0.9 -2,-1.6 -3.34,-2.18 -0.05,-0.02 -0.07,-0.03 -0.07,-0.04 0.01,-0.01 0.05,0 0.13,0.01 1.77,0.45 3.26,1.42 4.41,2.19 0.58,0.39 1.19,0.66 1.88,0.84 1.94,0.5 2.6,-0.2 1.15,-1.36 -0.97,-0.79 -2.64,-1.64 -4.97,-1.93 -0.2,-0.03 -0.41,-0.05 -0.63,-0.07l0.02 0.01c0,0 -0.03,-0.01 -0.11,-0.01 -7.41,-0.5 -12.36,6.05 -18.48,9.18l0 0z"/>
                        </g>
                    </svg>                   
                </div>
            </div>

            <div className="row mb-4 g-0">
                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Top: </b>{Cores[cort].tecido}</div><PaletaCores paleta="Paleta08" selecionada={cort} clique={ setCort }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor1].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor1} clique={ setCor1 }/></div>
                        <div><div><b>Design 02: </b>{Cores[cor2].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor2} clique={ setCor2 }/></div>
                        <div><div><b>Design 03: </b>{Cores[cor3].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor3} clique={ setCor3 }/></div>

                        {/* <div className="w-100 overflow-auto">
                            <img className="img-thumbnail me-2" src="https://solparagliders.com.br/solid/imagens/logo_p.png" onClick={() => setLogot('#000000') } alt="p" style={{maxWidth: '60px'}}/>
                            <img className="img-thumbnail me-2" src="https://solparagliders.com.br/solid/imagens/logo_v.png" onClick={() => setLogot('#FF0000') } alt="v" style={{maxWidth: '60px'}}/>
                            <img className="img-thumbnail me-2" src="https://solparagliders.com.br/solid/imagens/logo_x.png" onClick={() => setLogot('transparent') } alt="x" style={{maxWidth: '60px'}}/>
                        </div> */}
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Bottom: </b>{Cores[corb].tecido}</div><PaletaCores paleta="Paleta08" selecionada={corb} clique={ setCorb }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logob} clique={setLogob}/>
                        </div>
                    </div>
                </div>

                <div className="mt-3 d-flex justify-content-center">
                    <button className="btn btn-lg btn-success d-block d-sm-none w-100" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                    <button className="btn btn-lg btn-success d-none d-sm-block w-50" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                </div>

            </div>

        </ColunaCentral>
        <Rodape/>

        <FormSolid parapente={parapente} tamanhos={tamanhos} show={showModal} fechar={setShowModal} imgtop={imgtop} imgbottom={imgbottom} corestop={coresT} coresbottom={coresB}/>

    </>
}

export default Effectxt;