import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import Api from '../Api';
import { UnidadeContext } from '../Contextos/Unidades';
import { LinguaContext } from '../Contextos/Linguas';
import BarraSuperior from '../Componentes/BarraSuperior';
import ColunaCentral from '../Componentes/ColunaCentral';
import IfComponent from '../Componentes/IfComponent';
import Rodape from '../Componentes/Rodape';

function DownloadsList() {
    const { unidade } = useContext(UnidadeContext);
    const { idioma } = useContext(LinguaContext);
    const { slug } = useParams();
    const [ download, setDownload ] = useState({DOW_CODIGO:0});
    const [ loading, setLoading ] = useState(true);

    async function verDownload() {
        setLoading(true);
        const formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', slug);
        formData.set('idioma', idioma);
        formData.set('unidade', unidade);

        try {
            const response = await Api.post('downloads.php',formData);
            if (response.data.erro==="N") {
                setDownload(response.data.msg);
            } else {
                setDownload({DOW_CODIGO:0});
                //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } catch (error) {
            Swal.fire({title: 'Ah nãooooo!', text: error, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        }
        setLoading(false);
    }

    useEffect(() => {      
        verDownload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    async function baixarArquivo() {
        const formData = new FormData();
        formData.set('op','link');
        formData.set('codigo',download.DOW_SLUG);
        try {
            const response = await Api.post('downloads.php',formData);
            console.log('sss',response.data);
            if (response.data.erro==="N") {
                const link = document.createElement('a');
                //link.download = response.data.msg;
                link.href = response.data.link;
                link.click();
            } else {
                Swal.fire({title: 'Ooops!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
            
        } catch (error) {
            console.log('CATCH:',error);
        }

    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">

            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <IfComponent condicional={download.DOW_CODIGO!==0}>
                        <p className="news"><a href={'/downloads/'+download.DOW_SLUG}>{download.DOW_TITULO}</a></p>
                        <small>
                            <p className="" style={{marginTop: '-6px'}}>
                                <strong>{download.DOW_DTCADASTRO} </strong> | <a className="link-secondary" href={'/downloads/cat/'+download.DOW_CATEGORIA}>{download.DWC_DESCRICAO} </a> 
                            </p>
                        </small>
                        <button className="btn btn-lg btn-success mb-3" onClick={() => baixarArquivo()}><i className="fa fa-fw fa-download"></i> Baixar este arquivo</button>
                        <section className="mb-5" dangerouslySetInnerHTML={{ __html: download.DOW_DESCRICAO }} />
                    </IfComponent>
                    <IfComponent condicional={loading===true}>
                        <div className="container my-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando...</div>
                    </IfComponent>
                    <IfComponent condicional={loading===false}>
                        <IfComponent condicional={download.DOW_CODIGO===0}>
                            <div className="text-center fs-4 my-5">Download não localizado.</div>
                        </IfComponent>
                    </IfComponent>
                </div>
            </div>
        
        </ColunaCentral>
        <Rodape/>
    </>
}

export default DownloadsList;