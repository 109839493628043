import React from 'react';
import "./TreeView.css";

function TreeView(props) {
    const { data, id, descricao, filhos, fechar, slug } = props;

    return <>
        <div className="css-treeview">
            <ul>
                {
                    data.map((item,idx) => (
                        <li className="" key={idx}>
                            <input type="checkbox" id={
                                // eslint-disable-next-line no-eval
                                'item'+eval('item.'+id) } /><label htmlFor={'item'+eval('item.'+id)}><a href="/#" onClick={(e) => fechar(e, eval('item.'+id), eval('item.'+descricao), eval('item.'+slug) )}>{eval("item."+descricao)}</a></label>
                            <TreeViewFilhos data={
                                // eslint-disable-next-line no-eval
                                eval('item.'+filhos)} id={id} descricao={descricao} filhos={filhos} slug={slug} fechar={fechar}/>
                        </li>
                    ))
                }
            </ul>
        </div>
    </>
}

function TreeViewFilhos(props) {
    const { data, id, descricao, filhos, fechar, slug } = props;

    return <>
        <ul>
            {
                data.map((item,idx) => (
                    <li className="" key={idx}>
                        <input type="checkbox" id={
                            // eslint-disable-next-line no-eval
                            'item'+eval('item.'+id)} /><label htmlFor={'item'+eval('item.'+id)}><a href="/#" onClick={(e) => fechar(e, eval('item.'+id), eval('item.'+descricao), eval('item.'+slug) )}>{eval('item.'+descricao)}</a></label>
                        <TreeViewFilhos data={
                            // eslint-disable-next-line no-eval
                            eval('item.'+filhos)} id={id} descricao={descricao} filhos={filhos} slug={slug} fechar={fechar}/>
                    </li>
                ))
            }
        </ul>
    </>
}

export default TreeView;