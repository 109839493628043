import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import Api from '../Api';
import { UnidadeContext } from '../Contextos/Unidades';
import { LinguaContext } from '../Contextos/Linguas';
import BarraSuperior from '../Componentes/BarraSuperior';
import ColunaCentral from '../Componentes/ColunaCentral';
import IfComponent from '../Componentes/IfComponent';
import Rodape from '../Componentes/Rodape';
import VideoWrapper from '../Componentes/VideoWrapper';
import Cabecalho from '../Componentes/Cabecalho';

function VideosView() {
    const { unidade } = useContext(UnidadeContext);
    const { idioma } = useContext(LinguaContext);
    const { slug } = useParams();
    const [ video, setVideo ] = useState({VID_CODIGO:0});
    const [ loading, setLoading ] = useState(true);

    async function verVideo() {
        setLoading(true);
        const formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', slug);
        formData.set('idioma', idioma);
        formData.set('unidade', unidade);

        try {
            const response = await Api.post('videos.php',formData);
            if (response.data.erro==="N") {
                setVideo(response.data.msg);
            } else {
                setVideo({VID_CODIGO:0});
                //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } catch (error) {
            Swal.fire({title: 'Ah nãooooo!', text: error, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        }
        setLoading(false);
    }

    useEffect(() => {      
        verVideo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">

            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <IfComponent condicional={video.VID_CODIGO!==0}>
                        <Cabecalho className="mb-3" titulo={video.VID_NOME} texto={video.VID_DESCRICAOCURTA}/>
                        <VideoWrapper className="mb-5" video={video.VID_LINK+'?controls=0&autoplay=1'} imagem={video.VID_IMAGEMDESTAQUE} titulo={video.VID_NOME} descricao={video.VID_DESCRICAOCURTA}/>
                        <section className="mb-5" dangerouslySetInnerHTML={{ __html: video.VID_DESCRICAO }} />
                    </IfComponent>
                    <IfComponent condicional={loading===true}>
                        <div className="container my-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando...</div>
                    </IfComponent>
                    <IfComponent condicional={loading===false}>
                        <IfComponent condicional={video.VID_CODIGO===0}>
                            <div className="text-center fs-4 mt-5">Vídeo não localizado.</div>
                        </IfComponent>
                    </IfComponent>
                </div>
            </div>

        </ColunaCentral>
        <Rodape/>
    </>
}

export default VideosView;