import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Swal from 'sweetalert2';

import { LinguaContext } from '../Contextos/Linguas';
import Api from '../Api';
import T from '../Traducoes';

function FormSolid(props) {
    const { idioma } = useContext(LinguaContext);
    const { tamanhos, show, fechar, parapente, imgtop, imgbottom, corestop, coresbottom } = props;
    const [ formulario, setFormulario ] = useState({});
    const [ showModal, setShowModal ] = useState(false);
    const [ enviando, setEnviando ] = useState(false);
    const finalidade = [
        { value: T(idioma,"Pedido finalizado com revendedor, somente escolha de cores"), label: T(idioma,"Pedido finalizado com revendedor, somente escolha de cores") },
        { value: T(idioma,"Orçamento e quero retorno do setor de vendas"), label: T(idioma,"Orçamento e quero retorno do setor de vendas") }
    ];

    useEffect(() => {
        setShowModal(show);
    },[show]);

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="formulario") {
            setFormulario({...formulario, [name]: valor});
        }
    }

    function fecharModal() {
        setShowModal(false);
        fechar(false);
    }

    async function enviarSolID() {
        setEnviando(true);
        const formData = new FormData();
        formData.set('op','enviar');
        formData.set('nome',formulario.nome || '');
        formData.set('email',formulario.email || '');
        formData.set('fone',formulario.fone || '');
        formData.set('instrutor',formulario.instrutor || '');
        formData.set('parapente',parapente || '');
        formData.set('tamanho',formulario.tamanho || '');
        formData.set('finalidade',formulario.finalidade || '');
        formData.set('observacoes',formulario.observacoes || '');
        formData.set('imgtop',imgtop || '');
        formData.set('imgbottom',imgbottom || '');
        formData.set('corest',corestop || '');
        formData.set('coresb',coresbottom || '');
        try {
            const response = await Api.post('solid.php',formData);
            if (response.data.erro==='N') {
                Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: true, confirmButtonColor: '#000'});
                fecharModal(false);
            } else {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#000'});
            }
            setEnviando(false);
        } catch (error) {
            console.log('CATCH',error);
            setEnviando(false);
        }
    }

    return <>
        <Modal show={showModal} fullscreen={false} backdrop="static" size="lg">
            <Modal.Header className="bg-color2" >
                <Modal.Title><h5 className="modal-title">{T(idioma,"Envie sua cor personalizada!")}</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{T(idioma,"Preencha os campos abaixo para enviar a cor personalizada que você escolheu")}.</p>
                <div className="row mb-3">
                    <div className="col-6 d-flex justify-content-center align-items-center">
                        <img src={imgtop} alt="" style={{maxWidth: '200px'}}/>
                    </div>
                    <div className="col-6 d-flex justify-content-center align-items-center">
                        <img src={imgbottom} alt="" style={{maxWidth: '200px'}}/>
                    </div>
                </div>
                <div className="row gx-2 p-inputtext-sm pett-input">
                    <div className="col-lg-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="nome" value={formulario.nome} onChange={(e) => setCampo(e,'formulario',false)} />
                            <label htmlFor="nome">{T(idioma,"NOME COMPLETO")}</label>
                        </span>
                    </div>
                    <div className="col-lg-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="email" value={formulario.email} onChange={(e) => setCampo(e,'formulario',false)} />
                            <label htmlFor="email">EMAIL</label>
                        </span>
                    </div>
                    <div className="col-lg-6 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="fone" value={formulario.fone} onChange={(e) => setCampo(e,'formulario',false)} />
                            <label htmlFor="fone">{T(idioma,"FONE / WHATSAPP")}</label>
                        </span>
                    </div>
                    <div className="col-lg-6 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="instrutor" value={formulario.instrutor} onChange={(e) => setCampo(e,'formulario',false)} />
                            <label htmlFor="instrutor">{T(idioma,"ESCOLA/INSTRUTOR")}</label>
                        </span>
                    </div>
                    <div className="col-lg-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="tamanho" value={formulario.tamanho} options={tamanhos} onChange={(e) => setCampo(e,'formulario',false)} placeholder="Selecione"/>
                            <label htmlFor="tamanho">{T(idioma,"TAMANHO")}</label>
                        </span>
                    </div>
                    <div className="col-lg-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="finalidade" value={formulario.finalidade} options={finalidade} onChange={(e) => setCampo(e,'formulario',false)} placeholder="Selecione"/>
                            <label htmlFor="finalidade">{T(idioma,"FINALIDADE")}</label>
                        </span>
                    </div>
                    <div className="col-lg-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputTextarea className="w-100" name="observacoes" value={formulario.observacoes} onChange={(e) => setCampo(e,'formulario',false)} autoResize/>
                            <label htmlFor="observacoes">{T(idioma,"OBSERVAÇÕES")}</label>
                        </span>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-color2 m-0 p-0'>
                <div className="btn-group w-100">
                    { enviando 
                        ?<button className="btn btn-lg btn-success disabled"><i className="fa fa-fw fa-spin fa-spinner"></i> {T(idioma,"Enviando...")}</button>
                        :<button className="btn btn-lg btn-success" onClick={() => enviarSolID()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"Enviar")}</button>
                    }
                    <button className="btn btn-lg btn-danger" onClick={() => fecharModal(false)}><i className="fa fa-fw fa-cancel"></i> {T(idioma,"Cancelar")}</button>
                </div>
            </Modal.Footer>
        </Modal>
    </>
}

export default FormSolid;