import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// funcoes proprias.
import { LinguaContext } from '../Contextos/Linguas';
import Api from '../Api';
import BarraSuperior from '../Componentes/BarraSuperior';
import Cabecalho from '../Componentes/Cabecalho';
import ColunaCentral from '../Componentes/ColunaCentral';
import IfComponent from '../Componentes/IfComponent';
import Rodape from '../Componentes/Rodape';
import T from '../Traducoes';

function Pagina() {
    const { id } = useParams();
    const { idioma } = useContext(LinguaContext);
    const [ encontrado, setEncontrado ] = useState(true);
    const [ bloco, setBloco ] = useState({BLO_CODIGO: 0, BLO_CONTEUDO: '', BLO_MOSTRARTITULO: 'N', BLO_NOME: '', BLO_PAGINA: ''});

    function verBloco() {
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', id);
        formData.set('idioma', idioma);
        
        Api.post('paginas.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setBloco(response.data.msg);
                setEncontrado(true);
            } else {
                setEncontrado(false);
                //Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});            
        });            
    }
    
    useEffect(() => {
        verBloco();    
        // eslint-disable-next-line
    },[id]);
    
    return <>
        <BarraSuperior />
        
        <IfComponent condicional={encontrado===false}>
            <ColunaCentral left="false" right="false" className="container space-top">
                <p>{T(idioma,"Conteúdo não encontrado!")}</p>
            </ColunaCentral>
        </IfComponent>

        <IfComponent condicional={encontrado===true}>

            <IfComponent condicional={bloco.BLO_PAGINA==="S"}>

                <IfComponent condicional={bloco.BLO_IMAGEMDESTAQUE!==""}>
                    <img className="img-fluid space-top" src={bloco.BLO_IMAGEMDESTAQUE} alt=""/>
                    <ColunaCentral left="false" right="false" className="container">
                        <IfComponent condicional={bloco.BLO_MOSTRARTITULO==="S"}>
                            <Cabecalho className="mb-3" titulo={bloco.BLO_NOME} texto=""/>
                        </IfComponent>
                        <section className="mb-5" dangerouslySetInnerHTML={{ __html: bloco.BLO_CONTEUDO }} />
                    </ColunaCentral>
                </IfComponent>

                <IfComponent condicional={bloco.BLO_IMAGEMDESTAQUE===""}>
                    <ColunaCentral left="false" right="false" className="container space-top">
                        <IfComponent condicional={bloco.BLO_MOSTRARTITULO==="S"}>
                            <Cabecalho className="mb-3" titulo={bloco.BLO_NOME} texto=""/>
                        </IfComponent>
                        <section className="mb-5" dangerouslySetInnerHTML={{ __html: bloco.BLO_CONTEUDO }} />
                    </ColunaCentral>
                </IfComponent>

            </IfComponent>

            <IfComponent condicional={bloco.BLO_PAGINA==="N"}>
                <ColunaCentral left="false" right="false" className="container space-top">
                    <p>{T(idioma,"Página não disponível!")}</p>
                </ColunaCentral>
            </IfComponent>

        </IfComponent>

        <Rodape />
    </>
}

export default Pagina; 