import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';

import Api from '../../../Api';
import { AuthContext } from '../../../Contextos/Auth';
import { LinguaContext } from '../../../Contextos/Linguas';
import { UnidadeContext } from '../../../Contextos/Unidades';
import BarraSuperior from '../../../Componentes/BarraSuperior';
import Cabecalho from '../../../Componentes/Cabecalho';
import ColunaCentral from '../../../Componentes/ColunaCentral';
import IfComponent from '../../../Componentes/IfComponent';
import Rodape from '../../../Componentes/Rodape';
import './stylesnestable.css';

function MenuDrop() {
    const { logout } = useContext(AuthContext);
    const { unidade } = useContext(UnidadeContext);
    const { idioma } = useContext(LinguaContext);
    const [ collapsed, setCollapsed ] = useState(false);
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    // const [ pais, setPais ] = useState([]);
    const [ blocos, setBlocos ] = useState([]);
    const [ unidades, setUnidades ] = useState([]);
    const [ menu, setMenu ] = useState({
        MEN_CODIGO: 0,
        MEN_DESCRICAOBR: '',
        MEN_DESCRICAOEN: '',
        MEN_DESCRICAODE: '',
        MEN_ORDEM: 0
    });
    const [ operacao, setOperacao ] = useState('listar');

    const SelectItemsSIMNAO = [
        {value: "S", label: "SIM" },
        {value: "N", label: "NÃO" }
    ];    
    
    const SelectItemsTARGET = [
        {value: "_self", label: "Abrir na mesma janela" },
        {value: "_blank", label: "Abrir em nova janela" }
    ];  

    const getBlocos = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combomenu');
        Api.post('admin/blocos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setBlocos(response.data.msg);
            }
        });
    },[]);

    const getUnidades = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combo');
        Api.post('unidades.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setUnidades(response.data.msg);
            }
        });
    },[]);

    // const getPais = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','combopais');
    //     Api.post('admin/menusdrop.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setPais(response.data.msg);
    //         }
    //     });
    // },[]);    

    useEffect(() => {
        getBlocos();
        getUnidades();
        // getPais();
    },[getBlocos,getUnidades]);

    function listar() {
        setCarregando(true);    
                    
        var formData = new FormData();
        formData.set('op', 'listar');
        formData.set('unidade', unidade);
        formData.set('idioma', idioma);
        
        Api.post('admin/menusdrop.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setListagem(response.data.msg);
                setOperacao('listar');
                setCarregando(false);
            } else {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            setCarregando(false);
        });
    }
   
    useEffect(() => {
        listar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    function cancelar() {
        setOperacao('listar');
    }

    function editar(codigo) {
        setOperacao('atualizar');
        var formData = new FormData();
        formData.set('op', 'ver');
        formData.set('codigo', codigo);
              
        Api.post('admin/menusdrop.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setMenu(response.data.msg);
            } else {
                setOperacao('listar');
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH: Editar Menu');
        });        
    }
    
    function excluir(item) {
        Swal.fire({
            position: 'center',
            icon: 'question',
            title: 'Confirma Exclusão?',
            text: 'Tem certeza que deseja excluir '+item.MEN_DESCRICAO+'?',
            showCancelButton: true,
            confirmButtonText: 'Sim, quero!',
            cancelButtonText: 'Nãããoooooo',
            confirmButtonColor: '#212529'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.set('op','excluir');
                formData.set('codigo',item.MEN_CODIGO);
                Api.post('admin/menusdrop.php',formData).then((response) => {
                    if (response.data.erro==='N') {
                        Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
                        listar();
                    } else {
                        response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                    }
                }).catch(({response}) => {
                    console.log('CATCH: '+JSON.stringify(response));
                });
            }
        });
    }
    
    function novo() {
        const formData = new FormData();
        formData.set('op','novo');
        formData.set('unidade',unidade);
        Api.post('admin/menusdrop.php',formData).then((response) => {
            if (response.data.erro==='N') {                 
                setMenu(response.data.msg);
                setOperacao('atualizar');
            } else {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        });
    }    

    function salvar() {
        const formData = new FormData();
        formData.set('op','atualizar');
        formData.set('MEN_CODIGO',menu.MEN_CODIGO);
        formData.set('MEN_DESCRICAOBR',menu.MEN_DESCRICAOBR);
        formData.set('MEN_DESCRICAOEN',menu.MEN_DESCRICAOEN);
        formData.set('MEN_DESCRICAODE',menu.MEN_DESCRICAODE);
        formData.set('MEN_DROP',menu.MEN_DROP);
        formData.set('MEN_ICONE',menu.MEN_ICONE);
        formData.set('MEN_LINK',menu.MEN_LINK);
        formData.set('MEN_MENUTITLE',menu.MEN_MENUTITLE);
        formData.set('MEN_PAI',menu.MEN_PAI);
        formData.set('MEN_TARGET',menu.MEN_TARGET);
        formData.set('MEN_UNIDADE',menu.MEN_UNIDADE);
        formData.set('MEN_VISIBLE',menu.MEN_VISIBLE);
        Api.post('admin/menusdrop.php',formData).then((response) => {
            if (response.data.erro==='N') {                 
                listar();
                Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            } else {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        });
    }
    
    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="menu") {
            setMenu({...menu, [name]: valor});
        }
    }
    
    //exemplo de objeto que o nestable utiliza.
    // const items = [
    //     { id: 0, text: 'Andy' },
    //     {
    //       id: 1, text: 'Harry',
    //       children: [
    //         { id: 2, text: 'David' }
    //       ]
    //     },
    //     { id: 3, text: 'Lisa' }
    // ];

    const renderItem = ({ item, collapseIcon, handler }) => {
        return <>
            <div className="d-flex justify-content-between">
                <div className="bg-secondary rounded-start p-1">
                    <span className="" style={{cursor: 'pointer', color: '#fff'}}>{handler}</span>
                </div>
                <div className="bg-secondary bg-opacity-25 rounded-end p-1 w-100">
                    <span>{collapseIcon}</span>&nbsp;
                    {item.MEN_VISIBLE==="S"?<i className="fa fa-eye"></i>:<i className="fa fa-eye-slash"></i>}
                    <Link to="" onClick={ () => editar(item.MEN_CODIGO) }><i className="fa fa-pencil-alt mx-1"></i></Link>
                    <Link to="" onClick={ () => excluir(item) }><i className="fa fa-trash me-2"></i></Link>
                    {item.MEN_DESCRICAO}
                </div>
            </div>
        </>
    };

    const collapseIcon = (e) => {
        return e.isCollapsed
            ? <i className="fa fa-fw fa-plus-square fs-6"></i>
            : <i className="fa fa-fw fa-minus-square fs-6"></i>
    }

    const handleUpdate = (newList) => {
        setListagem(newList.items);
    }

    const colapsarTodos = () => {
        setCollapsed(true)
    }

    const expandirTodos = () => {
        setCollapsed(false);
    }

    async function salvarOrdenacao() {
        const formData = new FormData();
        formData.set('op','ordenar');
        formData.set('json',JSON.stringify(listagem));
        const response = await Api.post('admin/menusdrop.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <Cabecalho titulo="Cadastro de Menu" texto=""/>
            <IfComponent condicional={operacao==="inserir" || operacao==="atualizar"}>
                <div className="row mt-5 mb-3 gx-2 p-inputtext-sm pett-input">
                    <div className="col-lg-3 col-md-3 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="50" name="MEN_DESCRICAOBR" value={menu.MEN_DESCRICAOBR || ''} onChange={(e) => setCampo(e,'menu',false)} />
                            <label htmlFor="MEN_DESCRICAOBR">DESCRIÇÃO BR</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="50" name="MEN_DESCRICAOEN" value={menu.MEN_DESCRICAOEN || ''} onChange={(e) => setCampo(e,'menu',false)} />
                            <label htmlFor="MEN_DESCRICAOEN">DESCRIÇÃO EN</label>
                        </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="50" name="MEN_DESCRICAODE" value={menu.MEN_DESCRICAODE || ''} onChange={(e) => setCampo(e,'menu',false)} />
                            <label htmlFor="MEN_DESCRICAODE">DESCRIÇÃO DE</label>
                        </span>
                    </div>
                    {/* <div className="col-lg-3 col-md-3 col-sm-12 form-floating mb-3">
                        <span className="p-float-label pett-label">                            
                            <Dropdown className="w-100" name="MEN_BLOCO" value={menu.MEN_BLOCO || ''} options={blocos} onChange={(e) => setCampo(e,'menu',false)} placeholder="Selecione"/>
                            <label htmlFor="MEN_BLOCO">BLOCO</label>
                        </span>
                    </div> */}
                    <div className="col-lg-3 col-md-3 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MEN_LINK" value={menu.MEN_LINK || ''} options={blocos} onChange={(e) => setCampo(e,'menu',false)} placeholder="Selecione ou digite" editable/>
                            <label htmlFor="MEN_LINK">LINK</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MEN_TARGET" value={menu.MEN_TARGET || ''} options={SelectItemsTARGET} onChange={(e) => setCampo(e,'menu',false)} placeholder="Selecione"/>
                            <label htmlFor="MEN_TARGET">TARGET</label>
                        </span>
                    </div>
                    {/* <div className="col-lg-2 col-md-2 col-sm-12 form-floating mb-3">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MEN_PAI" value={String(menu.MEN_PAI) || ''} options={pais} onChange={(e) => setCampo(e,'menu',false)} placeholder="Selecione"/>
                            <label htmlFor="MEN_PAI">MENU PAI</label>
                        </span>
                    </div> */}
                    <div className="col-lg-2 col-md-2 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MEN_MENUTITLE" value={String(menu.MEN_MENUTITLE) || ''} options={SelectItemsSIMNAO} onChange={(e) => setCampo(e,'menu',false)} placeholder="Selecione"/>
                            <label htmlFor="MEN_MENUTITLE">TÍTULO DE MENU</label>
                        </span>
                    </div>
                    {/* <div className="col-lg-2 col-md-2 col-sm-12 form-floating mb-3">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MEN_DROP" value={String(menu.MEN_DROP) || ''} options={SelectItemsSIMNAO} onChange={(e) => setCampo(e,'menu',false)} placeholder="Selecione"/>
                            <label htmlFor="MEN_DROP">DROPDOWN</label>
                        </span>
                    </div> */}
                    <div className="col-lg-2 col-md-2 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MEN_VISIBLE" value={String(menu.MEN_VISIBLE) || ''} options={SelectItemsSIMNAO} onChange={(e) => setCampo(e,'menu',false)} placeholder="Selecione"/>
                            <label htmlFor="MEN_VISIBLE">VISÍVEL</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <Dropdown className="w-100" name="MEN_UNIDADE" value={String(menu.MEN_UNIDADE) || ''} options={unidades} onChange={(e) => setCampo(e,'menu',false)} placeholder="Selecione"/>
                            <label htmlFor="MEN_UNIDADE">UNIDADE</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="60" name="MEN_ICONE" value={menu.MEN_ICONE || ''} onChange={(e) => setCampo(e,'menu',false)}/>
                            <label htmlFor="MEN_ICONE">ÍCONE</label>
                        </span>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12 form-floating mb-2">
                        <span className="p-float-label pett-label">
                            <InputText className="w-100" maxLength="120" name="MEN_CODIGO" value={menu.MEN_CODIGO || ''} disabled/>
                            <label htmlFor="MEN_CODIGO">CÓDIGO</label>
                        </span>
                    </div>
                </div>
                <div className="box shadow-sm mt-3 mb-4">
                    <button className="btn btn-success me-2" onClick={() => salvar()}><i className="fa fas fa-save"></i> Salvar</button>
                    <button className="btn btn-secondary" onClick={() => cancelar()}><i className="fa fas fa-cancel"></i> Cancelar</button>
                </div>
                <div className="mb-5"></div>
            </IfComponent>

            <IfComponent condicional={operacao==="listar" || operacao===""}>
                <div className="box shadow-sm d-flex justify-content-between mt-3">
                    <button className="btn btn-secondary me-2" onClick={ () => colapsarTodos()}><i className="fa fa-fw fa-compress-alt"></i> Colapsar</button>
                    <button className="btn btn-secondary me-2" onClick={ () => expandirTodos()}><i className="fa fa-fw fa-expand"></i> Expandir</button>
                    <button className="btn btn-success me-2" onClick={ () => salvarOrdenacao()}><i className="fa fa-fw fa-save"></i> Salvar</button>
                    <button className="btn btn-success ms-auto" type="button" onClick={ () => novo() }><i className="fa fas fa-plus"></i> Novo Item</button>
                </div>
                <Nestable 
                    className="bg-secondary bg-opacity-10 p-2 mb-4 box shadow-sm" 
                    idProp="MEN_CODIGO" 
                    childrenProp="MEN_FILHOS" 
                    items={listagem} 
                    renderItem={renderItem} 
                    renderCollapseIcon={ (e) => collapseIcon(e) } 
                    collapsed={collapsed} 
                    // handler="≡"
                    handler={<i className="fa fa-fw fa-bars"></i>}
                    onChange={handleUpdate}
                />  
            </IfComponent>
            
            <IfComponent condicional={ listagem.length === 0 && carregando === false }>
                <div className="container d-flex justify-content-center align-items-center">
                    <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-note-sticky"></i></span><br/>Nenhum menu encontrado!</p>
                </div>                    
            </IfComponent>
        </ColunaCentral>
        <Rodape/>
    </>
}

export default MenuDrop;