import React from 'react';

function PaletaAdesivos(props) {

    // eslint-disable-next-line no-unused-vars
    const Paleta01 = { 
        colors: [  
            { RGB: "#000000", src: "/midias/solid/logo_p.png", alt: "P" },
            { RGB: "#FF0000", src: "/midias/solid/logo_v.png", alt: "V"  },
            { RGB: "transparent", src: "/midias/solid/logo_x.png", alt: "X" },
        ]
    }

    function cliqueCor(event,cor) {
        event.preventDefault();
        props.clique(cor);
    }

    return <>
        {
            // eslint-disable-next-line no-eval
            eval(props.paleta).colors.map((item,idx) => (
                <img className={props.selecionada===item.RGB?"img-thumbnail me-2 adesivo adesivo-selecionado":"img-thumbnail me-2 adesivo"} src={item.src} onClick={(e) => cliqueCor(e,item.RGB) } alt="v" style={{maxWidth: '60px'}} key={idx}/>
            ))
        }
    </>
}

export default PaletaAdesivos;