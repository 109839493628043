import React, { useContext, useEffect, useRef, useState } from 'react';
import * as htmlToImage from 'html-to-image';

import { LinguaContext } from '../../Contextos/Linguas';
import BarraSuperior from '../../Componentes/BarraSuperior';
import Bloco from '../../Componentes/Bloco';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Cores from './Cores.js';
import FormSolid from '../../Componentes/FormSolid';
import PaletaAdesivos from '../../Componentes/PaletaAdesivos';
import PaletaCores from '../../Componentes/PaletaCores';
import Rodape from '../../Componentes/Rodape';
import T from '../../Traducoes';

function Flexus2() {
    const { idioma } = useContext(LinguaContext);
    const svg_top = useRef(null);
    const svg_bottom = useRef(null);
    const parapente = 'Flexus 2';
    const [ imgtop, setImgtop ] = useState('');
    const [ imgbottom, setImgbottom ] = useState('');
    const [ coresT, setCoresT ] = useState('');
    const [ coresB, setCoresB ] = useState('');
    const [ cort, setCort ] = useState('#FFFFFF');
    const [ corb, setCorb ] = useState('#FFFFFF');
    const [ cor1, setCor1 ] = useState('#FFFFFF');
    const [ cor2, setCor2 ] = useState('#FFFFFF');
    const [ cor3, setCor3 ] = useState('#FFFFFF');
    const [ cor4, setCor4 ] = useState('#FFFFFF');
    const [ cor5, setCor5 ] = useState('#FFFFFF');
    const [ logot, setLogot ] = useState('transparent');
    const [ logob, setLogob ] = useState('transparent');
    const [ showModal, setShowModal ] = useState(false);
    const tamanhos = [
        {value: "XXS", label: "XXS" },
        {value: "XS", label: "XS" },
        {value: "S", label: "S" },
        {value: "M", label: "M" },
        {value: "L", label: "L" },
        {value: "XL", label: "XL" },
        {value: "XXL", label: "XXL" },
    ];
    
    function padrao1() {
        setCort('#F00008');
        setCorb('#F00008');
        setCor1('#FFFFFF');
        setCor2('#F00008');
        setCor3('#FFFFFF');
        setCor4('#FFFFFF');
        setCor5('#FFFFFF');    
    }
        
    function padrao2() {
        setCort('#FBE111');
        setCorb('#FBE111');
        setCor1('#191D24');
        setCor2('#FBE111');
        setCor3('#191D24');
        setCor4('#191D24');
        setCor5('#191D24'); 
    }

    function padrao3() {
        setCort('#1F4194');
        setCorb('#1F4194');
        setCor1('#FBE111');
        setCor2('#1F4194');
        setCor3('#FBE111');
        setCor4('#FBE111');
        setCor5('#FBE111');  
    }

    useEffect(() => {
        padrao1();
    },[]);

    function gerarImagem() {
        htmlToImage.toPng(svg_top.current)
        .then(function (dataURL) {
            setImgtop(dataURL);
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
        
        htmlToImage.toPng(svg_bottom.current)
        .then(function (dataURL) {
            setImgbottom(dataURL)
        })
        .catch(function (error) {
            console.log('CATCH',error);
        });
    }

    function abrirModalEnviar() {
        gerarImagem();
        let lt="";
        if (logot==="transparent") lt="NENHUM / NO LOGO";
        if (logot==="#000000") lt="Preto / Black";
        if (logot==="#FF0000") lt="Vermelho / Red";

        let lb="";
        if (logob==="transparent") lb="NENHUM / NO LOGO";
        if (logob==="#000000") lb="Preto / Black";
        if (logob==="#FF0000") lb="Vermelho / Red";

        const novasCoresTop = 
        'Top: '+Cores[cort].descricao+'\n'+
        'Design 01: '+Cores[cor1].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Design 03: '+Cores[cor3].descricao+'\n'+
        'Logo: '+lt+'\n\r';

        const novasCoresBottom = 
        'Bottom: '+Cores[corb].descricao+'\n'+
        'Design 01: '+Cores[cor4].descricao+'\n'+
        'Design 02: '+Cores[cor2].descricao+'\n'+
        'Design 03: '+Cores[cor5].descricao+'\n'+
        'Logo: '+lb+'\n';

        setCoresT(novasCoresTop);
        setCoresB(novasCoresBottom);

        setShowModal(true);
    }

    return <>
        <BarraSuperior/>
        <Bloco className="container" id="203"/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <div className="row mb-4">

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="w-100 d-flex justify-content-right mb-3 overflow-auto">
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/flexus_two_padrao1.jpg" onClick={() => padrao1()} alt="1" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/flexus_two_padrao2.jpg" onClick={() => padrao2()} alt="2" style={{maxWidth: '70px'}}/>
                        <img className="img-fluid img-thumbnail me-1" src="/midias/solid/flexus_two_padrao3.jpg" onClick={() => padrao3()} alt="3" style={{maxWidth: '70px'}}/>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 376.92 210.33" ref={svg_top}>
                        <g stroke="#000000" strokeWidth="0.5" fill={cort}>
                            <path d="M375.77 67.96c-0.51,1.11 -1.42,1.97 -2.53,2.48 -18.9,8.63 -210.72,95.96 -312.67,133.16 0,0 -34.27,14.37 -49.2,0.48 0,0 -4.61,-4.75 -10.33,-20.6 -1.21,-3.33 -1.09,-7 0.34,-10.23 8.84,-20.06 48.11,-95.25 173.87,-150.85 4.02,-1.78 46.08,-18.02 81.85,-21.1 45.54,-3.9 64.24,2.99 78.55,15.33 12.91,11.14 36.01,29.97 40.45,43.27 0.87,2.61 0.84,5.46 -0.29,7.97l-0.04 0.09 0 0z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor1}>
                            <path d="M370.15 71.85c-3.63,1.66 -9.33,4.24 -16.69,7.57 -7.8,-2.73 -18.03,-6.87 -30.95,-12.22 -46.22,-19.14 -74.49,10.24 -74.49,10.24 32.81,-36.54 92.52,-17.95 101.26,-13.45 4.35,2.24 13.14,5.33 20.87,7.86l0 0z"/>
                            <path d="M222.29 86.31c0,0 -44,0.13 -62.15,49.97 -5.09,13.96 -9.18,24.93 -12.65,32.98 -6.15,2.55 -12.24,5.06 -18.25,7.52 3.6,-7.9 7.58,-17.12 8.95,-22.19 2.72,-10.09 31.09,-69.93 84.1,-68.28z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M349.28 63.99c-8.6,-4.43 -66.51,-22.48 -99.63,11.7 7.93,-11.08 26.7,-28.43 66.56,-28.74 19.9,-0.15 35.53,0.65 50.76,-1.17 4.33,4.99 7.7,9.84 9.13,14.12 0.87,2.6 0.84,5.46 -0.29,7.97 -0.01,0.03 -0.03,0.06 -0.04,0.09 -0.51,1.11 -1.43,1.97 -2.53,2.48 -0.76,0.35 -1.8,0.82 -3.09,1.41 -7.73,-2.53 -16.52,-5.61 -20.87,-7.86l0 0z"/>
                            <path d="M1.85 172.2l7.16 17.26c90.1,-10.3 100.92,-32.52 139.48,-73.01 29.11,-30.56 56.6,-32.24 71.21,-30.18 -51.35,0.34 -78.84,58.4 -81.52,68.31 -1.37,5.08 -5.34,14.3 -8.94,22.2 -25.04,10.22 -48.59,19.49 -68.67,26.82 0,0 -22.45,9.4 -38.93,5.62l0 0.01c-0.01,-0.01 -0.03,-0.01 -0.04,-0.02 -0.01,0 -0.02,0 -0.03,0 0,0 0,0 0,-0.01 -3.85,-0.89 -7.38,-2.5 -10.2,-5.13 0,0 -4.61,-4.75 -10.33,-20.6 -1.21,-3.33 -1.1,-7 0.33,-10.24 0.04,-0.08 0.48,-1.03 0.48,-1.03z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor3}>
                            <path d="M316.21 46.95c-16.09,0.12 -28.74,3.03 -38.64,7.18 3.64,-1.73 7.96,-3.62 12.55,-5.28 0.07,-0.03 0.15,-0.06 0.22,-0.08 0.55,-0.2 1.11,-0.4 1.67,-0.59 5.96,-1.99 12.02,-3.03 17.18,-3.56 2.62,-0.27 5.25,-0.38 7.87,-0.36 1.74,0.02 3.75,0.01 6.01,-0.05 0,0 0.01,0 0.01,0l0 0c9.54,-0.25 23.36,-1.32 38.28,-4.45 1.99,2.01 3.88,4.03 5.61,6.02 -15.23,1.82 -30.86,1.02 -50.76,1.17l0 0z"/>
                            <path d="M1.85 172.2c1.05,-2.37 2.18,-4.74 4.13,-8.44 55.12,26.56 113.3,-21.75 135.34,-44.02l0 0c0,0 0.01,-0.01 0.01,-0.01 1.68,-1.7 3.15,-3.25 4.39,-4.6 1.89,-2.05 3.88,-3.99 5.99,-5.81 4.17,-3.6 9.39,-7.51 15.3,-10.64 0.55,-0.3 1.11,-0.58 1.67,-0.86 0.08,-0.04 0.15,-0.08 0.23,-0.12 4.65,-2.31 9.29,-4.25 13.31,-5.79 -10.49,4.58 -21.97,12.21 -33.73,24.55 -38.56,40.48 -49.38,62.7 -139.48,73l-7.16 -17.26z"/>
                        </g>
                        <g fill={logot}>
                            <path d="M209.67 66.03c-5.27,-0.16 -10.33,0.93 -9.24,5.1 1.18,4.46 10.15,8.04 21.35,3.27 2.31,-0.98 5.06,-2.5 6.78,-3.81 0.06,-0.06 0.08,-0.07 0.07,-0.08 0,-0.02 -0.04,0 -0.1,0.03 -2.21,1.09 -4.33,2.03 -6.64,2.72 -7.48,2.25 -13.94,1.27 -14.39,-0.77 -0.35,-1.56 2.75,-3.02 7.13,-3.48 2.63,-0.27 5.47,-0.12 8.59,0.01 7.64,0.32 16.27,-2.43 19.01,-10.46 1.33,-3.91 -0.47,-5.46 -3.3,-3.01 -2.16,1.86 -3.22,4.88 -4.34,7.42 -0.66,1.51 -1.32,2.74 -2.48,4.06 -0.04,0.04 -0.06,0.06 -0.04,0.07 0,0.01 0.04,-0.02 0.12,-0.08 1.69,-1.37 2.79,-3.19 3.64,-4.6 0.42,-0.71 0.94,-1.31 1.59,-1.86 1.84,-1.54 2.9,-1.11 1.94,0.88 -0.65,1.34 -2,3.1 -4.37,4.61 -0.2,0.13 -0.42,0.26 -0.64,0.39l0.02 -0.02c0,0 -0.04,0.02 -0.12,0.07 -7.72,4.32 -16.39,-0.21 -24.58,-0.46l0 0z"/>
                        </g>
                        <g stroke="#000000" strokeWidth="0.3" fill="transparent">
                            <path d="M229.65 134.32c-0.17,-3.56 -0.49,-9.02 -1.25,-12.43 -1.73,-7.84 -6.27,-14.93 -10.89,-21.78 -6.58,-9.75 -13.32,-18.99 -20.15,-28.25 -10.87,-14.73 -22.12,-29.64 -33.24,-44.38"/>
                            <path d="M242.34 128.8c-0.5,-5.65 -1.14,-12.84 -2.94,-18.01 -2.25,-6.47 -6.54,-12.2 -10.74,-17.91 -13.14,-17.86 -25.38,-35.53 -38.67,-52.68 -4.74,-6.12 -9.41,-11.94 -14.32,-17.98"/>
                            <path d="M253.8 123.79c0.16,-2.44 0.32,-6.75 0.08,-9.13 -0.65,-6.24 -3.65,-12.14 -6.92,-17.74 -6.83,-11.71 -14.79,-22.15 -22.81,-32.62 -12.2,-15.93 -23.59,-30.72 -35.95,-46.75"/>
                            <path d="M265.93 118.47c-0.1,-4.22 -0.31,-10.14 -1.23,-14.14 -1.74,-7.5 -6.14,-14.3 -10.64,-20.87 -10.33,-15.12 -21.2,-29.07 -32.42,-42.94 -7.78,-9.62 -14.05,-17.18 -22.04,-26.75"/>
                            <path d="M275.64 114.19c0.51,-1.2 1.71,-4.26 1.93,-5.5 0.67,-3.72 -0.67,-7.68 -2.22,-11.41 -3.14,-7.59 -7.13,-14.23 -11.45,-20.72 -11.59,-17.45 -25.54,-33.81 -39.17,-50.17 -5.17,-6.21 -8.24,-9.91 -13.34,-16.12"/>
                            <path d="M217.35 139.65c-0.93,-4.98 -2.19,-11.7 -3.87,-16.4 -2.49,-6.96 -6.63,-13.3 -10.82,-19.57 -16.14,-24.14 -33.23,-47.55 -50.13,-70.58"/>
                            <path d="M28.98 210.11c-2.56,-1.92 -7,-5.37 -8.92,-7.8 -2.25,-2.87 -3.68,-6.4 -5.03,-9.91 -2.5,-6.49 -4.73,-12.91 -7.04,-19.36 -1.16,-3.25 -1.61,-4.54 -2.78,-7.81"/>
                            <path d="M36.36 209.79c-2.48,-1.79 -7.23,-4.89 -8.91,-6.68 -1.62,-1.71 -2.75,-3.87 -3.83,-6.03 -2.69,-5.39 -5.03,-10.8 -7.15,-16.32 -3.01,-7.82 -5,-14.09 -7.72,-22.07"/>
                            <path d="M42.42 208.89c-2.58,-2.37 -7.38,-6.52 -9.53,-9.24 -3.21,-4.08 -5.06,-9.15 -7.02,-14.09 -3.27,-8.23 -6.83,-16.1 -9.88,-24.26 -1.31,-3.53 -2.06,-5.71 -3.27,-9.3"/>
                            <path d="M51.83 206.61c-3.46,-2.94 -8.79,-7.48 -11.55,-10.96 -4.24,-5.35 -6.8,-11.98 -9.32,-18.5 -3.45,-8.96 -6.81,-17.72 -10.14,-26.58 -0.99,-2.62 -1.64,-4.34 -2.62,-6.98"/>
                            <path d="M60.77 203.53c-3.77,-3.37 -8.93,-8 -11.93,-11.96 -3.35,-4.41 -5.75,-9.55 -8.05,-14.7 -6.2,-13.85 -11.5,-27.28 -16.93,-41.22"/>
                            <path d="M71.47 199.56c-2.99,-2.42 -7.21,-5.88 -9.7,-8.56 -4.26,-4.59 -7.39,-10.21 -10.23,-15.89 -3.86,-7.7 -7.2,-15.51 -10.51,-23.36 -3.49,-8.27 -6.91,-16.54 -10.35,-24.87"/>
                            <path d="M82.28 195.45c-3.06,-2.47 -7.56,-6.3 -10.1,-9.24 -2.31,-2.7 -4.21,-5.76 -5.97,-8.89 -4.04,-7.15 -7.37,-14.58 -10.79,-21.99 -5.65,-12.2 -11.72,-24.75 -17.65,-36.83"/>
                            <path d="M93.31 191.17c-3.16,-2.69 -7.98,-6.87 -10.51,-10.06 -3.09,-3.89 -5.26,-8.5 -7.56,-13.01 -4.07,-8.04 -8.51,-15.75 -12.65,-23.64 -5.79,-11.01 -11.51,-23.46 -16.65,-34.86"/>
                            <path d="M104.35 186.82c-2.01,-2.26 -5.36,-6.2 -7.24,-8.56 -4.51,-5.64 -8.35,-11.82 -11.95,-18.1 -6.62,-11.58 -12.41,-23.56 -18.18,-35.56 -3.61,-7.52 -8.11,-16.89 -11.7,-24.41"/>
                            <path d="M116.58 181.92c-2.98,-4.08 -7.25,-9.81 -10.03,-14.01 -6.22,-9.42 -11.52,-19.42 -16.95,-29.33 -5.79,-10.54 -11.73,-20.98 -17.59,-31.46 -2.33,-4.15 -5.89,-10.6 -8.21,-14.76"/>
                            <path d="M128.49 177.09c-2.83,-4.26 -6.85,-10.07 -9.56,-14.4 -6.2,-9.87 -11.82,-20.09 -17.52,-30.27 -7.67,-13.73 -15.47,-27.37 -23.35,-40.97 -0.96,-1.67 -3.41,-5.92 -4.38,-7.59"/>
                            <path d="M141.26 171.84c-1.84,-3.28 -4.6,-8.09 -6.46,-11.35 -7.54,-13.19 -15.53,-26.13 -23.42,-39.11 -8.73,-14.37 -18.84,-31.26 -27.44,-45.69"/>
                            <path d="M153.57 166.74c-1.79,-3.84 -4.29,-9.24 -6.21,-13.01 -5.71,-11.23 -12.55,-21.86 -19.29,-32.53 -10.82,-17.13 -22.63,-36.37 -33.18,-53.61"/>
                            <path d="M165.89 161.58c-1.2,-3.52 -2.96,-8.64 -4.4,-12.05 -4.52,-10.74 -11.25,-20.45 -17.77,-30.24 -12.77,-19.17 -25.59,-39.99 -37.52,-59.47"/>
                            <path d="M178.68 156.19c-1.65,-5.22 -3.83,-12.08 -6.05,-17.03 -3.8,-8.44 -9.26,-16.1 -14.43,-23.89 -7.08,-10.62 -13.63,-21.47 -20.42,-32.2 -6.37,-10.05 -13.42,-20.7 -20.01,-30.64"/>
                            <path d="M191.55 150.72c-0.62,-2.98 -1.6,-7.73 -2.51,-10.62 -1.78,-5.68 -4.65,-11 -7.7,-16.17 -4.33,-7.32 -9.03,-14.32 -13.7,-21.36 -11.31,-17.02 -22.46,-34.21 -34.07,-51.07 -1.3,-1.89 -2.8,-4.13 -4.11,-6.01"/>
                            <path d="M204.72 145.08c-0.37,-2.65 -1.08,-7.08 -1.73,-9.65 -1.81,-7.2 -5.68,-13.78 -9.67,-20.2 -8.93,-14.36 -18.44,-27.92 -28.06,-41.5 -8.07,-11.38 -16.41,-23.08 -24.57,-34.48"/>
                            <path d="M308.49 99.62c0.51,-2.79 1.13,-6.22 1,-8.98 -0.16,-3.21 -1.14,-6.38 -2.36,-9.41 -2.53,-6.27 -6.14,-11.92 -10.01,-17.37 -7.38,-10.37 -15.75,-19.99 -24.06,-29.64 -9.56,-11.09 -17.57,-20.47 -27.07,-31.59"/>
                            <path d="M317.92 95.4c0.55,-2.11 1.17,-4.59 1.27,-6.72 0.17,-3.65 -0.88,-7.36 -2.24,-10.86 -1.91,-4.88 -4.41,-9.36 -7.22,-13.67 -5.12,-7.85 -11.21,-15.09 -17.29,-22.29 -7.52,-8.9 -15.03,-17.76 -22.82,-26.38 -4.71,-5.22 -8.26,-9.01 -13.09,-14.13"/>
                            <path d="M326.84 91.41c0.61,-2.64 1.15,-5.36 1.22,-8.04 0.08,-3.26 -0.58,-6.59 -1.77,-9.63 -1.79,-4.6 -4.79,-8.57 -7.79,-12.51 -8.09,-10.6 -16.21,-20.93 -24.81,-30.91 -9.07,-10.52 -17.68,-19.61 -27.34,-29.68"/>
                            <path d="M334.56 87.95c0.75,-2.81 1.46,-5.55 1.52,-8.37 0.09,-4.62 -1.62,-9.28 -3.86,-13.48 -2.04,-3.82 -4.53,-7.24 -7.1,-10.63 -6.14,-8.1 -12.73,-15.95 -19.47,-23.63 -9.64,-11 -18.98,-20.96 -28.96,-31.6"/>
                            <path d="M286.66 109.32c0.68,-1.73 1.89,-4.97 2.14,-6.75 0.58,-4.15 -0.85,-8.51 -2.61,-12.57 -3.39,-7.79 -7.98,-14.47 -12.8,-21.05 -10.43,-14.26 -21.87,-28.04 -33.34,-41.7 -6.36,-7.55 -10.59,-12.55 -16.95,-20.06"/>
                            <path d="M297.31 104.6c0.5,-1.12 1.44,-3.39 1.72,-4.55 0.71,-2.94 0.33,-6.14 -0.38,-9.15 -0.47,-2.02 -1.1,-3.95 -1.85,-5.85 -1.63,-4.11 -3.84,-8.02 -6.2,-11.82 -7.2,-11.55 -15.89,-21.99 -24.69,-32.31 -7.73,-9.05 -15.54,-18.01 -23.39,-26.94 -3.38,-3.84 -4.8,-5.46 -8.19,-9.29"/>
                            <path d="M341.39 84.87c1.21,-2.45 2.3,-4.76 2.58,-7.33 0.33,-2.99 -0.59,-6.15 -1.8,-9.09 -2.68,-6.52 -6.78,-11.98 -11.02,-17.34 -5.29,-6.69 -10.81,-13.22 -16.49,-19.61 -7.49,-8.41 -15.27,-16.55 -23.22,-24.54 -2.41,-2.42 -4.29,-4.29 -6.74,-6.7"/>
                            <path d="M349.36 81.28c0.47,-2.01 0.9,-3.94 1.12,-6 0.25,-2.35 0.19,-4.76 -0.32,-7.06 -0.57,-2.58 -1.72,-5.02 -3.01,-7.36 -2.44,-4.44 -5.36,-8.54 -8.52,-12.45 -4.5,-5.61 -9.47,-10.83 -14.29,-16.17 -4.82,-5.34 -9.5,-10.79 -14.41,-16.02 -5.18,-5.51 -10.04,-10.22 -15.53,-15.43"/>
                            <path d="M355.13 78.67c0.72,-3.29 1.42,-6.6 1.25,-9.89 -0.18,-3.52 -1.36,-7.04 -3.05,-10.2 -2.17,-4.06 -5.2,-7.52 -8.19,-10.99 -4.66,-5.41 -9.22,-10.86 -13.97,-16.16 -5.07,-5.66 -10.35,-11.15 -15.74,-16.51 -4.76,-4.71 -8.94,-8.69 -13.82,-13.27"/>
                            <path d="M362.4 75.38c0.25,-2.01 0.48,-4.32 0.44,-6.34 -0.04,-2.26 -0.38,-4.54 -1.05,-6.68 -1.36,-4.29 -4.12,-8.03 -6.99,-11.6 -5.26,-6.54 -10.9,-12.49 -16.58,-18.47 -5.18,-5.45 -10.4,-10.93 -15.81,-16.18 -4.94,-4.79 -9.18,-8.62 -14.3,-13.23"/>
                            <path d="M367.29 73.15c0.4,-2 0.8,-4.58 0.62,-6.55 -0.23,-2.36 -1.12,-4.69 -2.17,-6.9 -2.61,-5.56 -6.13,-10.48 -9.95,-15.15 -7.1,-8.67 -15.23,-16.46 -23.49,-24.08 -6.11,-5.62 -11.19,-10.17 -17.36,-15.69"/>
                            <path d="M371.54 71.22c0.15,-1.74 0.34,-4.54 0.21,-6.25 -0.55,-6.84 -4.99,-12.93 -9.63,-18.45 -7.93,-9.44 -16.45,-17.22 -25.18,-25.08 -5.69,-5.12 -10.28,-9.19 -16.07,-14.35"/>
                            <path d="M374.77 69.39c-0.01,-0.46 -0.06,-3.95 -0.1,-4.4 -0.54,-6.84 -4.99,-12.93 -9.63,-18.46 -7.93,-9.43 -16.45,-17.21 -25.17,-25.07 -4.28,-3.84 -7.07,-6.37 -11.41,-10.24"/>
                            <path d="M22.38 209.39c-2.23,-1.59 -6.16,-4.15 -7.7,-6.31 -1.03,-1.43 -1.63,-3.19 -2.26,-4.93 -2.22,-6.12 -4.78,-11.86 -7.18,-17.77 -1.43,-3.51 -1.86,-4.35 -3.39,-8.18"/>
                        </g>
                    </svg>
                </div>          

                <div className="col-lg-6 col-md-12 col-sm-12 px-5">
                    <svg viewBox="0 0 801 189.31" ref={svg_bottom}>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={corb}>
                            <path d="M799.99 103.15c-2.18,-6.31 -40.73,-102.7 -399.49,-102.7 -358.76,0 -397.31,96.39 -399.49,102.7 -2.18,6.32 2.72,38.56 2.72,38.56 143.66,53.04 396.77,46.94 396.77,46.94 0,0 253.11,6.1 396.77,-46.94 0,0 4.9,-32.24 2.72,-38.56z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor2}>
                            <path d="M1.01 103.15c-2.08,6.02 2.27,35.52 2.69,38.34l0.36 -37.03c0,0 76.89,-65.67 177.53,-41.28 0,0 108.42,49.27 175.59,-9.51 -70.15,34.58 -139.12,0.61 -159.26,-9.65 -17.5,-8.93 -38.64,-16.63 -46.83,-19.5l-3.36 -1.16c-126.68,28.93 -145.23,75.47 -146.72,79.79z"/>
                            <path d="M799.99 103.15c2.07,6.02 -2.27,35.52 -2.69,38.34l-0.36 -37.03c0,0 -76.89,-65.67 -177.53,-41.28 0,0 -108.42,49.27 -175.59,-9.51 70.15,34.58 139.12,0.61 159.26,-9.65 17.5,-8.93 38.64,-16.63 46.83,-19.5l3.36 -1.16c126.68,28.93 145.23,75.47 146.72,79.79z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.3" fill={cor4}>
                            <path d="M197.92 44.02c22.22,11.32 103.91,51.51 181.02,-3.38 -47.06,19.93 -92.47,26.79 -145.73,-3.27 -16.71,-9.43 -31.97,-16.58 -45.05,-21.95 -14.57,2.44 -28.02,5.11 -40.44,7.94 1.97,0.66 28.81,9.75 50.2,20.66z"/>
                            <path d="M603.08 44.02c-22.22,11.32 -103.91,51.51 -181.02,-3.38 47.06,19.93 92.47,26.79 145.73,-3.27 16.71,-9.43 31.97,-16.58 45.05,-21.95 14.57,2.44 28.02,5.11 40.44,7.94 -1.97,0.66 -28.81,9.75 -50.2,20.66z"/>
                        </g>
                        <g stroke="#A6A6A6" strokeWidth="0.5" fill={cor5}>
                            <path d="M181.59 63.18c-100.64,-24.39 -177.53,41.28 -177.53,41.28l-0.36 37.03c0.02,0.14 0.03,0.22 0.03,0.22 3.37,1.24 6.79,2.45 10.27,3.63 7.64,-22.97 32.12,-67.58 107.72,-73.35 23.33,-1.78 46.79,0.7 69.38,6.8 20.83,5.64 59.65,12.99 96.51,3.05l-0.01 0c-54.19,4.89 -106.01,-18.66 -106.01,-18.66z"/>
                            <path d="M619.41 63.18c100.64,-24.39 177.53,41.28 177.53,41.28l0.36 37.03c-0.02,0.14 -0.03,0.22 -0.03,0.22 -3.37,1.24 -6.79,2.45 -10.27,3.63 -7.64,-22.97 -32.12,-67.58 -107.72,-73.35 -23.33,-1.78 -46.79,0.7 -69.38,6.8 -20.83,5.64 -59.65,12.99 -96.51,3.05l0.01 0c54.19,4.89 106.01,-18.66 106.01,-18.66z"/>
                        </g>
                        <g fill={logob}>
                            <path d="M372.33 92.93c-11.05,5.66 -22.92,8.43 -24.79,-1.52 -1.98,-10.68 13.48,-27.33 42.09,-28.49 5.91,-0.24 13.29,0.24 18.26,1.28 0.2,0.05 0.25,0.07 0.25,0.1 0,0.03 -0.1,0.03 -0.26,0.03 -5.79,-0.08 -11.25,0.06 -16.87,0.91 -18.15,2.76 -30.89,11.35 -29.81,16.16 0.82,3.65 8.91,3.63 18.67,0.18 5.86,-2.06 11.74,-5.25 18.26,-8.68 15.92,-8.37 37.04,-11.21 50.96,3.11 6.76,6.96 4.49,12.05 -3.99,9.71 -6.47,-1.79 -11.77,-7.14 -16.7,-11.41 -2.93,-2.55 -5.57,-4.5 -9.37,-6.11 -0.13,-0.06 -0.18,-0.1 -0.17,-0.12 0.01,-0.03 0.13,-0.01 0.34,0.04 4.99,1.22 9.15,3.96 12.39,6.13 1.62,1.07 3.33,1.83 5.25,2.33 5.46,1.42 7.29,-0.57 3.24,-3.81 -2.73,-2.2 -7.38,-4.59 -13.92,-5.4 -0.58,-0.07 -1.17,-0.13 -1.77,-0.17l0.06 0.01c0,0 -0.11,-0.01 -0.31,-0.03 -20.78,-1.42 -34.65,16.95 -51.81,25.75z"/>
                        </g>
                    </svg>
                </div>
            </div>

            <div className="row mb-4 g-0">
                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Top: </b>{Cores[cort].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cort} clique={ setCort }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor1].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor1} clique={ setCor1 }/></div>
                        <div><div><b>Design 02: </b>{Cores[cor2].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor2} clique={ setCor2 }/></div>
                        <div><div><b>Design 03: </b>{Cores[cor3].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor3} clique={ setCor3 }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logot} clique={setLogot}/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-1">
                    <div className="pett-12 p-2">
                        <div><div><b>Bottom: </b>{Cores[corb].tecido}</div><PaletaCores paleta="Paleta01" selecionada={corb} clique={ setCorb }/></div>
                        <div><div><b>Design 01: </b>{Cores[cor4].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor4} clique={ setCor4 }/></div>
                        <div><div><b>Design 03: </b>{Cores[cor5].tecido}</div><PaletaCores paleta="Paleta01" selecionada={cor5} clique={ setCor5 }/></div>

                        <div className="w-100 overflow-auto">
                            <PaletaAdesivos paleta="Paleta01" selecionada={logob} clique={setLogob}/>
                        </div>
                    </div>
                </div>

                <div className="mt-3 d-flex justify-content-center">
                    <button className="btn btn-lg btn-success d-block d-sm-none w-100" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                    <button className="btn btn-lg btn-success d-none d-sm-block w-50" onClick={() => abrirModalEnviar()}><i className="fa fa-fw fa-paper-plane"></i> {T(idioma,"ENVIAR CORES")}</button>
                    {/* <button className="btn btn-lg btn-success w-100" onClick={() => gerarImagem()}><i className="fa fa-fw fa-paper-plane"></i> GERAR IMAGENS</button> */}
                </div>

            </div>

        </ColunaCentral>
        <Rodape/>

        <FormSolid parapente={parapente} tamanhos={tamanhos} show={showModal} fechar={setShowModal} imgtop={imgtop} imgbottom={imgbottom} corestop={coresT} coresbottom={coresB}/>

    </>
}

export default Flexus2;