import React, { useCallback, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
// import { InputMask } from 'primereact/inputmask';
// import { Button } from 'primereact/button';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Api from '../../Api';
import { AuthContext } from '../../Contextos/Auth';
import BarraSuperior from '../../Componentes/BarraSuperior';
import ColunaCentral from '../../Componentes/ColunaCentral';
import Rodape from '../../Componentes/Rodape';

function Profile() {
    const { logout } = useContext(AuthContext);
    const [ usuario, setUsuario ] = useState({
        USU_APELIDO: '',
        USU_BAIRRO: '',
        USU_CEP: '',   
        USU_CIDADECODIGO: '',
        USU_COMPLEMENTO: '',
        USU_CNPJ: '',
        USU_CPF: '',        
        USU_EMAIL: '',
        USU_ENDERECO: '',
        USU_ESTADOCODIGO: '',
        USU_FONE1: '',
        USU_FONE2: '',
        USU_NOME: '',
        USU_NUMERO: '',
        USU_TIPOPESSOA: 'F'
    });
    // const [ imageData, setImageData ] = useState();
    // const [ cidades, setCidades ] = useState([]);
    // const [ estados, setEstados ] = useState([]);
    const [ paises, setPaises ] = useState([]);

    const [ showModal, setShowModal ] = useState(false);
    const [ arquivo, setArquivo ] = useState('');

    // const SelectItemsTIPOPESSOA = [
    //     {value: "F", label: "Física" },
    //     {value: "J", label: "Jurídica" }
    // ];

    const getPaises = useCallback(() => {
        const formData = new FormData();
        formData.set('op','combopaises');
        Api.post('enderecos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setPaises(response.data.msg);
            }
        });
    },[]);

    // const getEstados = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','comboestados');
    //     Api.post('enderecos.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setEstados(response.data.msg);
    //         }
    //     });
    // },[]);

    // const getCidades = useCallback((uf) => {
    //     const formData = new FormData();
    //     formData.set('op','combocidades');
    //     formData.set('estado',uf);
    //     Api.post('enderecos.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setCidades(response.data.msg);
    //         }
    //     });
    // },[]);

    function Profile() {
        var formData = new FormData();
        formData.set('op', 'profile');            
        Api.post('usuario/profile.php',formData).then((response) => {
            if (response.data.erro==='N') {
                // getCidades(response.data.msg.USU_ESTADOCODIGO);
                setUsuario(response.data.msg);
            } else {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        }).catch(({response}) => {
            console.log('CATCH Profile');
        });        
    }

    useEffect(() => {
        // getEstados();
        getPaises();
        Profile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const setCampo = (e) => {
        const { name, value } = e.target;
        //let valor = value?value.toUpperCase():'';
        let valor = value;
        if (name==='USU_EMAIL') {
            valor = valor.toLowerCase();
        }

        setUsuario({...usuario, [name]: valor});

        // if (name==="USU_ESTADOCODIGO") {
        //     getCidades(valor);
        // }
    }

    // async function consultarCEP(e) {
    //     const cep = usuario.USU_CEP.replace(/\D/g,"");
    //     const url = 'https://api.postmon.com.br/v1/cep/'+cep;
    //     try {
    //         const response = await axios.get(url);
    //         if (response.data) {
    //             getCidades(response.data.estado_info.codigo_ibge);
    //             setUsuario({
    //                 ...usuario, 
    //                 USU_BAIRRO: response.data.bairro, 
    //                 USU_ENDERECO: response.data.logradouro,
    //                 USU_CIDADECODIGO: response.data.cidade_info.codigo_ibge,
    //                 USU_ESTADOCODIGO: response.data.estado_info.codigo_ibge
    //             })
    //         } 
    //     } catch {
    //         setUsuario({
    //             ...usuario, 
    //             USU_BAIRRO: '', 
    //             USU_ENDERECO: '',
    //             USU_CIDADECODIGO: '',
    //             USU_ESTADOCODIGO: ''
    //         })
    //         Swal.fire({title: 'Ah nãooooo!', text: 'CEP não encontrado ou erro ao consultar.', icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'})
    //     }
    // }    
    
    async function salvarProfile() {
        const formData = new FormData();
        formData.set('op','atualizar');
        formData.set('USU_APELIDO',usuario.USU_APELIDO || '');
        // formData.set('USU_BAIRRO',usuario.USU_BAIRRO || '');
        // formData.set('USU_CEP',usuario.USU_CEP || '');
        // formData.set('USU_CIDADECODIGO',usuario.USU_CIDADECODIGO || '');
        // formData.set('USU_COMPLEMENTO',usuario.USU_COMPLEMENTO || '');
        // formData.set('USU_CNPJ',usuario.USU_CNPJ || '');
        // formData.set('USU_CPF',usuario.USU_CPF || '');
        formData.set('USU_EMAIL',usuario.USU_EMAIL || '');
        // formData.set('USU_ENDERECO',usuario.USU_ENDERECO || '');
        // formData.set('USU_ESTADOCODIGO',usuario.USU_ESTADOCODIGO || '');
        formData.set('USU_FONE1',usuario.USU_FONE1 || '');
        formData.set('USU_FONE2',usuario.USU_FONE2 || '');
        formData.set('USU_NOME',usuario.USU_NOME || '');
        // formData.set('USU_NUMERO',usuario.USU_NUMERO || '');
        formData.set('USU_PAIS',usuario.USU_PAIS || '');
        // formData.set('USU_TIPOPESSOA',usuario.USU_TIPOPESSOA || '');
        const response = await Api.post('usuario/profile.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
        }
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    async function enviarImagem() {
        if (arquivo) {
            const imagedata = document.querySelector('input[type="file"]').files[0];
            var formData = new FormData();
            formData.set('op','avatar');
            formData.set('arquivo', imagedata);
            try {
                const response = await Api.post('usuario/profile.php',formData);
                if (response.data.erro==="N") {
                    Swal.fire({title: 'Yesss!', text: response.data.msg, icon: 'success', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }

                if (response.data.erro==="S") {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }

            } catch (error) {
                console.log('CATCH enviarImagem',error);
            }
            setShowModal(false);
        } else {
            Swal.fire({title: 'Ooops!', text: 'Voce não selecionou nenhum arquivo', icon: 'info', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">

            <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 mb-3 d-flex justify-content-center">
                    <img className="img-thumbnail img-fluid rounded-circle shadow-sm" style={{maxHeight: '160px'}} src={usuario.USU_AVATAR} alt="foto" onClick={() => setShowModal(true)}/>
                    {/* <img className="img-thumbnail img-fluid rounded-circle shadow-sm" style={{maxHeight: '160px'}} onClick="AlterarImagem();" src={usuario.USU_AVATAR} alt="foto"/> */}
                </div>
                <div className="col-xl-10 col-lg-9 col-md-9 col-sm-12 d-flex align-items-center mb-3">
                    <div>
                        <h2 style={{marginBottom: '0px'}}><b>{usuario.USU_NOME}</b></h2>
                        <p className="text-muted" style={{marginBottom: '-2px'}}>{usuario.USU_EMAIL}</p>
                    </div>
                </div>
            </div>

            <div className="row mt-3 gx-2 p-inputtext-sm">
                <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_NOME" name="USU_NOME" value={usuario.USU_NOME} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_NOME">NOME COMPLETO</label>
                    </span>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_APELIDO" name="USU_APELIDO" value={usuario.USU_APELIDO} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_APELIDO">APELIDO</label>
                    </span>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_EMAIL" name="USU_EMAIL" value={usuario.USU_EMAIL} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_EMAIL">EMAIL</label>
                    </span>
                </div>
                {/* <div className="col-lg-2 col-md-6 col-sm-12 form-floating mb-3">
                    <span className="p-float-label">
                        <Dropdown className="w-100" name="USU_TIPOPESSOA" value={usuario.USU_TIPOPESSOA} options={SelectItemsTIPOPESSOA} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                        <label htmlFor="USU_TIPOPESSOA">TIPO PESSOA</label>
                    </span>
                </div> */}
                {/* <div className={usuario.USU_TIPOPESSOA==="F"?"col-lg-3 col-md-6 col-sm-12 mb-3":"col-lg-3 col-md-6 col-sm-12 mb-3 d-none"}>
                    <span className="p-float-label">
                        <InputMask className="w-100" name="USU_CPF" value={usuario.USU_CPF} onChange={(e) => setCampo(e)} mask="999.999.999-99" required/>
                        <label htmlFor="USU_CPF">CPF</label>
                    </span>
                </div>
                <div className={usuario.USU_TIPOPESSOA==="J"?"col-lg-3 col-md-6 col-sm-12 mb-3":"col-lg-3 col-md-6 col-sm-12 mb-3 d-none"}>
                    <span className="p-float-label">
                        <InputMask className="w-100" name="USU_CNPJ" value={usuario.USU_CNPJ} onChange={(e) => setCampo(e)} mask="99.999.999/9999-99" required/>
                        <label htmlFor="USU_CNPJ">CNPJ</label>
                    </span>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
                    <div className="p-inputgroup">
                        <span className="p-float-label">
                            <InputMask className="w-100" name="USU_CEP" value={usuario.USU_CEP} onChange={(e) => setCampo(e)} mask="99999-999" required/>
                            <label htmlFor="USU_CEP">CEP</label>
                        </span>
                        <Button className="px-3 btn bg-secondary text-light" icon="pi pi-search" onClick={(e) => consultarCEP(e)}/>
                    </div>          
                </div>                
                <div className="col-lg-2 col-md-6 col-sm-12 form-floating mb-3">
                    <span className="p-float-label">
                        <Dropdown className="w-100" name="USU_ESTADOCODIGO" value={String(usuario.USU_ESTADOCODIGO)} options={estados} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                        <label htmlFor="USU_ESTADOCODIGO">ESTADO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 form-floating mb-3">
                    <span className="p-float-label">
                        <Dropdown className="w-100" name="USU_CIDADECODIGO" value={String(usuario.USU_CIDADECODIGO)} options={cidades} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                        <label htmlFor="USU_CIDADECODIGO">CIDADE</label>
                    </span>
                </div> */}
                <div className="col-lg-3 col-md-6 col-sm-12 form-floating mb-3">
                    <span className="p-float-label">
                        <Dropdown className="w-100" name="USU_PAIS" value={String(usuario.USU_PAIS)} options={paises} onChange={(e) => setCampo(e)} placeholder="Selecione"/>
                        <label htmlFor="USU_PAIS">PAÍS</label>
                    </span>
                </div>
                {/* <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_ENDERECO" name="USU_ENDERECO" value={usuario.USU_ENDERECO || ''} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_ENDERECO">ENDEREÇO</label>
                    </span>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_NUMERO" name="USU_NUMERO" value={usuario.USU_NUMERO || ''} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_NUMERO">NÚMERO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_BAIRRO" name="USU_BAIRRO" value={usuario.USU_BAIRRO || ''} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_BAIRRO">BAIRRO</label>
                    </span>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_COMPLEMENTO" name="USU_COMPLEMENTO" value={usuario.USU_COMPLEMENTO || ''} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_COMPLEMENTO">COMPLEMENTO</label>
                    </span>
                </div> */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_FONE1" name="USU_FONE1" value={usuario.USU_FONE1 || ''} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_FONE1">FONE 01</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <span className="p-float-label">
                        <InputText className="w-100" id="USU_FONE2" name="USU_FONE2" value={usuario.USU_FONE2 || ''} onChange={(e) => setCampo(e)} required/>
                        <label htmlFor="USU_FONE2">FONE 02</label>
                    </span>
                </div>
                {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-3" data-provides="fileinput">
                    <div className="input-group">
                        <input className="form-control form-floating" type="file" id="USU_ARQUIVO" name="USU_ARQUIVO" onChange={(e) => setImageData(e.target.files[0])}/>
                    </div>
                </div> */}
            </div>

            <div className="box shadow-sm mb-5">
                <button className="btn btn-success me-2" type="button" onClick={() => salvarProfile()}>Salvar</button>
            </div>

        </ColunaCentral>
        <Rodape/>
        
        <Modal show={showModal} fullscreen={false} backdrop="static">
            <Modal.Header className="bg-color2" >
                <Modal.Title><h5 className="modal-title">Foto do Perfil</h5></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="col-12 mb-5" data-provides="fileinput">
                    <div className="input-group">
                        <input type="file" className="form-control form-floating" id="arquivo" name="arquivo" onChange={(e) => setArquivo(e.target.value)} value={arquivo}/>
                    </div>
                </div>  
            </Modal.Body>

            <Modal.Footer className='bg-color2'>
                <Button variant="success" onClick={() => enviarImagem()}><i className="fa fa-fw fa-upload"></i> Enviar</Button>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Cancelar</Button>
            </Modal.Footer>
        </Modal>

    </>
}

export default Profile;