import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { LinguaContext } from '../Contextos/Linguas';
import { UnidadeContext } from '../Contextos/Unidades';
import Api from '../Api';
import BarraSuperior from '../Componentes/BarraSuperior';
import ColunaCentral from '../Componentes/ColunaCentral';
import IfComponent from '../Componentes/IfComponent';
// import NoticiaArquivos from '../Componentes/NoticiaArquivos';
import NoticiaCard from '../Componentes/NoticiaCard';
// import NoticiaCategorias from '../Componentes/NoticiaCategorias';
// import NoticiaCorpo from '../Componentes/NoticiaCorpo';
// import NoticiaMaisLidos from '../Componentes/NoticiaMaisLidos';
// import NoticiaTags from '../Componentes/NoticiaTags';

import Rodape from '../Componentes/Rodape';

function News() {
    const { idioma } = useContext(LinguaContext);
    const { unidade } = useContext(UnidadeContext);
    const { cat, ano, mes, tag } = useParams();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    // const [ categorias, setCategorias ] = useState([]);
    // const [ maisLidos, setMaisLidos ] = useState([]);
    // const [ tags, setTags ] = useState([]);
    // const [ anoAtual, setAnoAtual ] = useState([]);
    // const [ anosRestantes, setAnosRestantes ] = useState([]);

    function listar(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('cat', cat || 0);
            formData.set('ano', ano || 0);
            formData.set('mes', mes || 0);
            formData.set('tag', tag || '');
            formData.set('idioma',idioma);
            formData.set('unidade',unidade);
            
            Api.post('/news.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    // const getCategorias = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','listar');
    //     Api.post('/categorias.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setCategorias(response.data.msg);
    //         }
    //     });
    // },[]);

    // const getMaisLidos = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','maislidos');
    //     Api.post('/news.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setMaisLidos(response.data.msg);
    //         }
    //     });
    // },[]);

    // const getTags = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','tags');
    //     Api.post('/news.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setTags(response.data.msg);
    //         }
    //     });
    // },[]);

    // const getArquivos = useCallback(() => {
    //     const formData = new FormData();
    //     formData.set('op','arquivos');
    //     Api.post('/news.php',formData).then((response) => {
    //         if (response.data.erro==='N') {
    //             setAnoAtual(response.data.msg.atual);
    //             setAnosRestantes(response.data.msg.restante);
    //         }
    //     });
    // },[]);

    useEffect(() => {      
        listar(pagina,final,false); 
        // getCategorias();
        // getMaisLidos();
        // getTags();
        // getArquivos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // useEffect(() => {      
    //     setPagina(0);
    //     setFinal(false);
    //     setListagem([]);
    //     listar(pagina,final,false); 
    //     console.log('effect cat ano mes tag',cat,ano,mes,tag)
    // },[cat,ano,mes,tag]);
    
    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">

            <div className="row">
                {/* <div className="col-lg-3 col-md-3 col-sm-3 hidden-xs">
                    <NoticiaCategorias categorias={categorias}/>
                    <NoticiaMaisLidos maisLidos={maisLidos}/>
                    <NoticiaTags tags={tags}/>
                    <NoticiaArquivos anoAtual={anoAtual} anosRestantes={anosRestantes} />
                </div> */}
                {/* <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12"> */}
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <IfComponent condicional={ listagem.length > 0}>

                        <div className="row row-cols-1 row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 g-4 mb-5">
                            {/* <div className="col"> */}

                                {                     
                                    listagem.map(noticia => (
                                        <NoticiaCard noticia={noticia} key={noticia.NOT_CODIGO}/>
                                        // <NoticiaCorpo noticia={noticia} tags={noticia.NOT_TAGS} visualizar={false} key={noticia.NOT_CODIGO} />
                                    ))
                                }
                            {/* </div> */}
                        </div>

                        <div className="col-12 text-center mb-5">
                            <IfComponent condicional={carregando && !final}>
                                <button className="btn btn-success" disabled><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</button>
                            </IfComponent>
                            <IfComponent condicional={!carregando && !final}>
                                <button className="btn btn-success" onClick={()=>listar(pagina,final,false)}>Carregar mais</button>
                            </IfComponent>
                            <IfComponent condicional={final}>
                                <p>...Sem mais registros...</p>
                            </IfComponent>
                        </div>
                    </IfComponent>
                </div>
            </div>


        </ColunaCentral>
        <Rodape/>
    </>
}

export default News;