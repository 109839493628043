import React, { useCallback, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';

import { LinguaContext } from '../Contextos/Linguas';
import Api from '../Api';
import BarraSuperior from '../Componentes/BarraSuperior';
import Cabecalho from '../Componentes/Cabecalho';
import ColunaCentral from '../Componentes/ColunaCentral';
import Rodape from '../Componentes/Rodape';

function SolicitacaoApoio() {
    const { idioma } = useContext(LinguaContext);
    const [ enviando, setEnviando ] = useState(false);
    const [ foneMask1, setFoneMask1 ] = useState('(99) 99999-999?9');
    const [ formulario, setFormulario ] = useState({
        APO_CATEGORIAS: '',
        APO_CIDADE: '',
        APO_DESCRICAO: '',
        APO_DIVULGACAO: '',
        APO_DOCUMENTO: '',
        APO_DTFIM: '',
        APO_DTINICIO: '',
        APO_EMAIL: '',
        APO_EMPRESAS: '',
        APO_ENDERECO: '',
        APO_ESTADO: '',
        APO_FACEBOOK: '',
        APO_FONE: '', 
        APO_HISTORICO: '',
        APO_INSTAGRAM: '',
        APO_LINKINSCRICAO: '',
        APO_MODALIDADE: 'Voo Livre',
        APO_NOMEEVENTO: '',
        APO_NOMELOCAL: '',
        APO_NUMPARTICIPANTES: '',
        APO_ORGAONOME: '',
        APO_ORGAODIRETOR: '',
        APO_PREMIACOES: '',
        APO_PROGRAMACAO: '',
        APO_RESPONSAVEL: '',
        APO_REVENDEDOR: '',
        APO_SITEEVENTO: '',
        APO_VALORINSCRICAO: '',
    });

    const selectModalidade = [
        {value: "Voo Livre", label: "Voo Livre" },
        {value: "Paramotor / Paratrike", label: "Paramotor / Paratrike" },
        {value: "Outros", label: "Outros" }
    ];

    const [ cidades, setCidades ] = useState([]);
    const [ estados, setEstados ] = useState([]);
    
    const getEstados = useCallback(() => {
        const formData = new FormData();
        formData.set('op','comboestados');
        formData.set('idioma',idioma);
        Api.post('/enderecos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setEstados(response.data.msg);
            }
        });
    },[idioma]);

    const getCidades = useCallback((uf) => {
        const formData = new FormData();
        formData.set('op','combocidades');
        formData.set('estado',uf);
        Api.post('enderecos.php',formData).then((response) => {
            if (response.data.erro==='N') {
                setCidades(response.data.msg);
            }
        });
    },[]); 
    
    useEffect(() => {
        getEstados();
    },[getEstados]);
    
    useEffect(() => {
        if (formulario.APO_ESTADO!=='') {
            getCidades(formulario.APO_ESTADO);
        }
    },[getCidades,formulario.APO_ESTADO]);

    async function salvar() {      
        setEnviando(true);
        const imagedata = document.getElementById('arquivo').files[0];
        const formData = new FormData();
        formData.set('op','inserir');
        formData.set('idioma',idioma);
        formData.set('APO_CATEGORIAS',formulario.APO_CATEGORIAS || '');
        formData.set('APO_CIDADE',formulario.APO_CIDADE || '');
        formData.set('APO_DESCRICAO',formulario.APO_DESCRICAO || '');
        formData.set('APO_DIVULGACAO',formulario.APO_DIVULGACAO || '');
        formData.set('APO_DOCUMENTO',formulario.APO_DOCUMENTO || '');
        formData.set('APO_DTFIM',formulario.APO_DTFIM || '');
        formData.set('APO_DTINICIO',formulario.APO_DTINICIO || '');
        formData.set('APO_EMAIL',formulario.APO_EMAIL || '');
        formData.set('APO_EMPRESAS',formulario.APO_EMPRESAS || '');
        formData.set('APO_ENDERECO',formulario.APO_ENDERECO || '');
        formData.set('APO_ESTADO',formulario.APO_ESTADO || '');
        formData.set('APO_FACEBOOK',formulario.APO_FACEBOOK || '');
        formData.set('APO_FONE',formulario.APO_FONE || '');
        formData.set('APO_HISTORICO',formulario.APO_HISTORICO || '');
        formData.set('APO_INSTAGRAM',formulario.APO_INSTAGRAM || '');
        formData.set('APO_LINKINSCRICAO',formulario.APO_LINKINSCRICAO || '');
        formData.set('APO_MODALIDADE',formulario.APO_MODALIDADE || '');
        formData.set('APO_NOMEEVENTO',formulario.APO_NOMEEVENTO || '');
        formData.set('APO_NOMELOCAL',formulario.APO_NOMELOCAL || '');
        formData.set('APO_NUMPARTICIPANTES',formulario.APO_NUMPARTICIPANTES || '');
        formData.set('APO_ORGAODIRETOR',formulario.APO_ORGAODIRETOR || '');
        formData.set('APO_ORGAONOME',formulario.APO_ORGAONOME || '');
        formData.set('APO_PREMIACOES',formulario.APO_PREMIACOES || '');
        formData.set('APO_PROGRAMACAO',formulario.APO_PROGRAMACAO || '');
        formData.set('APO_RESPONSAVEL',formulario.APO_RESPONSAVEL || '');
        formData.set('APO_REVENDEDOR',formulario.APO_REVENDEDOR || '');
        formData.set('APO_SITEEVENTO',formulario.APO_SITEEVENTO || '');
        formData.set('APO_VALORINSCRICAO',formulario.APO_VALORINSCRICAO || '');
        formData.set('arquivo',imagedata);
        try {
            const response = await Api.post('apoios.php',formData);
            if (response.data.erro==='N') {
                setEnviando(false);
                setFormulario({        
                    APO_CATEGORIAS: '',
                    APO_CIDADE: '',
                    APO_DESCRICAO: '',
                    APO_DIVULGACAO: '',
                    APO_DOCUMENTO: '',
                    APO_DTFIM: '',
                    APO_DTINICIO: '',
                    APO_EMAIL: '',
                    APO_EMPRESAS: '',
                    APO_ENDERECO: '',
                    APO_ESTADO: '',
                    APO_FACEBOOK: '',
                    APO_FONE: '', 
                    APO_HISTORICO: '',
                    APO_INSTAGRAM: '',
                    APO_LINKINSCRICAO: '',
                    APO_MODALIDADE: 'Voo Livre',
                    APO_NOMEEVENTO: '',
                    APO_NOMELOCAL: '',
                    APO_NUMPARTICIPANTES: '',
                    APO_ORGAONOME: '',
                    APO_ORGAODIRETOR: '',
                    APO_PREMIACOES: '',
                    APO_PROGRAMACAO: '',
                    APO_RESPONSAVEL: '',
                    APO_REVENDEDOR: '',
                    APO_SITEEVENTO: '',
                    APO_VALORINSCRICAO: '',
                });
                Swal.fire({title: 'Yessss!', text: 'Formulário enviado com sucesso.', icon: 'success', showConfirmButton: true, confirmButtonColor: '#212529'});
            } 
            if (response.data.erro==='S') {
                Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
            setEnviando(false);
        } catch (error) {
            setEnviando(false);
            console.log('CATCH',error);
        }
    }
    
    function soNumeros(texto) {
        return texto.replace(/\D/g, '');
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }

        if (name==="APO_FONE" && soNumeros(valor).length === 10) {
            setFoneMask1('(99) 9999-9999?9');
        } else
        if (name==="APO_FONE" && soNumeros(valor).length === 11) {
            setFoneMask1('(99) 99999-999?9');
        } else 
        if (name==="APO_FONE1" && soNumeros(valor).length < 10) {
            setFoneMask1('(99) 99999-999?9');
        }

        if (objeto==="formulario") {
            setFormulario({...formulario, [name]: valor});
        }
    }

    return <>
        <BarraSuperior/>
        <ColunaCentral left="false" right="false" className="container space-top">
            <Cabecalho className="mb-3" titulo="Solicitação de Apoio a Eventos" texto=""/>
            <p>
                Nota: Para melhor planejamento e respostas em tempo hábil este formulário deve ser enviado para análise com no mínimo <strong>120 dias</strong> de antecedência ao evento.
            </p>
            <div className="row mt-3 mb-3 gx-1 p-inputtext-sm pett-input">
                <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="APO_NOMEEVENTO" value={formulario.APO_NOMEEVENTO || ''} onChange={(e) => setCampo(e,'formulario',false)} required/>
                        <label htmlFor="APO_NOMEEVENTO">NOME DO EVENTO</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <Dropdown className="w-100" maxLength="20" name="APO_MODALIDADE" options={selectModalidade} value={formulario.APO_MODALIDADE || ''} onChange={(e) => setCampo(e,'formulario',false)} required/>
                        <label htmlFor="APO_MODALIDADE">MODALIDADE</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputTextarea className="w-100" name="APO_DESCRICAO" value={formulario.APO_DESCRICAO} onChange={(e) => setCampo(e,'formulario',false)} autoResize rows="5"/>
                        <label htmlFor="APO_DESCRICAO">DESCRIÇÃO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 mb-1">
                    <span className="p-float-label pett-label">
                        <InputMask className="w-100" maxLength="20" name="APO_DTINICIO" value={formulario.APO_DTINICIO} onChange={(e) => setCampo(e,'formulario',false)} mask='99/99/9999' required/>
                        <label htmlFor="APO_DTINICIO">DATA INÍCIO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 mb-1">
                    <span className="p-float-label pett-label">
                        <InputMask className="w-100" maxLength="20" name="APO_DTFIM" value={formulario.APO_DTFIM} onChange={(e) => setCampo(e,'formulario',false)} mask='99/99/9999' required/>
                        <label htmlFor="APO_DTFIM">DATA TÉRMINO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <Dropdown className="w-100" name="APO_ESTADO" value={formulario.APO_ESTADO || ''} options={estados} onChange={(e) => setCampo(e,'formulario',false)} placeholder="Selecione" required/>
                        <label htmlFor="APO_ESTADO">ESTADO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <Dropdown className="w-100" name="APO_CIDADE" value={formulario.APO_CIDADE || ''} options={cidades} onChange={(e) => setCampo(e,'formulario',false)} placeholder="Selecione" required/>
                        <label htmlFor="APO_CIDADE">CIDADE</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="APO_NOMELOCAL" value={formulario.APO_NOMELOCAL || ''} onChange={(e) => setCampo(e,'formulario',false)}/>                        
                        <label htmlFor="APO_NOMELOCAL">NOME DO LOCAL OU RAMPA ONDE SERAO AS DECOLAGENS</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="255" name="APO_LINKINSCRICAO" value={formulario.APO_LINKINSCRICAO || ''} onChange={(e) => setCampo(e,'formulario',false)}/>
                        <label htmlFor="APO_LINKINSCRICAO">LINK DA INSCRIÇÃO DO EVENTO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 mb-1">
                    <span className="p-float-label pett-label">
                        <InputNumber className="w-100" name="APO_VALORINSCRICAO" value={formulario.APO_VALORINSCRICAO} onValueChange={(e) => setCampo(e,'formulario',false)} mode="currency" currency="BRL" locale="pt-BR"  minFractionDigits={2}/>
                        <label htmlFor="APO_VALORINSCRICAO">VALOR DA INSCRIÇÃO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="15" name="APO_NUMPARTICIPANTES" value={formulario.APO_NUMPARTICIPANTES || ''} onChange={(e) => setCampo(e,'formulario',false)} />
                        <label htmlFor="APO_NUMPARTICIPANTES">NÚMERO ESTIMADO DE PARTICIPANTES</label>
                    </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="APO_CATEGORIAS" value={formulario.APO_CATEGORIAS || ''} onChange={(e) => setCampo(e,'formulario',false)} />
                        <label htmlFor="APO_CATEGORIAS">QUAIS AS CATEGORIAS DO EVENTO</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="200" name="APO_PREMIACOES" value={formulario.APO_PREMIACOES || ''} onChange={(e) => setCampo(e,'formulario',false)} />
                        <label htmlFor="APO_PREMIACOES">QUAIS AS PREMIAÇÕES DO EVENTO</label>                                                               
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-6 mb-1">
                    <span className="p-float-label pett-label">
                        <InputTextarea className="w-100" name="APO_PROGRAMACAO" value={formulario.APO_PROGRAMACAO} onChange={(e) => setCampo(e,'formulario',false)} autoResize rows="5"/>
                        <label htmlFor="APO_PROGRAMACAO">PROGRAMAÇÃO OFICIAL COM DATAS E HORÁRIOS</label>
                    </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="255" name="APO_SITEEVENTO" value={formulario.APO_SITEEVENTO || ''} onChange={(e) => setCampo(e,'formulario',false)}/>
                        <label htmlFor="APO_SITEEVENTO">SITE DO EVENTO</label>
                    </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="255" name="APO_FACEBOOK" value={formulario.APO_FACEBOOK || ''} onChange={(e) => setCampo(e,'formulario',false)}/>
                        <label htmlFor="APO_FACEBOOK">FACEBOOK DO EVENTO</label>
                    </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="255" name="APO_INSTAGRAM" value={formulario.APO_INSTAGRAM || ''} onChange={(e) => setCampo(e,'formulario',false)}/>
                        <label htmlFor="APO_INSTAGRAM">INSTAGRAM DO EVENTO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="APO_RESPONSAVEL" value={formulario.APO_RESPONSAVEL || ''} onChange={(e) => setCampo(e,'formulario',false)} required/>
                        <label htmlFor="APO_RESPONSAVEL">NOME DO RESPONSÁVEL PELO EVENTO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="APO_EMAIL" value={formulario.APO_EMAIL || ''} onChange={(e) => setCampo(e,'formulario',false)} type="email" required/>
                        <label htmlFor="APO_EMAIL">EMAIL DO RESPONSÁVEL PELO EVENTO</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="20" name="APO_DOCUMENTO" value={formulario.APO_DOCUMENTO || ''} onChange={(e) => setCampo(e,'formulario',false)} required/>
                        <label htmlFor="APO_DOCUMENTO">CPF/CNPJ DO RESPONSÁVEL</label>
                    </span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 mb-1">
                    <span className="p-float-label pett-label">
                        <InputMask className="w-100" maxLength="20" name="APO_FONE" value={formulario.APO_FONE} onChange={(e) => setCampo(e,'formulario',false)} mask={foneMask1} />
                        <label htmlFor="APO_FONE">FONE DE CONTATO DO RESPONSÁVEL</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputTextarea className="w-100" name="APO_ENDERECO" value={formulario.APO_ENDERECO} onChange={(e) => setCampo(e,'formulario',false)} autoResize rows="5"/>
                        <label htmlFor="APO_ENDERECO">ENDEREÇO COMPLETO PARA ENVIO</label>
                    </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="APO_REVENDEDOR" value={formulario.APO_REVENDEDOR || ''} onChange={(e) => setCampo(e,'formulario',false)}/>
                        <label htmlFor="APO_REVENDEDOR">REVENDEDOR SOL DA REGIÃO</label>
                    </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="APO_ORGAONOME" value={formulario.APO_ORGAONOME || ''} onChange={(e) => setCampo(e,'formulario',false)}/>
                        <label htmlFor="APO_ORGAONOME">NOME DO CLUBE OU ÓRGÃO ORGANIZADOR</label>
                    </span>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="100" name="APO_ORGAODIRETOR" value={formulario.APO_ORGAODIRETOR || ''} onChange={(e) => setCampo(e,'formulario',false)}/>
                        <label htmlFor="APO_ORGAODIRETOR">DIRETOR DO CLUBE OU ÓRGÃO</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputTextarea className="w-100" name="APO_HISTORICO" value={formulario.APO_HISTORICO} onChange={(e) => setCampo(e,'formulario',false)} autoResize rows="5"/>
                        <label htmlFor="APO_HISTORICO">HISTÓRICO DOS EVENTOS ANTERIORES (COM DATAS)</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputText className="w-100" maxLength="200" name="APO_EMPRESAS" value={formulario.APO_EMPRESAS || ''} onChange={(e) => setCampo(e,'formulario',false)}/>
                        <label htmlFor="APO_EMPRESAS">QUAIS OS NOMES DAS OUTRAS EMPRESAS QUE ESTÃO APOIANDO O EVENTO?</label>
                    </span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-1">
                    <span className="p-float-label pett-label">
                        <InputTextarea className="w-100" name="APO_DIVULGACAO" value={formulario.APO_DIVULGACAO} onChange={(e) => setCampo(e,'formulario',false)} autoResize rows="5"/>
                        <label htmlFor="APO_DIVULGACAO">COMO SERÁ DIVULGADA A MARCA SOL PARAGLIDERS NO EVENTO?</label>
                    </span>
                </div>
                <div className="col-12 mb-5" data-provides="fileinput">
                    <label htmlFor="arquivo">ANEXO PARA IMAGEM OU PDF (não obrigatório)</label>
                    <div className="input-group">
                        <input type="file" accept="image/png, image/jpeg, application/pdf" className="form-control form-floating" id="arquivo" name="arquivo" onChange={(e) => setCampo(e,'formulario',false)} value={formulario.arquivo}/>
                    </div>
                </div> 
            </div>

            <div className="box shadow-sm mt-3 mb-4">
                {
                    enviando
                        ? <button className="btn btn-success me-2 disabled"><i className="fa fa-fw fa-spin fa-spinner"></i> Enviando...</button>
                        : <button className="btn btn-success me-2" onClick={() => salvar(false)}><i className="fa fa-fw fa-paper-plane"></i> Enviar</button>
                }
            </div>

        </ColunaCentral>
        <Rodape/>
    </>
}

export default SolicitacaoApoio;